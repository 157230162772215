import { PageOrchestratorProps } from '../models/PageOrchestratorProps';
import { useEffect } from 'react';
import { useAppDispatch } from './reduxHooks';
import { getCurrentMarketNameInfo } from '../providers/reducers/marketSlice';
import { getMvTaxesAndDutiesInfo } from '../providers/reducers/mvLawsAndRegsSlice';
import usePageOrchestratorComponent from './usePageOrchestratorComponent';

export default function useDutiesandtaxesLawsAndRegsPageOrchestrator(props: PageOrchestratorProps): {
    isDutiesandtaxes: boolean;
} {
    const dispatch = useAppDispatch();
    const isDutiesandtaxes = usePageOrchestratorComponent(props, 'laws_and_regulations_duties_and_taxes');

    useEffect(() => {
        if (isDutiesandtaxes) {
            dispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: props.match }));
            dispatch(getMvTaxesAndDutiesInfo({ commodity: props.match.params.sector, market: props.match.params.market }));
        }
    }, [isDutiesandtaxes, props.match.params.sector, props.match.params.market]);

    return { isDutiesandtaxes };
}
