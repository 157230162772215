import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import './CommoditiesSearch.scss';
import './NonAgriMarketsSearch.scss';
import { MarketOption } from '../../models/MarketOption';
import MarketSearchBase from './MarketSearchBase';
import { getMarketOptionsWithNotOptions } from '../../utils/marketSearchUtils';
import { ContentItem } from '@exporter-services/common-ui';
import * as ReactGA from 'react-ga';
import { client } from '../../config';
import { PageName } from '../../models/PageName';
import { CommonConstants } from '../../CommonConstants';
import { useGetMarketsQuery } from '../../providers/reducers/marketsApi';

export interface NonAgriMarketsSearchProps {
    item: ContentItem;
    sector: string;
    onClick?: (selectedUrl: MarketOption) => void;
    onFocus?: () => void;
    ariaLabel?: string;
}

const NonAgriMarketsSearch = (props: NonAgriMarketsSearchProps) => {
    const [selectedValue, setSelectedValue] = useState<MarketOption>();
    const [allMarkets, setAllMarkets] = useState<any[]>();
    const [marketOptions, setMarketOptions] = useState<MarketOption[]>();

    const marketSearchInputRef = useRef(null);
    const isExportRulesFinder = window.location.pathname === `/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}`;

    const marketsQueryResult = useGetMarketsQuery({});
    const setMarketSearchInputRef = (ref: any) => {
        marketSearchInputRef.current = ref;
    };

    function populateSectorMarkets(sector: string, allMarkets: any[]): Promise<any> {
        return client
            .items()
            .equalsFilter('system.type', 'laws_and_regulations_non_agri')
            .containsFilter('elements.industry_sector', [sector])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                let marketOptions: MarketOption[] = response.data.items
                    .filter(
                        (r) =>
                            r.elements.marketcodesnippet__market?.value?.length > 0 &&
                            r.elements.marketcodesnippet__market?.value[0].codename.toLowerCase() !== CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME,
                    )
                    .map((r: any) => {
                        let market = r.elements.marketcodesnippet__market?.value[0].codename.toLowerCase();
                        return allMarkets.find((m) => m.Code.toLowerCase() === market);
                    })
                    .filter((r) => r);

                setSelectedValue(null); // Change it to desired market for Tile API flow.
                props.onClick && props.onClick(null);
                let sortedMarketOptions = marketOptions.sort((a, b) => a.Label.toLowerCase().localeCompare(b.Label.toLowerCase()));
                if (isExportRulesFinder) {
                    setMarketOptions(getMarketOptionsWithNotOptions(sortedMarketOptions));
                } else {
                    setMarketOptions(sortedMarketOptions);
                }
            });
    }

    useEffect(() => {
        marketSearchInputRef.current?.focus();
    }, [selectedValue]);

    useEffect(() => {
        if (allMarkets) {
            populateSectorMarkets(props.sector, allMarkets);
        } else {
            if (marketsQueryResult.isSuccess) {
                let m = marketsQueryResult.data.map((x) => ({
                    Code: x.Iso3lCode,
                    Label: x.Name,
                }));
                setAllMarkets(m);
                populateSectorMarkets(props.sector, m);
            }
        }
    }, [props.sector, marketsQueryResult.isSuccess]);

    const handleChange = (selectedOption: MarketOption) => {
        setSelectedValue(selectedOption);
        props.onClick && selectedOption && props.onClick(selectedOption);
    };

    const handleOnInputChange = debounce((searchtext) => {
        if (!searchtext) return;
        !isExportRulesFinder &&
            ReactGA.event({
                category: 'LR Non Food and agribusiness',
                action: 'Enters market search term',
                label: searchtext,
            });
    }, 1000);

    return (
        <MarketSearchBase
            id={'servicesMarketSearch'}
            marketOptions={marketOptions}
            setMarketSearchInputRef={setMarketSearchInputRef}
            handleOnChange={handleChange}
            handleInputChange={handleOnInputChange}
            selectedValue={selectedValue}
            class={'commoditiessearch-ahecc-container marketssearch-ahecc-container'}
            ariaLabel={props.ariaLabel}
            onFocus={props.onFocus}
        />
    );
};
export default NonAgriMarketsSearch;
