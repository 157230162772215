import { PageOrchestratorProps } from '../models/PageOrchestratorProps';
import { useEffect, useState } from 'react';
import { getItemMultipleChoiceList } from '@exporter-services/common-ui/src/utils/contentItemHelper';

export default function usePageOrchestratorComponent(props: PageOrchestratorProps, componentSelectorCodename: string) {
    const [isComponentSelector, setisComponentSelector] = useState<boolean>(false);

    useEffect(() => {
        const componentSelectorList = getItemMultipleChoiceList(props.data.item);
        const componentSelector = componentSelectorList?.length > 0 && componentSelectorList[0];

        componentSelector &&
            componentSelector.value?.length > 0 &&
            componentSelector.value.forEach((value) => {
                if (value.codename === componentSelectorCodename) {
                    setisComponentSelector(true);
                }
            });
    }, [props.data.item]);

    return isComponentSelector;
}
