import React, { useState, useEffect, useRef } from 'react';
import './FilterCheckbox.scss';
import { FilterItemInteraction } from './FilerItemInteraction';
import { ReactComponent as FilterTick } from '../../../assets/filterTick.svg';
import TagManager from 'react-gtm-module';
import { SPACE_KEY } from '../../../utils/interactionHelper';

export interface FilterCheckboxProps {
    data?: {
        id: string;
        value?: string;
        checked: boolean;
        label?: string;
        count?: number;
        clearState: boolean;
    };
    onChange?: Function;
}

export const FilterCheckbox = (props: FilterCheckboxProps) => {
    const [isChecked, setChecked] = useState<boolean>(false);
    const checkboxRef = useRef(null);
    const DL_EVENT_NAME = 'checkbox_click';

    useEffect(() => {
        if (props.data.clearState) {
            setChecked(false);
        }
    }, [props.data.clearState]);

    useEffect(() => {
        setChecked(props.data.checked);
    }, [props.data.checked]);

    const handleOnChange = (e) => {
        let setItem: FilterItemInteraction = { value: props.data.value, text: props.data.label, selected: !isChecked };
        props.onChange && props.onChange(setItem);
        let newStatus = !isChecked;
        setChecked(newStatus);

        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_checkbox_status: newStatus,
                dlv_checkbox_text: props.data.value,
            },
        });
    };

    const handleOnKeyPress = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.key === SPACE_KEY) {
            checkboxRef.current.click();
        }
    };

    const handleCheckBoxLabelOnClick = (e: React.MouseEvent<HTMLLabelElement>) => {
        const checkbox = e.currentTarget?.parentElement?.querySelector('input');
        if (checkbox) {
            setTimeout(() => {
                checkbox.blur();
            }, 30);
        }
    };

    return (
        <div className="filter-checkbox">
            <div className="filter-checkbox-item">
                <div className="filter-checkbox-field-inner">
                    <input
                        ref={checkboxRef}
                        type="checkbox"
                        id={props.data.id}
                        onChange={handleOnChange}
                        checked={isChecked}
                        aria-checked={isChecked}
                    />
                    <label
                        onKeyPress={handleOnKeyPress}
                        htmlFor={props.data.id}
                        className={'filter-checkbox-label'}
                        aria-label={
                            props.data.label + ' ' + (props.data.count ? props.data.count : 0) + (props.data.count > 1 ? ' results' : 'result')
                        }
                        onClick={handleCheckBoxLabelOnClick}
                    >
                        {props.data.label}
                        {props.data.count > 0 && <>&nbsp;({props.data.count})</>}
                        <FilterTick />
                    </label>
                </div>
            </div>
        </div>
    );
};
