import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import './TickBoxList.scss';
import { ContentItem } from '@exporter-services/common-ui';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/utils';
import { useEffect } from 'react';

interface TickBoxListProps {
    data: {
        item: ContentItem;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
}

const TickBoxList = (props: TickBoxListProps) => {
    const { personalisationState } = useContext(personalisationContext);

    const handleLoadDone = (dataAvailable: boolean, title: string): void => {
        props.onLoad(true, title);
    };
    useEffect(() => {
        handleLoadDone(true, null);
    }, []);
    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements?.industry_sector) ? (
                <></>
            ) : (
                <Col>
                    <Row className="tickbox-list">
                        <ResolveLink data={{ item: props.data.item, onLoad: handleLoadDone }} type={props.data.item.system.type} />
                    </Row>
                </Col>
            )}
        </>
    );
};

export default TickBoxList;
