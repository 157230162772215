import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container } from 'reactstrap';
import { Elements } from '@kontent-ai/delivery-sdk';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { capitalise } from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getCurrentMarketNameInfo } from '../../providers/reducers/marketSlice';
import { CurrentMarketNameInfo, MvProductInfo } from '../../models/ReduxModels';
import { getMVProductInfo } from '../../providers/reducers/mvLawsAndRegsSlice';
import { useGetMvImportDocumentDataQuery } from '../../providers/reducers/mvLawsAndRegsApi';
import EbgaHead from '../EbgaHead';
import { useGetHSCodeLabelQuery } from '../../providers/reducers/commoditiesApi';
import { getPageHeaderBackgroundStyling } from './PageHeaderCommon';

interface LarPageHeaderProps {
    data: {
        type: any;
        title: any;
        title_tag: string;
        titleNumber?: Elements.NumberElement;
        metaDescription: string;
        description: any;
        image: any;
        label?: any;
        lastUpdated_at?: any;
        urlSlug?: Elements.UrlSlugElement;
        backgroundColorTheme?: Elements.MultipleChoiceElement;
    };
    match: any;
}

const LarPageHeader = (props: LarPageHeaderProps) => {
    const [sectorLabel, setSectorLabel] = useState(null);
    const [marketName, setMarketName] = useState(null);
    const [sectorLoading, setSectorLoading] = useState<boolean>(true);
    const [marketLoading, setMarketLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [documentName, setDocumentName] = useState(null);
    const dispatch = useAppDispatch();

    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);

    const mvProductInfo = useAppSelector<MvProductInfo>((state) => state.mvLawsAndRegs.mvProductInfo);
    const fetchMVProductInfo = useCallback(
        () => dispatch(getMVProductInfo({ sector: props.match.params.sector, market: props.match.params.market })),
        [props.match.params.sector, props.match.params.market],
    );

    const { data: hsCodeLabel, isSuccess: hsCodeLabelSuccess } = useGetHSCodeLabelQuery(
        { hsCode: props.match.params.sector },
        { skip: !(props.match.params.sector && (props.match.params.sector.length === 4 || props.match.params.sector.length === 6)) },
    );

    const { data, isSuccess } = useGetMvImportDocumentDataQuery(
        { sector: props.match.params.sector, market: props.match.params.market, documentId: props.match.params.document },
        { skip: !props.match.params.sector || !props.match.params.market || !props.match.params.document },
    );

    useEffect(() => {
        dispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: props.match }));
        if (props.match.params.sector.length !== 4 && props.match.params.sector.length !== 6) {
            fetchMVProductInfo();
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (isSuccess && data) {
            setDocumentName(data.Title);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (props.match.params.market) {
            if (currentMarketNameInfo.retrieved) {
                setMarketName(currentMarketNameInfo.data);
                setMarketLoading(false);
                setLoading(false);
            }
        } else {
            setMarketLoading(false);
            setLoading(false);
        }
    }, [currentMarketNameInfo]);

    useEffect(() => {
        if (!mvProductInfo.retrieved) {
            return;
        }
        if (mvProductInfo.data) {
            setSectorLabel(mvProductInfo.data.Name);
        }
        setSectorLoading(false);
        setLoading(false);
    }, [mvProductInfo.retrieved, mvProductInfo.data?.Name]);

    useEffect(() => {
        if (!hsCodeLabelSuccess) {
            return;
        }
        if (hsCodeLabel) {
            setSectorLabel(hsCodeLabel.LabelWithoutCode);
        }
        setSectorLoading(false);
        setLoading(false);
    }, [hsCodeLabelSuccess, hsCodeLabel]);

    const showAriaInfo = () => !!props.data.label;

    const transformPageTitle = (title: string) => {
        let tempTitle = title.replace(/{{market}}/g, marketName);

        if (sectorLabel) {
            tempTitle = tempTitle.replace(/{{sector}}/g, sectorLabel);
        }

        if (title.indexOf('ahecc')) {
            tempTitle = tempTitle.replace(/{{ahecc}}/g, props.match.params.sector);
        }

        if (title.indexOf('document')) {
            tempTitle = tempTitle.replace(/{{document}}/g, documentName);
        }

        return tempTitle || '';
    };

    const getPageTitle = () => transformPageTitle(props.data.title);

    const getDescription = () => {
        let descriptionText = props.data.description.replace(/{{market}}/g, '<strong>' + marketName + '</strong>');
        if (sectorLabel) {
            const sector = capitalise(sectorLabel);
            descriptionText = descriptionText.replace(/{{sector}}/g, '<strong>' + sector + '</strong>');
        }
        if (props.data.description.indexOf('ahecc')) {
            descriptionText = descriptionText.replace(/{{ahecc}}/g, '<strong>' + props.match.params.sector + '</strong>');
        }

        return descriptionText;
    };

    const getStandardH1 = (showAria: boolean, testId: string, cssClass?: string) => (
        <h1 id="pageTitle" className={`${!!cssClass ? `${cssClass}` : ''}`} aria-hidden={!showAria} data-testid={testId}>
            {props.data.titleNumber?.value ? props.data.titleNumber.value + '.' : ''}{' '}
            {/*show loading overlay only on filtered market result (country page) or filtered strong market result (country page)*/}
            {props.match.params && (props.match.params.market || props.match.params.sector)
                ? !marketLoading && !sectorLoading
                    ? getPageTitle()
                    : ''
                : getPageTitle()}
        </h1>
    );

    return (
        <>
            <EbgaHead
                pageTitle={getPageTitle()}
                titleTag={props.data.title_tag}
                abstract={getDescription()}
                metaDescription={props.data.metaDescription}
                industry={props.match.params.market}
                sector={props.match.params.sector}
            />

            <LoadingOverlay active={loading || marketLoading || sectorLoading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <Container fluid className={`page-header ${getPageHeaderBackgroundStyling(props.data.backgroundColorTheme)}`} id="lar-page-header">
                    <Container>
                        <div className="content-header">
                            {props.data.image.value.length > 0 ? (
                                <Col className="d-flex">
                                    <div className="component-content p-md-5 p-4">
                                        {showAriaInfo() && (
                                            <>
                                                {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                                <div className="header-label">{props.data.label}</div>
                                            </>
                                        )}
                                        {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                        <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                    </div>
                                    <div className="component-image">
                                        {props.data.image.value && <img src={props.data.image.value[0].url} alt="" />}
                                    </div>
                                </Col>
                            ) : (
                                <Col md={8} sm={12} className="p-md-5 p-4">
                                    {showAriaInfo() && (
                                        <>
                                            {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                            <div className="header-label">{props.data.label}</div>
                                        </>
                                    )}
                                    {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                    <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                </Col>
                            )}
                        </div>
                    </Container>
                </Container>
            </LoadingOverlay>
        </>
    );
};

export default LarPageHeader;
