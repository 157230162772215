import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { NestedFilterItemInteraction, FilterItem } from '.';
import { ComMultiSelectUIItem } from '../../generic/formControls/ComMultiSelectUIItem';
import { FilterHeader } from './FilterHeader';
import { NestedFilterCheckbox } from './NestedFilterCheckbox';
import { NestedFilterItem } from './NestedFilterItem';

export interface FilterNestedCheckboxPanelProps {
    data?: {
        id?: string;
        controlWidth?: string;
        label?: string;
        nestedFilterItems?: NestedFilterItem[];
        clearState: boolean;
    };
    onChange?: Function;
}
interface SubPanelStateInterface {
    subPanelId: string;
    collapsedState: boolean;
}

type sectorIndexedCollection = { [id: string]: { label: string; industry: boolean } };

export const FilterNestedCheckboxPanel = (props: FilterNestedCheckboxPanelProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [subPanelStates, setSubPanelStates] = useState<SubPanelStateInterface[]>(() => {
        let tempSubPanelStates = [];
        for (let i = 0; i < props.data.nestedFilterItems.length; i++) {
            tempSubPanelStates.push({
                subPanelId: props.data.nestedFilterItems[i].value,
                subPanelText: props.data.nestedFilterItems[i].label,
                collapsedState: true,
            });
        }
        return tempSubPanelStates;
    });
    const [subSectorIndexedCollection] = useState<sectorIndexedCollection>(() => {
        let tempSubSectorIndexedCollection: sectorIndexedCollection = {};

        props.data.nestedFilterItems.forEach((nestedItem: NestedFilterItem) => {
            tempSubSectorIndexedCollection[nestedItem.value] = { label: nestedItem.label, industry: true };
            nestedItem.subFilterItems.forEach((item: FilterItem) => {
                tempSubSectorIndexedCollection[item.value] = { label: item.label, industry: false };
            });
        });

        return tempSubSectorIndexedCollection;
    });
    const [lozengeNames, setLozengeNames] = useState<{}>();

    useEffect(() => {
        if (props.data.clearState) {
            setLozengeNames({});
        }
    }, [props.data.clearState]);

    const headerOnClick = (open: boolean) => {
        setOpen(open);
    };

    const filterPanelClick = (item) => {
        let updatedSubPanelStates = [...subPanelStates];
        updatedSubPanelStates.forEach((subPanelState) => {
            subPanelState.collapsedState = subPanelState.subPanelId !== item.value;
        });
        setSubPanelStates(updatedSubPanelStates);
    };

    const handleChildCheckItemRemove = (itemValue: string, itemText: string) => {
        let newState = _.omit(lozengeNames, itemValue);
        setLozengeNames(newState);
    };

    const handleCheckBoxChange = (items: NestedFilterItemInteraction[]) => {
        let newState = Object.assign({}, lozengeNames);

        let isTriggerByParent =
            items.every((x: NestedFilterItemInteraction) => x.selected === true) ||
            items.every((x: NestedFilterItemInteraction) => x.selected === false);

        //industry
        if (items.length === 1 && items[0].parentValue) {
            let item = items[0];
            if (item.selected) {
                let key = item.parentValue;
                newState[key] = item.selected;
                setLozengeNames(newState);
            } else {
                newState = _.omit(lozengeNames, item.parentValue);
                setLozengeNames(newState);
            }
        }

        //sectors
        items.forEach((item: NestedFilterItemInteraction) => {
            if (item.childValue && item.selected) {
                let key = item.childValue;
                newState[key] = item.selected;
                setLozengeNames(newState);
            }
            if (item.childValue && item.selected === false && isTriggerByParent === false) {
                newState = _.omit(lozengeNames, item.childValue);
                setLozengeNames(newState);
            }
            if (item.parentValue && item.selected === false && item.childValue === undefined) {
                let parentIndex = props.data.nestedFilterItems.findIndex((checkitem: NestedFilterItem) => checkitem.value === item.parentValue);
                props.data.nestedFilterItems[parentIndex].subFilterItems.forEach((subItem) => {
                    newState = _.omit(newState, subItem.value);
                });
                setLozengeNames(newState);
            }
        });
        props.onChange && props.onChange(items);
    };

    const getSelectedSubSectorLozenges = () => {
        return (
            lozengeNames &&
            Object.keys(lozengeNames).map((key) => {
                return (
                    <ComMultiSelectUIItem
                        key={`check-item-${key}`}
                        data={{ value: key, text: subSectorIndexedCollection[key].label }}
                        removeItemHandler={handleChildCheckItemRemove}
                    />
                );
            })
        );
    };

    return (
        <>
            <FilterHeader data={{ label: props.data.label, open: open }} onClick={headerOnClick} />
            <div className={`${open ? '' : 'mb-2'}`}>{getSelectedSubSectorLozenges()}</div>
            <div style={{ display: open ? 'block' : 'none' }}>
                {props.data.nestedFilterItems &&
                    props.data.nestedFilterItems?.map((item: NestedFilterItem, index: number) => (
                        <NestedFilterCheckbox
                            key={props.data.label + 'NestedFilterCheckbox2' + index}
                            data={{
                                id: item.value,
                                nestedFilterItem: item,
                                clearState: props.data.clearState,
                                open: false,
                                collapsedState: subPanelStates[index].collapsedState,
                                activeLozenges: lozengeNames,
                                name: props.data.label,
                            }}
                            onChange={handleCheckBoxChange}
                            onClick={() => filterPanelClick(item)}
                        />
                    ))}
            </div>
        </>
    );
};
