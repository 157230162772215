import { IContentItem, Responses } from '@kontent-ai/delivery-sdk';
import { client } from '../config';
import { ArticleType, NewsFilterItem } from '../models/NewsFilterItem';
import { PageName } from '../models/PageName';

export class NewsFilterService {
    private static newsItemIndex = 0;

    public getNewsFilterData = (): Promise<NewsFilterItem[]> => {
        return new Promise<NewsFilterItem[]>((resolve) => {
            const successStoryPromise = client.items().equalsFilter('system.type', 'case_study_page').toPromise();

            const newsFilterPromise = client.items().equalsFilter('system.type', 'news_article_page').toPromise();

            const blogArticlePromise = client.items().equalsFilter('system.type', 'blog_article_page').toPromise();

            let newsFilterItemList = [];

            Promise.allSettled([newsFilterPromise, successStoryPromise, blogArticlePromise]).then((results) => {
                results.forEach((response) => {
                    const articleType = (response['value'].data.items as Responses.IListContentItemsResponse<IContentItem>)?.[0]?.system.type;
                    if (articleType) {
                        switch (articleType) {
                            case 'news_article_page':
                                newsFilterItemList = newsFilterItemList.concat(this.processData(response['value'].data, ArticleType.NewsArticle));
                                break;
                            case 'case_study_page':
                                newsFilterItemList = newsFilterItemList.concat(
                                    this.processData(response['value'].data, ArticleType.SuccessStoryArticle),
                                );
                                break;
                            case 'blog_article_page':
                                newsFilterItemList = newsFilterItemList.concat(this.processData(response['value'].data, ArticleType.BlogArticle));
                                break;
                        }
                    }
                });
                let newsFilterItemsSorted = this.sortByDateTimeDesc(newsFilterItemList);
                resolve(newsFilterItemsSorted);
            });
        });
    };

    public isNewsArticlePage(pageUrl: string): boolean {
        return (
            pageUrl.indexOf(PageName.NewsAndEvents) > -1 &&
            pageUrl.indexOf(PageName.NewsFilterPage) > -1 &&
            !pageUrl.endsWith(PageName.NewsFilterPage)
        );
    }

    processData = (response: any, articleType: ArticleType): NewsFilterItem[] => {
        let newsFilterItemsCleanData: any = response.items.filter((r: any) => {
            return !(!r.elements.title?.value || !r.elements.abstract?.value || !r.elements.last_updated?.value);
        });

        let newsFilterItems: NewsFilterItem[] = [...newsFilterItemsCleanData];

        newsFilterItems.forEach((item: NewsFilterItem) => {
            item.id = (NewsFilterService.newsItemIndex++).toString();
            item.title = item.elements.title.value;
            item.abstract = item.elements.abstract?.value;
            item.urlSlug = item.elements.url?.value;
            item.lastUpdated = item.elements.last_updated?.value ? new Date(item.elements.last_updated?.value) : null;
            item.imageLink = '';
            item.imageCodeName = item.elements.image_or_video?.value?.length > 0 ? item.elements.image_or_video?.value[0] : '';
            item.articleType = articleType;
        });

        newsFilterItems.forEach((item: NewsFilterItem) => {
            let [assetUrl, caption] = this.findAssetUrl(item.imageCodeName, response.linkedItems);
            item.imageLink = assetUrl;
            item.imageCaption = caption;
        });

        newsFilterItems = this.sortByDateTimeDesc(newsFilterItems);

        return newsFilterItems;
    };

    findAssetUrl = (codeName: string, linkedItems: any): [string, string] => {
        let contentItem = linkedItems && linkedItems[codeName] && linkedItems[codeName];

        let assetUrl = contentItem?.elements.image?.value?.length > 0 ? contentItem.elements.image.value[0].url : null;
        let caption = contentItem?.elements.caption?.value;
        return [assetUrl, caption];
    };

    sortByDateTimeDesc = (articleItems: NewsFilterItem[]) => {
        if (!articleItems) return null;
        return articleItems.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
    };
}
