import React from 'react';
import { Col, Row } from 'reactstrap';
import './FeatureImagePathway.scss';
import { Elements } from '@kontent-ai/delivery-sdk';
import { ComImage, ComPathwayAnchor } from '@exporter-services/common-ui';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';
import { client } from '../../config';
import { FeatureImageItem } from './FeatureImageItem';
import ResolveHeadingLevel from '../resolveHeadingLevel/ResolveHeadingLevel';
import { useUrlParams } from '../../hooks/useUrlParams';

export interface FeatureImagePathwayProps {
    data: {
        item: FeatureImageItem;
    };
    match?: any;
}

const FeatureImagePathway = (props: FeatureImagePathwayProps) => {
    const urlCodes = useUrlParams(props.match);
    const featureImagePathwayItem = filterContentItemAgainstUrlParams(props.data.item, urlCodes);

    if (!featureImagePathwayItem) return null;

    return (
        <Row>
            <Col className="feature-image-pathway-wrapper" key={featureImagePathwayItem.system.id}>
                <ComPathwayAnchor
                    data={{
                        link: null,
                        pathway_link: featureImagePathwayItem.elements.pathway__link as Elements.RichTextElement,
                    }}
                    client={client}
                    className="feature-image-pathway-anchor"
                    title={featureImagePathwayItem.elements.title.value}
                >
                    <div className="feature-image-pathway-card">
                        <div className="feature-image-pathway-card-content-left">
                            <div className="feature-image-pathway-card-text">
                                <ResolveHeadingLevel
                                    data={{
                                        headingLevel: featureImagePathwayItem.elements.headinglevelsnippet__level,
                                        titleText: featureImagePathwayItem.elements?.title?.value,
                                    }}
                                />
                                <div
                                    className="feature-image-pathway-card-description"
                                    dangerouslySetInnerHTML={{
                                        __html: featureImagePathwayItem.elements.description.value,
                                    }}
                                ></div>
                            </div>

                            <div className="feature-image-pathway-footer">
                                <div className="circle-background">
                                    <span className="arrow-right-black-icon"></span>
                                </div>
                            </div>
                        </div>
                        {featureImagePathwayItem.elements.image.value.length > 0 && (
                            <div className="feature-image-pathway-card-content-right">
                                <ComImage
                                    src={featureImagePathwayItem.elements.image.value[0].url}
                                    alt={featureImagePathwayItem.elements.image.value[0].description}
                                />
                            </div>
                        )}
                    </div>
                </ComPathwayAnchor>
            </Col>
        </Row>
    );
};

export default FeatureImagePathway;
