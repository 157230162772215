import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { useGetMicorLinksForCommoditiesQuery } from '../../providers/reducers/commoditiesApi';

interface MicorProhibitedExportRequirementsProps {
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
    type?: string;
}

const MicorProhibitedExportRequirements = (props: MicorProhibitedExportRequirementsProps) => {
    const {
        data: micorData,
        isSuccess: micorDataSuccess,
        isFetching: micorDataFetching,
    } = useGetMicorLinksForCommoditiesQuery({
        sector: props.match.params.sector,
        market: props.match.params.market,
    });

    const renderData =
        micorData &&
        micorData.ProhibitedCommodities.map((x, index) => {
            return (
                <li key={`${index}-${x.Label}`}>
                    <a href={x.Link}>{x.Label} importing requirements</a>
                </li>
            );
        });
    return (
        <LoadingOverlay active={micorDataFetching} spinner={<SyncLoader />} text="Please wait" className="loader">
            <div>
                {micorDataSuccess && (
                    <>
                        <div> {micorData.ProhibitedCommodities.length > 0 && <ul>{renderData}</ul>}</div>
                    </>
                )}
            </div>
        </LoadingOverlay>
    );
};

export default MicorProhibitedExportRequirements;
