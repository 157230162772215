export const formatSearchString = (searchString) => {
    let sublabel = searchString.split('– ');
    searchString = sublabel[0] + '– ' + sublabel[1].trim().charAt(0).toUpperCase() + sublabel[1].slice(1).toLowerCase();
    if (searchString.indexOf(':') > -1) {
        let colonSubString = searchString.split(':');
        searchString = colonSubString[0] + ' : ' + colonSubString[1].trim().charAt(0).toUpperCase() + colonSubString[1].slice(2).toLowerCase();
    }

    return searchString;
};
