import React, { useEffect, useState } from 'react';
import { PageOrchestratorProps } from '../../models/PageOrchestratorProps';
import LawAndRegsPageOrchestrator from './LawsAndRegsPageOrchestrator';
import { getItemMultipleChoiceList } from '@exporter-services/common-ui/src/utils/contentItemHelper';

const PageOrchestrator = (props: PageOrchestratorProps) => {
    const [addLawAndRegs, setaddLawAndRegs] = useState<boolean>(false);

    useEffect(() => {
        const componentSelectorList = getItemMultipleChoiceList(props.data.item);
        const componentSelector = componentSelectorList?.length > 0 && componentSelectorList[0];

        componentSelector &&
            componentSelector.value?.length > 0 &&
            componentSelector.value.forEach((cs) => {
                switch (cs.codename) {
                    case 'laws_and_regulations_export_requirements':
                    case 'laws_and_regulations_duties_and_taxes':
                        setaddLawAndRegs(true);
                        break;
                }
            });
    }, [props.data.item]);

    return <>{addLawAndRegs && <LawAndRegsPageOrchestrator data={props.data} match={props.match} />}</>;
};

export default PageOrchestrator;
