import React, { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import './AccordionItem.scss';
import { AccordionContentItem } from './AccordionContentItem';
import ChevronUpIcon from '../../assets/chevronUp333.svg';
import ChevronDownIcon from '../../assets/chevronDown333.svg';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/utils';

interface AccordionItemProps {
    item: AccordionContentItem;
    expanded: boolean;
}

const AccordionItem = (props: AccordionItemProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded]);

    const { personalisationState } = useContext(personalisationContext);

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.click();
        }
    };

    return (
        <>
            {!validatePersonalisation(personalisationState, props.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <Row>
                    <Col className="accordion-item">
                        <div
                            className={`accordion-item-tile ${expanded ? 'open' : ''}`}
                            title={expanded ? 'Collapse' : 'Expand'}
                            role={'tab'}
                            aria-expanded={expanded ? 'true' : 'false'}
                            onClick={() => setExpanded((expanded) => !expanded)}
                            onKeyPress={handleOnKeyPress}
                            tabIndex={0}
                        >
                            <div className="accordion-item-tile-inner">
                                <div className="accordion-item-tile-inner-title">
                                    <h4 className={expanded ? 'expanded' : 'closed'} data-testid="h4-title">
                                        {' '}
                                        {props.item && props.item.elements.title.value}
                                    </h4>
                                </div>
                                <button
                                    className={`arrow ${expanded ? 'expanded' : 'closed'}`}
                                    aria-label={props.item && props.item.elements.title.value}
                                    aria-expanded={expanded}
                                    data-testid={'accordion-item-button'}
                                >
                                    <div className="float-button-content">
                                        <img src={!expanded ? ChevronDownIcon : ChevronUpIcon} alt=""></img>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className={`accordion-item-content ${expanded ? 'visible' : ''}`}>
                            <ResolveLink data={{ item: props.item }} type={props.item.system.type} />
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default AccordionItem;
