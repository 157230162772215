import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import './TariffsSearchToolSearchContainer.scss';
import '../../controls/search/SearchFilterGroup.scss';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import CommoditiesSearch, { Commodity } from '../../controls/search/CommoditiesSearch';
import { CommoditySearchItem } from '../../controls/search/CommoditySearchItem';
import NotListed from '../../controls/notListed/NotListed';
import TagManager from 'react-gtm-module';
import TariffFinderNarrowDownSearchTree from './narrowDownSearchTree/TariffFinderNarrowDownSearchTree';
import { MarketOption } from '../../models/MarketOption';
import TariffsMarketsSearch from './tariffMarketSearch/TariffsMarketsSearch';
import { PageName } from '../../models/PageName';
import { HscodeHierarchy } from '../../models/HscodeHierarchy';
import { scrollTo } from '../../utils/scrollUtils';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { TooltipText } from '../../controls/tooltip/TooltipText';
import { CommonConstants } from '../../CommonConstants';

interface TariffsSearchToolSearchContainerProps {
    data: {
        item?: ContentItem;
        linkedItems?: IContentItemsContainer;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
}

const TariffsSearchToolSearchContainer = (props: TariffsSearchToolSearchContainerProps) => {
    const DL_EVENT_NAME = 'tariffs_search_tool_search_button_click';
    const [clearSelection, setClearSelection] = useState<boolean>(false);
    const [selectedMarket, setSelectedMarket] = useState<MarketOption>(null);
    const [showSearchButton, setShowSearchButton] = useState<boolean>(false);
    const [showNotListedProductInformation, setShowNotListedProductInformation] = useState<boolean>(false);
    const [showNotListedMarket, setShowNotListedMarket] = useState<Boolean>(false);
    const [showNotSureMarket, setShowNotSureMarket] = useState<Boolean>(false);
    const [strongMarketEntity, setStrongMarketEntity] = useState(null);
    const [showRulesAndRegsNarrowDownSearch, setShowRulesAndRegsNarrowDownSearch] = useState<boolean>(false);
    const [selectedHsCode, setSelectedHsCode] = useState('');
    const [selectedNarrowedDownHsCode, setSelectedNarrowedDownHsCode] = useState<string>();
    const commoditiesDropdownRef = useRef(null);
    const marketDropdownRef = useRef(null);
    const { isMobile, isTablet } = useGetDisplayType();
    const [hsCodesHelpText, setHsCodesHelpText] = useState<[string, string]>(null);

    useEffect(() => {
        const extractStrongMarketEntityForProductSearch = () => {
            setStrongMarketEntity(
                props.data.item?.elements.list_of_questions['linkedItems'][1].elements.dropdown_item['linkedItems'].filter(
                    (v) => v.elements.industry_sector?.value[0]?.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME,
                )[0],
            );
        };

        extractStrongMarketEntityForProductSearch();
        extractHSCodesHelpText();
    }, []);

    useEffect(() => {
        if (selectedHsCode && selectedMarket && selectedHsCode !== selectedNarrowedDownHsCode) {
            setShowRulesAndRegsNarrowDownSearch(true);
        } else {
            setShowRulesAndRegsNarrowDownSearch(false);
        }
    }, [selectedHsCode, selectedMarket]); //ok to use object as dependency object as it is used more as struct instead of class

    const extractHSCodesHelpText = () => {
        let hsCodesHelpTextResult = props.data.item?.elements.list_of_questions['linkedItems'].find((item) => {
            if (item?.system?.codename === CommonConstants.KONTENT_HSCODES_TOOLTIP_CODENAME) {
                return item;
            }
            return null;
        });

        if (hsCodesHelpTextResult) {
            let header = hsCodesHelpTextResult.elements.header?.value;
            let description = hsCodesHelpTextResult.elements.description?.value;
            if (header && description) {
                setHsCodesHelpText([header, description]);
            }
        }
    };

    const handleSearchOnClick = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_product_code: selectedNarrowedDownHsCode,
                dlv_service_or_product: 'product',
                dlv_market: selectedMarket ? selectedMarket.Code : undefined,
            },
        });

        window.location.href = getSearchButtonHref();
    };

    const onCommodityClick = (selectedOption: Commodity) => {
        setSelectedHsCode(selectedOption?.Code);
        let isListed = selectedOption?.Code !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME && selectedOption?.Code !== null;
        if (!isListed) {
            setSelectedHsCode('');
            setShowSearchButton(false);
        }
        hideAllWarnings();
        setShowNotListedProductInformation(selectedOption && !isListed);
        setShowSearchButton(false);
    };

    const onRulesAndRegulationsNarrowDownSearchSectorSelected = (selectedSector: string) => {
        setSelectedNarrowedDownHsCode(selectedSector);
        if (selectedSector) {
            setShowSearchButton(true);
        } else {
            setSelectedNarrowedDownHsCode(null);
            setShowSearchButton(false);
        }
    };

    const onMarketsSelection = (selectedOption: MarketOption) => {
        setClearSelection(false);
        setShowNotListedMarket(false);
        setShowNotSureMarket(false);
        setShowSearchButton(selectedHsCode === selectedNarrowedDownHsCode);

        if (!selectedOption) {
            setSelectedMarket(null);
            return;
        }
        if (
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME ||
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME
        ) {
            setSelectedMarket(null);
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                setShowNotSureMarket(false);
                setShowNotListedMarket(true);
            }
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME) {
                setShowNotListedMarket(false);
                setShowNotSureMarket(true);
            }
            setSelectedHsCode('');
            setShowNotListedProductInformation(false);
            return;
        }
        setSelectedMarket(selectedOption ?? null);
    };

    const hideAllWarnings = (): void => {
        setShowNotListedMarket(false);
        setShowNotSureMarket(false);
        setShowNotListedProductInformation(false);
    };

    const getNotListedProduct = () => {
        return props.data.linkedItems[props.data.linkedItems.tariff_product_search.elements.not_listed['linkedItemCodenames'][0]];
    };

    const getMarketNotListed = () => {
        const allProductsLink = props.data.linkedItems[props.data.linkedItems.tariff_product_search.elements.dropdown_item.value[0]];
        const lawsAndRegsSearch = props.data.linkedItems[allProductsLink.elements.filtered_component.value[0]];
        return props.data.linkedItems[lawsAndRegsSearch.elements.market_not_listed['linkedItemCodenames'][0]];
    };

    const getMarketNotSure = () => {
        const allProductsLink = props.data.linkedItems[props.data.linkedItems.tariff_product_search.elements.dropdown_item.value[0]];
        const lawsAndRegsSearch = props.data.linkedItems[allProductsLink.elements.filtered_component.value[0]];
        return props.data.linkedItems[lawsAndRegsSearch.elements.market_not_sure['linkedItemCodenames'][0]];
    };

    const getSearchButtonHref = () => {
        const commodityMarketUrlFragment = `${selectedNarrowedDownHsCode}/${selectedMarket?.Code.toLowerCase()}`;

        return `/${PageName.PricingCostsAndFinance}/${PageName.FindTariffs}/tariffs/${commodityMarketUrlFragment}`;
    };

    const clearFilter = () => {
        setClearSelection(true);
        setSelectedMarket(null);
        setSelectedHsCode('');
        setShowNotSureMarket(false);
        setShowNotListedMarket(false);
        setShowNotListedProductInformation(false);
        setShowSearchButton(false);
        if (isMobile || isTablet) {
            scrollTo(marketDropdownRef);
        }
    };

    const onHierarchyRetrieved = (result: HscodeHierarchy[]) => {
        if (!result || result.length === 0) {
            setShowNotListedProductInformation(true);
            setShowRulesAndRegsNarrowDownSearch(false);
        } else if (result[0].Children.length === 0) {
            setShowNotListedProductInformation(false);
            setShowRulesAndRegsNarrowDownSearch(false);
            setSelectedNarrowedDownHsCode(selectedHsCode);
            setShowSearchButton(true);
        } else {
            setShowNotListedProductInformation(false);
            setShowRulesAndRegsNarrowDownSearch(true);
        }
    };

    const marketDropdownFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(marketDropdownRef);
        }
    };

    const commoditiesDropdownFocus = () => {
        if (isMobile || isTablet) {
            // fix for third party dropdown control -scroll issue
            setTimeout(() => {
                scrollTo(commoditiesDropdownRef);
            }, 100);
        }
    };

    return (
        <>
            <Row className="tariffssearchtoolsearchcontainer-box tariffssearchtoolsearchcontainer-box-container">
                <Col md="12">
                    <div className="filtergroup-form-field-container" ref={marketDropdownRef}>
                        <label className="field-label" htmlFor="productsMarketSearch">
                            {props.data.item?.elements.list_of_questions['linkedItems'][1].elements.nonagri_market_label?.value}
                        </label>
                        <TariffsMarketsSearch
                            onClick={onMarketsSelection}
                            ariaLabel={props.data.item?.elements.list_of_questions['linkedItems'][1].elements.nonagri_market_label?.value}
                            clearSelection={clearSelection}
                            onFocus={marketDropdownFocus}
                        />
                    </div>

                    {selectedMarket && (
                        <div className="filtergroup-form-field-container" ref={commoditiesDropdownRef}>
                            <label className="field-label" htmlFor="commodities-search">
                                {strongMarketEntity?.elements.information_for?.value}
                            </label>
                            <CommoditiesSearch
                                item={strongMarketEntity}
                                searchItem={strongMarketEntity?.elements.filtered_component['linkedItems'][0] as CommoditySearchItem}
                                onClick={onCommodityClick}
                                PnPFilter={false}
                                industryCode="food-and-agribusiness"
                                commodityCode={null}
                                isTariffFinder={true}
                                ariaLabel={strongMarketEntity?.elements.information_for?.value}
                                clearSelection={clearSelection}
                                onFocus={commoditiesDropdownFocus}
                            />
                            {hsCodesHelpText && <TooltipText data={{ header: hsCodesHelpText[0], description: hsCodesHelpText[1] }} />}
                        </div>
                    )}

                    {showRulesAndRegsNarrowDownSearch && (
                        <div className="filtergroup-form-field-container">
                            <label className="field-label" htmlFor="productsMarketSearch">
                                {props.data.linkedItems['tariffs_search_tool_search_container']?.elements.narrow_down_search_label?.value}
                            </label>
                            <TariffFinderNarrowDownSearchTree
                                data={{
                                    market: selectedMarket?.Code,
                                    sector: selectedHsCode,
                                }}
                                onSectorSelected={onRulesAndRegulationsNarrowDownSearchSectorSelected}
                                onDataRetrieved={onHierarchyRetrieved}
                            />
                        </div>
                    )}
                </Col>
            </Row>

            <Row>
                {/*----- Not listed Product ------*/}
                {!showNotListedMarket && !showNotSureMarket && showNotListedProductInformation && (
                    <Col>
                        <NotListed
                            data={{ item: getNotListedProduct(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'search_dropdown_question'}
                        />
                    </Col>
                )}

                {/*----- Not listed Market ------*/}
                {showNotListedMarket && (
                    <Col>
                        <NotListed
                            data={{ item: getMarketNotListed(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'market_not_listed'}
                        />
                    </Col>
                )}

                {/*----- Market not sure ------*/}
                {showNotSureMarket && (
                    <Col>
                        <NotListed
                            data={{ item: getMarketNotSure(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'market_not_sure'}
                        />
                    </Col>
                )}
            </Row>

            <Row>
                <Col>
                    {
                        <div className="filtergroup-filter-footer filter-footer button-container">
                            <ComButton
                                id="clearFilters"
                                variant="link"
                                className="filtergroup-clear-filters refresh-icon"
                                onClick={clearFilter}
                                aria-pressed="false"
                            >
                                <span>Start again</span>
                            </ComButton>

                            {showSearchButton && (
                                <ComButton
                                    onClick={handleSearchOnClick}
                                    id="personalization_filter_selection"
                                    className="sticktoright"
                                    data-gtm-market={selectedMarket && selectedMarket.Code}
                                    data-gtm-commodity={selectedNarrowedDownHsCode}
                                    data-gtm-clickurl={selectedMarket && getSearchButtonHref()}
                                >
                                    See results
                                </ComButton>
                            )}
                        </div>
                    }
                </Col>
            </Row>
        </>
    );
};
export default TariffsSearchToolSearchContainer;
