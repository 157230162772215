import { IContentItem, ElementModels } from '@kontent-ai/delivery-sdk';

type MultipleChoiceElement = {
    name: string;
    type: string;
    value: Array<ElementModels.MultipleChoiceOption>;
};

export const isPropertyMultipleChoice = (property: Record<string, any>): boolean => {
    return property?.type === 'multiple_choice';
};

export const getMultipleChoiceSelectedOptions = (multipleChoiceElement: MultipleChoiceElement): Array<ElementModels.MultipleChoiceOption> => {
    return (
        multipleChoiceElement && multipleChoiceElement.value?.length > 0 && (multipleChoiceElement.value as Array<ElementModels.MultipleChoiceOption>)
    );
};

//gets all multiple choice taxonomy selected
export const getItemMultipleChoiceList = (contentItem: IContentItem): Array<MultipleChoiceElement> => {
    return (
        contentItem &&
        (() => {
            let response: Array<MultipleChoiceElement> = new Array<MultipleChoiceElement>();
            for (let k in contentItem.elements) {
                if (isPropertyMultipleChoice(contentItem.elements[k]) && getMultipleChoiceSelectedOptions(contentItem.elements[k])?.length > 0) {
                    response.push(contentItem.elements[k]);
                }
            }
            return response;
        })()
    );
};

export const getImageItemByMarketCode = (imageItems: IContentItem[], marketCode: string) => {
    let imageItem = imageItems?.filter((r) => {
        if (r.elements.marketcodesnippet__market?.value?.length > 0) {
            let findIndex = r.elements.marketcodesnippet__market?.value.findIndex((x: any) => x.codename.toLowerCase() === marketCode.toLowerCase());
            return findIndex > -1;
        }
        return false;
    });

    if (imageItem?.length > 0 && imageItem[0].elements.image) return imageItem[0];
    else return null;
};

export const getImageItemDescription = (imageItem: IContentItem) => {
    if (imageItem?.elements?.image?.value && imageItem.elements.image.value.length > 0 && imageItem.elements.image.value[0].description)
        return imageItem.elements.image.value[0].description;
    else return '';
};

export const getImageItemUrl = (imageItem: IContentItem) => {
    if (imageItem?.elements?.image?.value && imageItem.elements.image.value.length > 0 && imageItem.elements.image.value[0].url)
        return imageItem.elements.image.value[0].url;
    else return '';
};

export const getImageItemSystemId = (imageItem: IContentItem) => {
    return imageItem?.system?.id;
};
