import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import LayoutResolver from '../LayoutResolver';
import PageBody from '../pageBody/PageBody';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import Breadcrumb from '../../site/breadcrumb/Breadcrumb';
import { client } from '../../config';
import Layout from '../../site/Layout';
import PageHeader from '../pageHeader/PageHeader';
import LarPageHeader from '../pageHeader/LarPageHeader';
import FilteredSearchtoolPageHeader from '../pageHeader/FilteredSearchtoolPageHeader';
import NewsArticlePageHeader from '../pageHeader/NewsArticlePageHeader';
import TagManager from 'react-gtm-module';
import { PageItem } from '../../models/PageItem';
import NotFound from '../errorPages/NotFound';
import LoadingOverlay from 'react-loading-overlay-ts';
import { PersonalisationStore } from '../../providers/personalisation';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setApplicableBootstrapBreakpoints, setPageInfo } from '../../providers/reducers/pageSlice';
import _ from 'lodash';
import { getCurrentMarketNameInfo } from '../../providers/reducers/marketSlice';
import { CurrentMarketNameInfo } from '../../models/ReduxModels';
import { useLocation, useParams } from 'react-router-dom';
import { LawsAndRegsPageService } from '../../services/LawsAndRegsPageService';
import useSector from '../../hooks/useSector';
import { PageName } from '../../models/PageName';
import { NewsFilterService } from '../../services/NewsFilterService';
import { useGetMvImportDocumentDataQuery } from '../../providers/reducers/mvLawsAndRegsApi';
import ErrorBoundary from '../../controls/error/ErrorBoundary';
import SiteSearchResultsHeader from '../pageHeader/SiteSearchResultsHeader';
import Pluralize from 'pluralize';

const Page = () => {
    const [content, setContent] = useState<PageItem>();
    const [sectorBreadCrumbLabel, setSectorBreadCrumbLabel] = useState('');
    const [linkedItems, setlinkedItems] = useState<IContentItemsContainer>();
    const [loading, setLoading] = useState<boolean>(true);
    const [documentName, setDocumentName] = useState(null);
    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);
    const lawsAndRegsService = useAppSelector<LawsAndRegsPageService>((state) => state.singletonServices.lawsAndRegsService);
    const newsFilterService = useAppSelector<NewsFilterService>((state) => state.singletonServices.newsFilterService);

    const dispatch = useAppDispatch();
    const resizeDebounceDelay = 25;
    let params;
    const { sector } = useSector();
    let { third_slug, industry, market, document: documentId } = (params = useParams());
    let { pathname } = useLocation();

    let urlPath = window.location.href;

    const { data: documentData, isSuccess: documentDataRetrieved } = useGetMvImportDocumentDataQuery(
        { sector, market, documentId },
        { skip: !sector || !market || !documentId },
    );

    let lastSlug;

    if (pathname.includes(PageName.ShortlistProducts)) {
        lastSlug = PageName.ShortlistProducts;
    } else if (pathname.includes(PageName.Shortlist)) {
        lastSlug = PageName.Shortlist;
    } else if (params && industry) {
        lastSlug = third_slug;
    } else if (pathname.includes(PageName.RulesAndRestrictionsDocument)) {
        lastSlug = PageName.RulesAndRestrictionsDocument;
    } else if (pathname.includes(PageName.RulesAndRestrictions)) {
        lastSlug = PageName.RulesAndRestrictions;
    } else if (pathname.includes(PageName.ProductRequirements)) {
        lastSlug = PageName.ProductRequirements;
    } else if (params && !industry && sector) {
        lastSlug = third_slug;
    } else {
        let parts = urlPath.split('/');
        lastSlug = parts.pop() || parts.pop();
        lastSlug = lastSlug.replace(/#.*/g, '').replace(/(\?.*)$/, '');
    }

    const handleResize = useCallback(() => {
        dispatch(setApplicableBootstrapBreakpoints({ pageWidth: window.innerWidth }));
    }, []);

    useEffect(() => {
        if (documentDataRetrieved && documentData) {
            setDocumentName(documentData.Title);
        }
    }, [documentData, documentDataRetrieved]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        setSectorBreadCrumbLabel(sector);
    }, [sector]);

    useEffect(() => {
        if (params && market) {
            dispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: { params: params } }));
        }

        client
            .items()
            .equalsFilter('elements.url', lastSlug)
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                setlinkedItems(response.data.linkedItems);
                setContent(response.data.items[0] as PageItem);
                setLoading(false);
                dispatch(setPageInfo({ codename: response.data.items[0].system.codename }));
                const tagManagerArgs = {
                    gtmId: 'GTM-5QD69QM',
                };
                TagManager.initialize(tagManagerArgs);
            });
        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', function () {
                onloadPosition();
            });
        }
        window.onload = function () {
            onloadPosition();
        };
    }, [lastSlug]);

    useEffect(() => {
        const debounced = _.debounce(handleResize, resizeDebounceDelay);
        window.addEventListener('resize', debounced);

        return () => {
            debounced.cancel();
            window.removeEventListener('resize', debounced); // use debounced directly
        };
    }, [handleResize]);

    function onloadPosition() {
        if (urlPath.indexOf('#') > -1) {
            let linkPageId = urlPath.split('#')[1];
            let pageId = document.getElementById(linkPageId);
            if (pageId) {
                const rect = pageId.getBoundingClientRect();
                document.documentElement.scrollTo(0, rect.top - 10);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }

    const checkFilteredSearchtool = () => {
        return lastSlug === PageName.MarketSearchTool || lastSlug === PageName.EventsSearchTool;
    };

    const checkSiteSearchResultsPage = () => {
        return lastSlug === PageName.SiteSearchResults;
    };

    const checkLarFunctionality = useCallback(() => {
        return (
            lastSlug === 'tariffs' ||
            lawsAndRegsService.isExportRulesRequirementsPage(lastSlug) ||
            lawsAndRegsService.isResultsRulesAndRestrictionsDocumentPage({ params })
        );
    }, [lawsAndRegsService]);

    const checkNewsFunctionality = useCallback(() => {
        return newsFilterService.isNewsArticlePage(window.location.href);
    }, [newsFilterService]);

    const buildLarBreadCrumb = (marketNameInfoRetrieved: boolean) => {
        const productsOrServices = urlPath.indexOf('/products') > -1 ? 'products' : urlPath.indexOf('/services') > -1 ? 'services' : null;

        if (marketNameInfoRetrieved && productsOrServices) {
            return urlPath.replace(
                new RegExp(`/${productsOrServices}.*`, 'g'),
                `/Exporting ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`,
            );
        } else if (marketNameInfoRetrieved && urlPath.indexOf(`${PageName.FindTariffs}/tariffs`) > -1) {
            return urlPath.replace(
                new RegExp('/tariffs.*', 'g'),
                `/Tariffs for exporting ${sectorBreadCrumbLabel} from Australia to ${currentMarketNameInfo.data}`,
            );
        }

        return urlPath;
    };

    const buildExportRulesFinderBreadCrumb = (marketNameInfoRetrieved: boolean) => {
        const isProduct = urlPath.indexOf('/product-') > -1;

        if (marketNameInfoRetrieved && urlPath.indexOf(`${PageName.ExportRulesFinder}/${PageName.ProductRequirements}`) > -1) {
            if (urlPath.indexOf(PageName.RulesAndRestrictionsDocument) > -1) {
                return urlPath.replace(
                    new RegExp(`/${PageName.ProductRequirements}.*`, 'g'),
                    `/Requirements ${isProduct ? 'HS code' : ''} ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}/${documentName}`,
                );
            }

            return urlPath.replace(
                new RegExp(`/${PageName.ProductRequirements}.*`, 'g'),
                `/Requirements ${isProduct ? 'HS code' : ''} ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`,
            );
        } else if (marketNameInfoRetrieved && urlPath.indexOf(`${PageName.ExportRulesFinder}/${PageName.ServiceRequirements}`) > -1) {
            return urlPath.replace(new RegExp(`/${PageName.ServiceRequirements}.*`, 'g'), `/Exporting to ${currentMarketNameInfo.data}`);
        }

        return urlPath;
    };

    const buildBreadCrumb = useCallback(() => {
        //Breadcrumbs based on URL location

        let marketNameInfoRetrieved = currentMarketNameInfo.retrieved;

        const url =
            marketNameInfoRetrieved && urlPath.indexOf('results') > -1
                ? urlPath.replace(/\/market-explorer-results.*/g, `/Exporting to ${currentMarketNameInfo.data}`)
                : urlPath.indexOf(`${PageName.FindTariffs}/tariffs`) > -1
                  ? buildLarBreadCrumb(marketNameInfoRetrieved)
                  : urlPath.indexOf(PageName.ExportRulesFinder) > -1
                    ? buildExportRulesFinderBreadCrumb(marketNameInfoRetrieved)
                    : urlPath.indexOf(PageName.Shortlist) > -1
                      ? marketNameInfoRetrieved
                          ? urlPath
                                .replace(/\/shortlist-products.*/g, `/${PageName.MarketSearchTool}/Exporting to ${currentMarketNameInfo.data}`)
                                .replace(/\/shortlist.*/g, `/${PageName.MarketSearchTool}/Exporting to ${currentMarketNameInfo.data}`)
                          : urlPath
                      : urlPath;

        const pageUrl = url
            .replace(/(\?.*)$/, '')
            .split('/')
            .slice(3);

        let urlParts: any[];
        urlParts = [
            {
                title: 'Home',
                url: '/',
            },
        ];

        const lastArray = pageUrl[pageUrl.length - 1];
        for (let j = 0; j < pageUrl.length; j++) {
            const urlPart = pageUrl[j];
            const link = '/' + pageUrl.slice(0, j + 1).join('/');

            let pageName = urlPart.replace(/-/g, ' ');
            pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);

            if (lastArray.replace(/-/g, ' ').toLowerCase() === pageName.toLowerCase() && pageUrl.length > 1) {
                urlParts.push({
                    title: pageName.replace(/#.*/g, ''),
                    url: '',
                });
            } else if (marketNameInfoRetrieved && pageName === `Exporting ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`) {
                urlParts.push({
                    title: pageName,
                    url: link.replace(pageName, `products/${sector}/${market}`),
                });
            } else if (marketNameInfoRetrieved && pageName === `Requirements HS code ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`) {
                urlParts.push({
                    title: pageName,
                    url: link.replace(pageName, `${PageName.ProductRequirements}/${sector}/${market}`),
                });
            } else {
                urlParts.push({
                    title: pageName,
                    url: pageUrl.length === 1 ? '' : link,
                });
            }
        }

        return urlParts;
    }, [currentMarketNameInfo.retrieved, sectorBreadCrumbLabel, documentName, lastSlug]);

    const breadCrumbData = buildBreadCrumb();
    const lastUpdatedAt = content?.elements.last_updated?.value ? new Date(content.elements.last_updated.value) : null;

    return (
        <PersonalisationStore>
            <LoadingOverlay active={loading} spinner text="Please wait" className="loader">
                {!loading && !content ? (
                    <NotFound />
                ) : (
                    <ErrorBoundary>
                        <Layout>
                            {content && breadCrumbData ? <Breadcrumb menus={breadCrumbData} /> : ''}
                            {content && checkFilteredSearchtool() ? (
                                <FilteredSearchtoolPageHeader
                                    data={{
                                        item: content,
                                        backgroundColorTheme: content.elements.page_header_color_theme__header_color,
                                    }}
                                    match={{ params: params }}
                                />
                            ) : content && checkSiteSearchResultsPage() ? (
                                <SiteSearchResultsHeader
                                    data={{
                                        title: content.elements.title.value,
                                        title_tag: content.elements.common_page_info__title_tag.value,
                                        description: content.elements.abstract.value,
                                        metaDescription: content.elements.common_page_info__meta_description_tag.value,
                                    }}
                                />
                            ) : content && checkLarFunctionality() ? (
                                <LarPageHeader
                                    data={{
                                        label: content.elements.label.value,
                                        title: content.elements.title.value,
                                        title_tag: content.elements.common_page_info__title_tag.value,
                                        titleNumber: content.elements.title_number,
                                        description: content.elements.abstract.value,
                                        metaDescription: content.elements.common_page_info__meta_description_tag.value,
                                        image: content.elements.image,
                                        type: content.system.type,
                                        lastUpdated_at: lastUpdatedAt,
                                        urlSlug: content.elements.url,
                                        backgroundColorTheme: content.elements.page_header_color_theme__header_color,
                                    }}
                                    match={{ params: params }}
                                />
                            ) : content && checkNewsFunctionality() ? (
                                <NewsArticlePageHeader
                                    data={{
                                        label: content.elements.label.value,
                                        title: content.elements.title.value,
                                        title_tag: content.elements.common_page_info__title_tag.value,
                                        titleNumber: content.elements.title_number,
                                        description: content.elements.abstract.value,
                                        metaDescription: content.elements.common_page_info__meta_description_tag.value,
                                        image_or_video: content.elements.image_or_video,
                                        type: content.system.type,
                                        lastUpdated_at: lastUpdatedAt as Date,
                                        urlSlug: content.elements.url,
                                        backgroundColorTheme: content.elements.page_header_color_theme__header_color,
                                    }}
                                />
                            ) : (
                                content && (
                                    <PageHeader
                                        data={{
                                            label: content.elements.label.value,
                                            title: content.elements.title.value,
                                            title_tag: content.elements.common_page_info__title_tag.value,
                                            titleNumber: content.elements.title_number,
                                            description: content.elements.abstract.value,
                                            metaDescription: content.elements.common_page_info__meta_description_tag.value,
                                            image: content.elements.image,
                                            type: content.system.type,
                                            lastUpdated_at: lastUpdatedAt,
                                            marketType: content.elements.market_type,
                                            urlSlug: content.elements.url,
                                            backgroundColorTheme: content.elements.page_header_color_theme__header_color,
                                        }}
                                        match={{ params: params }}
                                    />
                                )
                            )}
                            {content && (
                                <>
                                    <Helmet>
                                        <meta
                                            name="category"
                                            content={
                                                content.elements.category?.value?.[0]?.name ? Pluralize(content.elements.category.value[0].name) : ''
                                            }
                                            data-react-helmet="true"
                                        />
                                    </Helmet>
                                    <PageBody pageType={content.elements.page_type}>
                                        <LayoutResolver
                                            data={{
                                                item: content as PageItem,
                                                linkedItems: linkedItems,
                                            }}
                                            match={{ params: params }}
                                        />
                                    </PageBody>
                                </>
                            )}
                        </Layout>
                    </ErrorBoundary>
                )}
            </LoadingOverlay>
        </PersonalisationStore>
    );
};

export default Page;
