import React, { useState } from 'react';
import {
    FilterRadiobuttonPanel,
    FilterClear,
    FilterNestedCheckboxPanel,
    NestedFilterItemInteraction,
    NestedFilterItem,
    FilterCheckbox,
} from '@exporter-services/common-ui/src/components/shared/filterControls';
import { FilterItem, FilterItemInteraction } from '@exporter-services/common-ui/src/components/shared/filterControls';
import '../../styles/_filterPages.scss';
import { CommonConstants } from '../../CommonConstants';

export interface NewsFilterCriteria {
    articleTypeCriteriaList?: { [id: string]: boolean };
    regionMarketCriteriaList?: { [id: string]: boolean };
    industrySectorCriteriaList?: { [id: string]: boolean };
    sourceCriteria?: string;
    sortCriteria?: string;
    resultsCount?: number;
}

export interface FilterMainPanelProps {
    data?: {
        articleTypeList?: FilterItem[];
        regionMarketList?: NestedFilterItem[];
        industrySectorList?: NestedFilterItem[];
        sortList?: FilterItem[];
    };
    onChange?: (filterCriteria: NewsFilterCriteria) => void;
}

const FilterNewsPanel = (props: FilterMainPanelProps) => {
    const sortDefaultState = 'Newest';
    const FILTER_DEFAULT_STATE: NewsFilterCriteria = {
        articleTypeCriteriaList: {},
        regionMarketCriteriaList: {},
        industrySectorCriteriaList: {},
        sourceCriteria: null,
        sortCriteria: sortDefaultState,
    };

    let [filterCriteria, setFilterCriteria] = useState<NewsFilterCriteria>(FILTER_DEFAULT_STATE);

    let [clearState, setClearState] = useState<boolean>(false);

    const handleOnClearFilter = () => {
        setClearState(true);
        filterCriteria = JSON.parse(JSON.stringify(FILTER_DEFAULT_STATE));

        let newState = Object.assign({}, FILTER_DEFAULT_STATE);
        props.onChange && props.onChange(newState);
        setFilterCriteria(newState);
    };

    const handleArtilceTypeFilterChange = (filterItem: FilterItemInteraction) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);

        if (filterItem.selected) {
            newState.articleTypeCriteriaList[filterItem.value] = filterItem.selected;
        } else {
            if (filterItem.value in newState.articleTypeCriteriaList) {
                delete newState.articleTypeCriteriaList[filterItem.value];
            }
        }
        props.onChange && props.onChange(newState);
        setFilterCriteria(newState);
    };

    const handleIndustrySectorListPanelChange = (items: NestedFilterItemInteraction[]) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);
        items.forEach((item) => {
            let key = item.childValue ? item.parentValue + ':' + item.childValue : item.parentValue;
            newState.industrySectorCriteriaList[key] = item.selected;
        });
        props.onChange && props.onChange(newState);
        setFilterCriteria(newState);
    };

    const handleRegionMarketListPanelChange = (items: NestedFilterItemInteraction[]) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);
        items.forEach((item) => {
            let key = item.childValue ? item.parentValue + ':' + item.childValue : item.parentValue;
            newState.regionMarketCriteriaList[key] = item.selected;
        });

        props.onChange && props.onChange(newState);
        setFilterCriteria(newState);
    };

    const handleSortPanelChange = (item: string) => {
        setClearState(false);

        let newState = Object.assign({}, filterCriteria);

        newState.sortCriteria = item;
        props.onChange && props.onChange(newState);
        setFilterCriteria(newState);
    };

    return (
        <div className="filter-main-panel">
            {props.data.articleTypeList?.map((typeFilterItem: FilterItem, index: number) => {
                return (
                    <>
                        <hr className="filter-main-panel-separator" />
                        <FilterCheckbox
                            data={{
                                id: 'articleType_' + index,
                                value: typeFilterItem.value,
                                label: typeFilterItem.label,
                                count: typeFilterItem.count,
                                checked: filterCriteria.articleTypeCriteriaList[typeFilterItem.value] ?? false,
                                clearState: clearState,
                            }}
                            onChange={handleArtilceTypeFilterChange}
                        />
                    </>
                );
            })}

            {props.data.industrySectorList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterNestedCheckboxPanel
                        data={{
                            label: 'Industry',
                            nestedFilterItems: props.data.industrySectorList,
                            clearState: clearState,
                        }}
                        onChange={handleIndustrySectorListPanelChange}
                    />
                </>
            ) : null}

            {props.data.regionMarketList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterNestedCheckboxPanel
                        data={{ label: 'Region', nestedFilterItems: props.data.regionMarketList, clearState: clearState }}
                        onChange={handleRegionMarketListPanelChange}
                    />
                </>
            ) : null}

            <hr className="filter-main-panel-separator" />
            <FilterRadiobuttonPanel
                data={{
                    label: 'Sort',
                    itemList: props.data.sortList,
                    clearState: clearState,
                    defaultState: sortDefaultState,
                }}
                name={'fao-search-filter'}
                onChange={handleSortPanelChange}
            />

            <hr className="filter-main-panel-separator" />
            <FilterClear data={{ label: CommonConstants.CLEAR_FILTERS_LABEL }} onClick={handleOnClearFilter} />
        </div>
    );
};

export default FilterNewsPanel;
