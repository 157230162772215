import React, { useEffect, useState } from 'react';
import { client } from '../../config';
import { InlinePathway, InlinePathwayItem } from '@exporter-services/common-ui/src/index';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { getLawsAndRegsDataSource } from '../../providers/reducers/lawsAndRegsSlice';
import { PageName } from '../../models/PageName';
import { useUrlParams } from '../../hooks/useUrlParams';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';

interface MarketAccessProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const MarketAccess = (props: MarketAccessProps) => {
    const urlCodes = useUrlParams(props.match);
    const [content, setContent] = useState(null);
    const market = props.match.params.market.toUpperCase();
    const sector = props.match.params.sector.replace(/-/g, '_');
    const dispatch = useAppDispatch();
    const [marketAccessData, setMarketAccessData] = useState(null);
    const [inlinePathwayItem, setInlinePathwayItem] = useState<InlinePathwayItem>(null);

    useEffect(() => {
        if (props.type === 'market') {
            checkAndGetMarketAccessData();
        } else if (props.type === 'strong_market') {
            setStrongMarketAccessData();
        }
    }, [props.type]);

    useEffect(() => {
        if (marketAccessData) {
            const sector = props.match.params.sector;

            if (!marketAccessData.elements.link.value.includes(sector)) {
                marketAccessData.elements.link.value = `${marketAccessData.elements.link.value}/${PageName.ProductRequirements}/${sector}/${props.match.params.market}`;
            }

            setContent(marketAccessData);
            props.onLoad && props.onLoad(true, 'Market access');
        }
    }, [marketAccessData]);

    const setStrongMarketAccessData = () => {
        const item = getMarketAccessData();
        setMarketAccessData(item);
        dispatch(getLawsAndRegsDataSource({ sector }));
    };

    const checkAndGetMarketAccessData = () => {
        client
            .items()
            .equalsFilter('system.type', 'laws_and_regulations_non_agri')
            .anyFilter('elements.industry_sector', [sector])
            .containsFilter('elements.marketcodesnippet__market', [market.toLowerCase()])
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                if (response.data.items[0]) {
                    const marketAccessData = getMarketAccessData();

                    if (marketAccessData) {
                        marketAccessData.elements.link.value = `${marketAccessData.elements.link.value}/${PageName.ServiceRequirements}/${props.match.params.industry}/${sector}/${market}`;
                        setContent(marketAccessData);
                        props.onLoad && props.onLoad(true, 'Market access');
                    }
                } else {
                    getMarketAccessTutorialData();
                }
            });
    };

    const getMarketAccessData = (): any => {
        const pathwayItems = props.data.item.elements['market_access_pathway_items'];
        return pathwayItems?.linkedItems?.length > 0 ? pathwayItems.linkedItems[0] : null;
    };

    const getMarketAccessTutorialData = () => {
        const pathwayItems = props.data.item.elements['market_access_pathway_items'];
        if (pathwayItems?.linkedItems?.length > 1) {
            setContent(pathwayItems.linkedItems[1]);
            props.onLoad && props.onLoad(true, 'Market access');
        }
    };

    useEffect(() => {
        if (content && urlCodes) {
            const filteredItem = filterContentItemAgainstUrlParams(content, urlCodes);
            setInlinePathwayItem(filteredItem);
        }
    }, [content, urlCodes]);

    return (
        <>
            {inlinePathwayItem && (
                <div className="pb-4">
                    <InlinePathway data={{ content: [inlinePathwayItem] }} client={client} />
                </div>
            )}
        </>
    );
};

export default MarketAccess;
