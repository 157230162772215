import React from 'react';
import './RadioButtonGroup.scss';
import { ComRadioButtonGroup, ComRadioButtonGroupLayout } from '@exporter-services/common-ui';

export interface RadioButtonGroupItem {
    Id: string;
    Title: string;
    Value: string;
}

interface RadioButtonGroupProps {
    items: RadioButtonGroupItem[];
    label: string;
    name: string;
    selectedValue?: string;
    focusedValue?: string;
    onChangeHandler: (evt: React.MouseEvent<HTMLInputElement>, selectedItem: RadioButtonGroupItem) => void;
    legendAsLabel: boolean;
}

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
    const label = props.label;

    const itemOnChange = (evt: React.MouseEvent<HTMLInputElement>, selectedValue: string) => {
        const item = props.items.find((itm) => itm.Value === selectedValue);
        props.onChangeHandler && props.onChangeHandler(evt, item);
    };

    return (
        <fieldset className="filtergroup-form-field-container filtergroup-form-field-container-radiobuttongroup">
            <legend className={`field-label ${!props.legendAsLabel && 'sr-only'}`}>{label}</legend>
            <ComRadioButtonGroup
                items={props.items}
                selectedValue={props.selectedValue}
                focusedValue={props.focusedValue}
                name={props.name}
                setLabelCssClass={() => 'form-field form-radio-button'}
                setLayout={() => (props.items.length > 2 ? ComRadioButtonGroupLayout.Stacked : ComRadioButtonGroupLayout.NotStacked)}
                handleOnChange={itemOnChange}
            />
        </fieldset>
    );
};

export default RadioButtonGroup;
