import { ContentItemElementsIndexer } from '@kontent-ai/delivery-sdk';

export function getPageHeaderBackgroundStyling(backgroundColorTheme: ContentItemElementsIndexer) {
    let className = '';
    let tempTheme = 'blue';

    if (backgroundColorTheme?.value?.length > 0) {
        tempTheme = backgroundColorTheme.value[0].codename;
    }

    switch (tempTheme) {
        case 'blue':
        case 'teal':
        case 'yellow':
            className = `page-header-${tempTheme}-theme`;
            break;
        default:
            className = 'page-header-blue-theme';
            break;
    }

    return className;
}
