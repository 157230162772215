import React from 'react';
import { Col, Container } from 'reactstrap';
import './HomePageHeader.scss';
import EbgaHead from '../EbgaHead';

interface HomePageHeaderProps {
    data: {
        title: any;
        title_tag: string;
        description: any;
        metaDescription: string;
        image: any;
        label?: any;
    };
}

const HomePageHeader = (props: HomePageHeaderProps) => {
    const showAriaInfo = () => !!props.data.label;

    const getPageTitle = (market: string, sector: string) => {
        let pageTitle = props.data.title.replace(/{{market}}/g, market);
        return pageTitle.replace(/{{sector}}/g, sector);
    };

    const getMetaDescription = () => {
        return props.data.description.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const getStandardH1 = (showAria: boolean, testId: string, cssClass?: string) => (
        <h1 id="pageTitle" className={`${!!cssClass ? `${cssClass}` : ''}`} aria-hidden={!showAria} data-testid={testId}>
            {getPageTitle('', '')}
        </h1>
    );

    return (
        <>
            <EbgaHead
                metaDescription={getMetaDescription()}
                abstract={props.data.description}
                pageTitle={getPageTitle('', '')}
                titleTag={props.data.title_tag}
                market={''}
                sectorLabel={''}
                sector={''}
            />
            <div className="homepage-header-wrapper homepage-header-wrapper-top">
                <Container fluid className="homepage-header" id="page-header">
                    <Container>
                        <div className="content-header">
                            <Col md={6} sm={12} className="order-2 order-md-1">
                                <div className="text-container">
                                    {showAriaInfo() && (
                                        <>
                                            {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                            <p className="header-label">{props.data.label}</p>
                                        </>
                                    )}
                                    {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                    <div className="header-intro" dangerouslySetInnerHTML={{ __html: props.data.description }} />
                                </div>
                            </Col>
                            {props.data.image.value.length > 0 && (
                                <Col className="page-header-backgroundIcon d-md-block order-1 order-md-2" md={6} sm={12}>
                                    {props.data.image.value && <img src={props.data.image.value[0].url} alt="" />}
                                </Col>
                            )}
                        </div>
                    </Container>
                </Container>
            </div>
        </>
    );
};
export default HomePageHeader;
