import React from 'react';
import './ImageTilePathway.scss';
import { ImageTilePathwayItem } from './ImageTilePathwayItem';
import ComImage from '../../base/ComImage';
import ComPathwayAnchor from '../../base/ComPathwayAnchor';
import { DeliveryClient } from '@kontent-ai/delivery-sdk';
import { Col, Row } from 'reactstrap';
import { PageLayoutType } from '../../../models/enums';

export interface ImageTilePathwayProps {
    data: {
        items: ImageTilePathwayItem[];
    };
    client: DeliveryClient;
    pageLayoutType?: PageLayoutType;
}

const ImageTilePathway = (props: ImageTilePathwayProps) => {
    const lgColumnWidth = props.pageLayoutType === PageLayoutType.TwoColumnPage ? 6 : 4;
    return (
        <Row data-testid="image-tile-pathway" className="image-tile-pathway-list full-width">
            {props.data.items?.map((item: ImageTilePathwayItem) => (
                <Col lg={lgColumnWidth} sm={6} className="image-tile-pathway-list-block" key={item.systemId}>
                    <div className="image-tile-pathway-anchor-wrapper">
                        <ComPathwayAnchor
                            data={{ link: item.text_link, pathway_link: item.pathway_link }}
                            client={props.client}
                            className={'image-tile-pathway-anchor'}
                        >
                            <div className="image-tile-pathway-list-button image-tile-pathway-list-wrapper">
                                <div className="image-tile-pathway-list-heading">
                                    <h3>{item.title}</h3>
                                </div>
                                <div className="image-tile-pathway-list-body">
                                    <div className="image-tile-pathway-list-body-content">
                                        <ComImage src={item.imageUrl} alt={item.imageAltText} />
                                    </div>
                                </div>
                            </div>
                        </ComPathwayAnchor>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default ImageTilePathway;
