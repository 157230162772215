import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, PaginationLinks } from '@exporter-services/common-ui';
import '../../styles/_filterPages.scss';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import FilterEventsPanel, { EventsFilterCriteria } from './FilterEventsPanel';
import _ from 'lodash';
import { NestedFilterItem } from '@exporter-services/common-ui';
import { stringFilterDictionary } from '../news/newsFilterUtils';
import { client } from '../../config';
import { EventPathwayItem } from '../../controls/eventPathway/EventPathwayItem';
import EventPathway from '../../controls/eventPathway/EventPathway';
import { filterUtils, marketDictionary, uniqueContentIdDictionary } from '../../utils/filterUtils';
import { getKenticoRegionMarketMapping, getKenticoSectorTaxonomyGroup } from '../../providers/reducers/taxonomySlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { KenticoRegionMarketMapping, KenticoSectorTaxonomyGroup } from '../../models/ReduxModels';
import { CommonConstants } from '../../CommonConstants';
import { scrollTo } from '../../utils/scrollUtils';

const FilteredEventsResultsContainer = () => {
    const [eventsList, setEventsList] = useState<EventPathwayItem[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<EventPathwayItem[]>(null);
    const [currentPageEvents, setCurrentPageEvents] = useState<EventPathwayItem[]>(null);
    const [eventListRetrieved, setEventListRetrieved] = useState<boolean>(false);

    const pageSize = CommonConstants.PAGE_SIZE;

    const [regionMarketPanelList, setRegionMarketPanelList] = useState<NestedFilterItem[]>(null);
    const fullMarketRegionDictionary = useRef<marketDictionary>({});
    const fullRegionMarketList = useRef<uniqueContentIdDictionary>({});

    const [industrySectorPanelList, setIndustrySectorPanelList] = useState<NestedFilterItem[]>(null);
    const fullSectorFlatList = useRef<uniqueContentIdDictionary>({});
    const [filteredCount, setFilteredCount] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const sectorTaxonomyGroup = useAppSelector<KenticoSectorTaxonomyGroup>((state) => state.taxonomy.sectorTaxonomyGroup);
    const regionMarketMapping = useAppSelector<KenticoRegionMarketMapping>((state) => state.taxonomy.regionMarketMapping);

    //#region Get events list, market list, sector list

    const refResultCountElement = useRef(null);
    const resultsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getKenticoSectorTaxonomyGroup());
        dispatch(getKenticoRegionMarketMapping());
        getEventsList();
    }, []);

    useEffect(() => {
        if (eventListRetrieved && sectorTaxonomyGroup.retrieved) {
            let tempIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(eventsList);
            fullSectorFlatList.current = tempIndustrySectorFlatList;
            let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(tempIndustrySectorFlatList, sectorTaxonomyGroup.data);
            let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);
            setIndustrySectorPanelList(tempFilterList);
        }
    }, [sectorTaxonomyGroup.retrieved, eventListRetrieved]);

    useEffect(() => {
        if (regionMarketMapping.retrieved && eventListRetrieved) {
            let tempMarketRegionDictionary = filterUtils.getMarketRegionDictionary(regionMarketMapping.data);
            fullMarketRegionDictionary.current = tempMarketRegionDictionary;
            let tempMarketContentItemsDictionary = filterUtils.getMarketContentItemsDictionary(eventsList);
            fullRegionMarketList.current = tempMarketContentItemsDictionary;
            let tempRegionMarketDictionary = filterUtils.getRegionMarketDictionary(
                tempMarketContentItemsDictionary,
                tempMarketRegionDictionary,
                regionMarketMapping.data,
            );

            let regionMarketNestedItem = filterUtils.getRegionMarketNestedPanelList(tempRegionMarketDictionary);
            setRegionMarketPanelList(regionMarketNestedItem);
        }
    }, [regionMarketMapping.retrieved, eventListRetrieved]);

    //#endregion

    //#region getEvents for the page

    const getEventsList = () => {
        client
            .items()
            .equalsFilter('system.type', 'event_pathway')
            .notEqualsFilter('system.workflow_step', 'archived')
            .depthParameter(0)
            .toPromise()
            .then((response) => {
                if (response?.data?.items?.length > 0) {
                    let tempEventsList = response.data.items as EventPathwayItem[];

                    let listWithDate: EventPathwayItem[] = tempEventsList.filter((a: EventPathwayItem) => a.elements.event_start_date?.value);
                    let listWithNoDate: EventPathwayItem[] = tempEventsList.filter((a: EventPathwayItem) => !a.elements.event_start_date?.value);

                    let sortedListWithDate: EventPathwayItem[] = listWithDate.sort((a: EventPathwayItem, b: EventPathwayItem) => {
                        let startDateA = a.elements.event_start_date.value;
                        let startDateB = b.elements.event_start_date.value;

                        return startDateA > startDateB ? 1 : startDateA < startDateB ? -1 : 0;
                    });

                    let resultSortedList = sortedListWithDate.concat(listWithNoDate);

                    setEventsList([...resultSortedList]);
                    setFilteredEvents([...resultSortedList]);

                    //set first page results
                    let pagedItems = _.drop(resultSortedList, 0).slice(0, pageSize);
                    setCurrentPageEvents(pagedItems);
                    setCurrentPage(1);

                    let tempTotalCount = response.data?.items ? resultSortedList.length : 0;
                    setPaginationParameters(tempTotalCount);
                    setTotalPages(Math.ceil(tempTotalCount / pageSize));
                }

                setEventListRetrieved(true);
                setLoading(false);
            });
    };

    const setPaginationParameters = (resultsCount: number) => {
        setFilteredCount(resultsCount);
        setTotalPages(Math.ceil(resultsCount / pageSize));
    };
    const getEvents = (pageNumber: number) => {
        if (!filteredEvents) return;

        let offset = (pageNumber - 1) * pageSize;
        let pagedItems = _.drop(filteredEvents, offset).slice(0, pageSize);
        setCurrentPageEvents(pagedItems);
        setCurrentPage(pageNumber);
    };

    const scrollToPageTop = () => {
        scrollTo(refResultCountElement);
        if (resultsContainerRef?.current) resultsContainerRef.current.focus();
    };

    const onFirstPageClick = () => {
        getEvents(1);
        scrollToPageTop();
    };

    const onPreviousPageClick = () => {
        if (currentPage > 1) {
            getEvents(currentPage - 1);
        }
        scrollToPageTop();
    };

    const onNextPageClick = () => {
        if (currentPage < totalPages) {
            getEvents(currentPage + 1);
        }
        scrollToPageTop();
    };

    const onLastPageClick = () => {
        getEvents(totalPages);
        scrollToPageTop();
    };

    function onPageNumberClick(pageNumber: number) {
        getEvents(pageNumber);
        scrollToPageTop();
    }

    //#endregion

    //#region Apply Filters
    const onFilterChange = (filterCriteria: EventsFilterCriteria) => {
        let tempFilteredList: EventPathwayItem[] = [...eventsList];

        tempFilteredList = filterUtils.applySectorFilterToContentItemsList(filterCriteria.industrySectorCriteriaList, tempFilteredList);
        tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(filterCriteria.regionMarketCriteriaList, tempFilteredList);

        setFilteredEvents([...tempFilteredList]);
        setFilteredCount(tempFilteredList.length);

        //Update left side FilterPanels

        updateIndustrySectorFilterPanel(filterCriteria);
        updateRegionMarketFilterPanel(filterCriteria);

        //set results to 1st page of pagination
        let pagedItems = _.drop(tempFilteredList, 0).slice(0, pageSize);
        setCurrentPageEvents(pagedItems);
        setCurrentPage(1);
        setPaginationParameters(tempFilteredList.length);
    };

    const updateIndustrySectorFilterPanel = (filterCriteria: EventsFilterCriteria) => {
        let tempFilteredList = [...eventsList] as ContentItem[];

        if (checkFilterSelectionCount(filterCriteria.regionMarketCriteriaList) > 0) {
            tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(filterCriteria.regionMarketCriteriaList, [...tempFilteredList]);
        }

        updateIndustrySectorFilterPanelListCount([...tempFilteredList]);
    };

    const updateRegionMarketFilterPanel = (filterCriteria: EventsFilterCriteria) => {
        let tempFilteredList = [...eventsList] as ContentItem[];

        if (checkFilterSelectionCount(filterCriteria.industrySectorCriteriaList) > 0) {
            tempFilteredList = filterUtils.applySectorFilterToContentItemsList(filterCriteria.industrySectorCriteriaList, [...tempFilteredList]);
        }

        updateRegionMarketFilterPanelListCount([...tempFilteredList]);
    };

    const checkFilterSelectionCount = (criteria: stringFilterDictionary) => {
        let count = 0;
        if (!criteria || Object.keys(criteria).length === 0) return 0;
        Object.keys(criteria).forEach((item: string) => {
            if (criteria[item]) {
                count++;
            }
        });
        return count;
    };

    const updateIndustrySectorFilterPanelListCount = (filteredEventList: EventPathwayItem[]) => {
        let filteredIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(filteredEventList);
        let updatedIndustrySectorFlatList = filterUtils.updateIndustrySectorFlatList(filteredIndustrySectorFlatList, fullSectorFlatList.current);
        let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(updatedIndustrySectorFlatList, sectorTaxonomyGroup.data);
        let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);

        setIndustrySectorPanelList(tempFilterList);
    };

    const updateRegionMarketFilterPanelListCount = (filteredEventList: EventPathwayItem[]) => {
        let filteredRegionMarketFlatList = filterUtils.getMarketContentItemsDictionary(filteredEventList);
        let updatedRegionMarketFlatList = filterUtils.updateRegionMarketFlatList(filteredRegionMarketFlatList, fullRegionMarketList.current);

        let tempRegionMarketDictionaryList = filterUtils.getRegionMarketDictionary(
            updatedRegionMarketFlatList,
            fullMarketRegionDictionary.current,
            regionMarketMapping.data,
        );
        let tempFilterList = filterUtils.getRegionMarketNestedPanelList(tempRegionMarketDictionaryList);

        setRegionMarketPanelList(tempFilterList);
    };

    //#endregion

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="" className="loader">
            <Row>
                <Col>
                    {filteredCount > 0 ? (
                        <h2 className="results-count-header" ref={refResultCountElement}>
                            Showing <strong>{filteredCount}</strong> {filteredCount === 1 ? 'event' : 'events'}
                        </h2>
                    ) : (
                        <h2 className="results-count-header">No events to show</h2>
                    )}
                </Col>
            </Row>
            <Row className="filtered-results-container">
                <Col md="4">
                    {(regionMarketPanelList?.length > 0 || industrySectorPanelList?.length > 0) && (
                        <FilterEventsPanel
                            data={{
                                industrySectorList: industrySectorPanelList,
                                regionMarketList: regionMarketPanelList,
                            }}
                            onChange={onFilterChange}
                        />
                    )}
                </Col>
                <Col md="8">
                    <div ref={resultsContainerRef} tabIndex={-1}>
                        {currentPageEvents && <EventPathway data={{ items: currentPageEvents }}></EventPathway>}
                    </div>
                    <div className="pagination-links-container" data-testid={'pagination'}>
                        {totalPages > 0 && (
                            <PaginationLinks
                                data={{
                                    totalPages: totalPages,
                                    currentPage: currentPage,
                                    pageSize: pageSize,
                                    onPageNumberClick: onPageNumberClick,
                                    onFirstPageClick: onFirstPageClick,
                                    onLastPageClick: onLastPageClick,
                                    onNextPageClick: onNextPageClick,
                                    onPreviousPageClick: onPreviousPageClick,
                                }}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </LoadingOverlay>
    );
};

export default FilteredEventsResultsContainer;
