import React, { useState, useEffect, useRef } from 'react';
import './CommoditiesSearch.scss';
import { MarketOption } from '../../models/MarketOption';
import MarketSearchBase from './MarketSearchBase';
import { useGetImportingDocumentMarketsForHsCodeQuery } from '../../providers/reducers/marketsApi';
import { handleMarketSearchResponse } from '../../utils/marketSearchUtils';

export interface MarketsByHsCodeSearchProps {
    onClick?: (selectedUrl: MarketOption) => void;
    hsCode: string;
    ariaLabel: string;
    onFocus?: () => void;
}

const MarketsByHsCodeSearch = (props: MarketsByHsCodeSearchProps) => {
    const [selectedValue, setSelectedValue] = useState<MarketOption>();
    const [marketOptions, setMarketOptions] = useState<MarketOption[]>();

    const marketsByCommodityCodeSearchInputRef = useRef(null);

    const marketsForHsCodeQuery = useGetImportingDocumentMarketsForHsCodeQuery(props.hsCode);

    const setMarketSearchInputRef = (ref: any) => {
        marketsByCommodityCodeSearchInputRef.current = ref;
    };

    useEffect(() => {
        if (marketsForHsCodeQuery.isLoading || !marketsForHsCodeQuery.currentData) {
            return;
        }
        let options = handleMarketSearchResponse(marketsForHsCodeQuery.currentData);
        setSelectedValue(null);
        props.onClick && props.onClick(null);
        setMarketOptions(options);
    }, [marketsForHsCodeQuery.isLoading, marketsForHsCodeQuery.currentData]);

    useEffect(() => {
        marketsByCommodityCodeSearchInputRef.current?.focus();
    }, [selectedValue]);

    const handleChange = (selectedOption: MarketOption) => {
        setSelectedValue(selectedOption);
        props.onClick && selectedOption && props.onClick(selectedOption);
    };

    return (
        <MarketSearchBase
            id={'productsMarketSearch'}
            marketOptions={marketOptions}
            setMarketSearchInputRef={setMarketSearchInputRef}
            handleOnChange={handleChange}
            selectedValue={selectedValue}
            class={'commoditiessearch-ahecc-container'}
            ariaLabel={props.ariaLabel}
            onFocus={props.onFocus}
        />
    );
};
export default MarketsByHsCodeSearch;
