import { KenticoIndustrySectorCollection, KenticoRegion } from '../models/ReduxModels';
import { ContentItem, FilterItem, NestedFilterItem } from '@exporter-services/common-ui';

export type stringFilterDictionary = { [id: string]: boolean };

type sectorDictionary = {
    //Nested subsectors converted to flat list
    [sectorCode: string]: { count: number; contentIds: stringFilterDictionary };
};

type industrySectorDictionary = {
    //Nested subsectors converted to flat list
    [industryCode: string]: { count: number; sectorDictionary: sectorDictionary };
};

export type marketDictionary = {
    [marketCode: string]: { regions: string[] };
};

export type regionMarketDictionary = {
    [regionCode: string]: { description?: string; count?: number; markets: descCountDictionary; uniqueContentIds: stringFilterDictionary };
};

export type descCountDictionary = { [id: string]: { description: string; count: number } };
export type uniqueContentIdDictionary = { [id: string]: { description: string; contentIds: stringFilterDictionary } };

export enum FilterContentKey {
    SystemCode = 0,
    MarketCode = 1,
}

export class filterUtils {
    //#region Region methods
    static getMarketRegionDictionary = (regionMarketMapping: KenticoRegion[]) => {
        let tempMarketDictionary: marketDictionary = {};
        if (regionMarketMapping?.length > 0) {
            regionMarketMapping.forEach((region) => {
                if (region.markets?.length > 0) {
                    region.markets.forEach((market) => {
                        if (!tempMarketDictionary[market.code]) {
                            tempMarketDictionary[market.code] = { regions: [region.code] };
                        } else {
                            if (!tempMarketDictionary[market.code].regions.includes(region.code)) {
                                tempMarketDictionary[market.code].regions.push(region.code);
                            }
                        }
                    });
                }
            });
        }
        return tempMarketDictionary;
    };

    static getMarketContentItemsDictionary = (contentItemList: ContentItem[]): uniqueContentIdDictionary => {
        let tempUniqueContentIdDictionary: uniqueContentIdDictionary = {};

        contentItemList.forEach((r) => {
            let marketElement = r.elements.marketcodesnippet__market;

            if (marketElement?.value?.length > 0) {
                const contentItemMarkets = marketElement.value;

                contentItemMarkets.forEach((market) => {
                    if (!tempUniqueContentIdDictionary[market.codename]) {
                        let position = market.name.indexOf('|') === -1 ? market.name.length : market.name.indexOf('|');
                        tempUniqueContentIdDictionary[market.codename] = {
                            description: market.name.substring(0, position).trim(),
                            contentIds: { [r.system.codename]: true },
                        };
                    } else {
                        tempUniqueContentIdDictionary[market.codename].contentIds[r.system.codename] = true;
                    }
                });
            }
        });

        return tempUniqueContentIdDictionary;
    };

    static getRegionMarketDictionary = (
        contentItemsMarketList: uniqueContentIdDictionary,
        marketRegionDictionary: marketDictionary,
        regionMarketMapping: KenticoRegion[],
    ) => {
        let tempRegionMarketDictionary: regionMarketDictionary = {};

        if (marketRegionDictionary) {
            for (let marketCode in contentItemsMarketList) {
                if (marketRegionDictionary[marketCode]?.regions?.length > 0) {
                    marketRegionDictionary[marketCode].regions.forEach((regionCode) => {
                        let marketRegionCode = marketCode + ':' + regionCode;
                        let regionDesc = regionMarketMapping?.find((r) => r.code === regionCode)?.description;
                        if (!tempRegionMarketDictionary[regionCode]) {
                            tempRegionMarketDictionary[regionCode] = {
                                description: regionDesc,
                                markets: {
                                    [marketRegionCode]: {
                                        description: contentItemsMarketList[marketCode].description,
                                        count: Object.keys(contentItemsMarketList[marketCode].contentIds).length,
                                    },
                                },
                                uniqueContentIds: contentItemsMarketList[marketCode].contentIds,
                            };
                        } else {
                            let uniqueContentIds = tempRegionMarketDictionary[regionCode].uniqueContentIds;
                            for (let contentIdKey in contentItemsMarketList[marketCode].contentIds) {
                                if (!uniqueContentIds[contentIdKey]) {
                                    uniqueContentIds[contentIdKey] = true;
                                }
                            }
                            tempRegionMarketDictionary[regionCode].markets[marketRegionCode] = {
                                description: contentItemsMarketList[marketCode].description,
                                count: Object.keys(contentItemsMarketList[marketCode].contentIds).length,
                            };
                            tempRegionMarketDictionary[regionCode].uniqueContentIds = uniqueContentIds;
                            tempRegionMarketDictionary[regionCode].description = regionDesc;
                        }
                    });
                }
            }
        }

        return tempRegionMarketDictionary;
    };

    static getRegionMarketNestedPanelList = (regionMarketDictionary: regionMarketDictionary) => {
        let tempRegionMarketMappedList: NestedFilterItem[] = [];
        if (Object.keys(regionMarketDictionary)?.length > 0) {
            for (let regionKey in regionMarketDictionary) {
                let regionTotal = 0;
                let tempMarketList: FilterItem[] = [];

                if (Object.keys(regionMarketDictionary[regionKey].markets)?.length > 0) {
                    for (let marketKey in regionMarketDictionary[regionKey].markets) {
                        tempMarketList.push({
                            value: marketKey,
                            label: regionMarketDictionary[regionKey].markets[marketKey].description,
                            count: regionMarketDictionary[regionKey].markets[marketKey].count,
                        });
                    }
                }

                regionTotal = Object.keys(regionMarketDictionary[regionKey].uniqueContentIds).length;

                tempMarketList.sort((a: FilterItem, b: FilterItem) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
                tempRegionMarketMappedList.push({
                    value: regionKey,
                    label: regionMarketDictionary[regionKey].description,
                    count: regionTotal,
                    subFilterItems: tempMarketList,
                });
            }

            tempRegionMarketMappedList.sort((a: NestedFilterItem, b: NestedFilterItem) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        }
        return tempRegionMarketMappedList;
    };

    static updateRegionMarketFlatList = (
        filteredContentItemsRegionMarketFlatList: uniqueContentIdDictionary,
        contentItemsRegionMarketFlatList: uniqueContentIdDictionary,
    ): uniqueContentIdDictionary => {
        if (Object.keys(contentItemsRegionMarketFlatList)?.length > 0) {
            for (let marketKey in contentItemsRegionMarketFlatList) {
                if (filteredContentItemsRegionMarketFlatList[marketKey]) {
                    contentItemsRegionMarketFlatList[marketKey].contentIds = filteredContentItemsRegionMarketFlatList[marketKey].contentIds;
                } else {
                    contentItemsRegionMarketFlatList[marketKey].contentIds = {};
                }
            }
        }
        return { ...contentItemsRegionMarketFlatList };
    };
    //#endregion

    //#region SectorList contentItems
    static getIndustrySectorFlatListFromContentItems = (
        contentItemList: ContentItem[],
        filterContentKey = FilterContentKey.SystemCode,
    ): uniqueContentIdDictionary => {
        let tempUniqueContentIdDictionary: uniqueContentIdDictionary = {};

        contentItemList.forEach((r) => {
            const marketElement = r.elements.marketcodesnippet__market;
            let industrySectorElement = r.elements.industrysectorcodesnippet__sector
                ? r.elements.industrysectorcodesnippet__sector
                : r.elements.industry_sector;

            if (industrySectorElement.value?.length > 0) {
                const contentItemSectors = industrySectorElement.value;
                const contentKey = filterContentKey === FilterContentKey.MarketCode ? marketElement.value[0].codename : r.system.codename;

                contentItemSectors.forEach((sector) => {
                    if (!tempUniqueContentIdDictionary[sector.codename]) {
                        tempUniqueContentIdDictionary[sector.codename] = {
                            description: sector.name,
                            contentIds: { [contentKey]: true },
                        };
                    } else {
                        tempUniqueContentIdDictionary[sector.codename].contentIds[contentKey] = true;
                    }
                });
            }
        });

        return tempUniqueContentIdDictionary;
    };

    static getIndustrySectorDictionaryList = (
        contentItemsIndustrySectorList: uniqueContentIdDictionary,
        kenticoCollection: KenticoIndustrySectorCollection,
    ): industrySectorDictionary => {
        let tempIndustrySectorDictionary: industrySectorDictionary = {};

        //build industry sector list from Kentico Taxonomy
        if (contentItemsIndustrySectorList && kenticoCollection?.industryCollection?.length > 0) {
            for (let key in contentItemsIndustrySectorList) {
                if (kenticoCollection.industryDictionary[key]) {
                    if (!tempIndustrySectorDictionary[key])
                        tempIndustrySectorDictionary[key] = {
                            count: Object.keys(contentItemsIndustrySectorList[key].contentIds).length,
                            sectorDictionary: {},
                        };
                } else if (kenticoCollection.sectorCollectionFlat[key]) {
                    if (!tempIndustrySectorDictionary[kenticoCollection.sectorCollectionFlat[key].industryCode]) {
                        tempIndustrySectorDictionary[kenticoCollection.sectorCollectionFlat[key].industryCode] = {
                            count: 0,
                            sectorDictionary: {},
                        };
                        tempIndustrySectorDictionary[kenticoCollection.sectorCollectionFlat[key].industryCode].sectorDictionary[key] = {
                            count: Object.keys(contentItemsIndustrySectorList[key].contentIds).length,
                            contentIds: contentItemsIndustrySectorList[key].contentIds,
                        };
                    } else {
                        tempIndustrySectorDictionary[kenticoCollection.sectorCollectionFlat[key].industryCode].sectorDictionary[key] = {
                            count: Object.keys(contentItemsIndustrySectorList[key].contentIds).length,
                            contentIds: contentItemsIndustrySectorList[key].contentIds,
                        };
                    }
                }
            }
        }

        return tempIndustrySectorDictionary;
    };

    static getIndustrySectorFilterPanelList = (
        contentItemsIndustrySectorDictionary: industrySectorDictionary,
        kenticoCollection: KenticoIndustrySectorCollection,
    ): NestedFilterItem[] => {
        let tempIndustrySectorMappedList: NestedFilterItem[] = [];
        if (Object.keys(contentItemsIndustrySectorDictionary)?.length > 0) {
            for (let key in contentItemsIndustrySectorDictionary) {
                let industryTotal = 0;
                let uniqueContentIds: stringFilterDictionary = {};
                let tempSubsectorMappedList: FilterItem[] = [];

                if (Object.keys(contentItemsIndustrySectorDictionary[key].sectorDictionary)?.length > 0) {
                    for (let sectorKey in contentItemsIndustrySectorDictionary[key].sectorDictionary) {
                        tempSubsectorMappedList.push({
                            value: sectorKey,
                            label: kenticoCollection.sectorCollectionFlat[sectorKey].description,
                            count: contentItemsIndustrySectorDictionary[key].sectorDictionary[sectorKey].count,
                        });

                        for (let contentIdKey in contentItemsIndustrySectorDictionary[key].sectorDictionary[sectorKey].contentIds) {
                            if (!uniqueContentIds[contentIdKey]) {
                                uniqueContentIds[contentIdKey] = true;
                            }
                        }
                    }
                }

                industryTotal = Object.keys(uniqueContentIds).length;

                tempSubsectorMappedList.sort((a: FilterItem, b: FilterItem) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
                tempIndustrySectorMappedList.push({
                    value: key,
                    label: kenticoCollection.industryDictionary[key].description,
                    count: tempSubsectorMappedList.length > 0 ? industryTotal : contentItemsIndustrySectorDictionary[key].count,
                    subFilterItems: tempSubsectorMappedList,
                });
            }

            tempIndustrySectorMappedList.sort((a: NestedFilterItem, b: NestedFilterItem) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        }
        return tempIndustrySectorMappedList;
    };

    static updateIndustrySectorFlatList = (
        filteredContentItemsIndustrySectorFlatList: uniqueContentIdDictionary,
        contentItemsIndustrySectorFlatList: uniqueContentIdDictionary,
    ): uniqueContentIdDictionary => {
        if (Object.keys(contentItemsIndustrySectorFlatList)?.length > 0) {
            for (let key in contentItemsIndustrySectorFlatList) {
                if (filteredContentItemsIndustrySectorFlatList[key]) {
                    contentItemsIndustrySectorFlatList[key].contentIds = filteredContentItemsIndustrySectorFlatList[key].contentIds;
                } else {
                    contentItemsIndustrySectorFlatList[key].contentIds = {};
                }
            }
        }
        return { ...contentItemsIndustrySectorFlatList };
    };
    //#endregion

    //#region Filters to contentItem list

    static applySectorFilterToContentItemsList = (sectorCriteria: stringFilterDictionary, paramContentItemsList: ContentItem[]) => {
        if (!sectorCriteria) return paramContentItemsList; // return passed list
        let checkedSectors: stringFilterDictionary = {};
        Object.keys(sectorCriteria).forEach((item: string) => {
            if (sectorCriteria[item]) {
                checkedSectors[item] = true;
            }
        });

        //if no sectors selected in the panel
        if (!checkedSectors || Object.keys(checkedSectors).length === 0) return [...paramContentItemsList]; // return passed list

        let tempFilteredList = paramContentItemsList.filter((r: ContentItem) => {
            let industrySectorElement = r.elements.industrysectorcodesnippet__sector
                ? r.elements.industrysectorcodesnippet__sector
                : r.elements.industry_sector;

            let result = industrySectorElement?.value?.findIndex((sectorItem: any) => {
                let findSectorTagIndex = Object.keys(checkedSectors).findIndex((r: string) => {
                    let results = r.split(':');
                    if (results.length > 1 && results[1].length > 0) {
                        return results[1] === sectorItem.codename;
                    } else {
                        return results[0] === sectorItem.codename;
                    }
                });
                return findSectorTagIndex > -1;
            });
            return result > -1;
        });
        return tempFilteredList;
    };

    static applyRegionMarketFilterToContentItemsList = (regionMarketCriteria: stringFilterDictionary, paramContentItemsList: ContentItem[]) => {
        if (!regionMarketCriteria) return paramContentItemsList; // return passed list
        let checkedMarkets: stringFilterDictionary = {};
        Object.keys(regionMarketCriteria).forEach((item: string) => {
            if (regionMarketCriteria[item]) {
                checkedMarkets[item] = true;
            }
        });

        //if no sectors selected in the panel
        if (!checkedMarkets || Object.keys(checkedMarkets).length === 0) return [...paramContentItemsList]; // return passed list

        let tempFilteredList = paramContentItemsList.filter((r: ContentItem) => {
            let regionMarketElement = r.elements.marketcodesnippet__market;

            let result = regionMarketElement?.value?.findIndex((marketItem: any) => {
                let findMarketTagIndex = Object.keys(checkedMarkets).findIndex((r: string) => {
                    let results = r.split(':');
                    if (results.length > 1 && results[1].length > 0) {
                        return results[1] === marketItem.codename;
                    } else {
                        return results[0] === marketItem.codename;
                    }
                });
                return findMarketTagIndex > -1;
            });
            return result > -1;
        });
        return tempFilteredList;
    };
    //#endregion
}
