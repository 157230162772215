import { TreeNodeListItemHtmlModifiers, TreeRecord, TreeUtilitiesBase } from './TreeUtils';

export interface ExpandCollapseTreeRecord<T> extends TreeRecord<T> {
    IsExpanded: boolean;
    Children: ExpandCollapseTreeRecord<T>[];
}

export type ListItemStateHashList = Record<string, { isExpanded: boolean; isHidden: boolean }>;

export class ExpandCollapseTreeUtils extends TreeUtilitiesBase {
    private static ROOT_DEPTH = 1;
    public static generateInitialListItemState<T1>(hierarchy: ExpandCollapseTreeRecord<T1>[]): ListItemStateHashList {
        const root = hierarchy[0];
        let listItemState = {};
        listItemState[root.Id] = { isExpanded: true };
        return listItemState;
    }

    public static isListItemRoot = (Id: string) => Id.indexOf('.') === -1;

    private static IsListItemExpanded(listItemStateHashList: ListItemStateHashList, rootListItemId: string, listItemId: string): boolean {
        if (rootListItemId === listItemId) {
            return true;
        }

        return listItemStateHashList[listItemId] && listItemStateHashList[listItemId].isExpanded;
    }

    private static generateHtmlModifiersHashList<T1>(
        treeHierarchy: ExpandCollapseTreeRecord<T1>[],
        rootNode: ExpandCollapseTreeRecord<T1>,
        isRoot: boolean,
        depth: number,
        listItemStateHashList: ListItemStateHashList,
        itemHtmlModifiers: (
            treeRecord: ExpandCollapseTreeRecord<T1>,
            rootNode: ExpandCollapseTreeRecord<T1>,
            isRoot: boolean,
            depth: number,
            index: number,
            listItemStateHashList: ListItemStateHashList,
            isExpanded: boolean,
            isLeafLevel: boolean,
        ) => TreeNodeListItemHtmlModifiers,
    ): Record<string, TreeNodeListItemHtmlModifiers> {
        let htmlModifiersHash: Record<string, TreeNodeListItemHtmlModifiers> = {};
        treeHierarchy &&
            treeHierarchy.forEach((c, i) => {
                const isExpanded = ExpandCollapseTreeUtils.IsListItemExpanded(listItemStateHashList, rootNode.Id, c.Id);
                const isLeafLevel = !!!(c.Children && c.Children.length > 0);
                htmlModifiersHash[c.Id] = itemHtmlModifiers(c, rootNode, isRoot, depth, i, listItemStateHashList, isExpanded, isLeafLevel);

                if (!isLeafLevel) {
                    const descendant = ExpandCollapseTreeUtils.generateHtmlModifiersHashList(
                        c.Children,
                        rootNode,
                        false,
                        depth + 1,
                        listItemStateHashList,
                        itemHtmlModifiers,
                    );
                    htmlModifiersHash = { ...htmlModifiersHash, ...descendant };
                }
            });

        return htmlModifiersHash;
    }

    protected static generateTreeHtmlInternal<T1>(
        treeHierarchy: ExpandCollapseTreeRecord<T1>[],
        rootNode: ExpandCollapseTreeRecord<T1>,
        isRoot = false,
        depth: number,
        listItemStateHashList: ListItemStateHashList,
        htmlModifiersHash: Record<string, TreeNodeListItemHtmlModifiers>,
        nodeItemTitleModifer: (
            treeRecord: TreeRecord<T1>,
            rootNode: ExpandCollapseTreeRecord<T1>,
            isRoot: boolean,
            depth: number,
            index: number,
            listItemStateHashList: ListItemStateHashList,
            isExpanded: boolean,
            isLeafLevel: boolean,
        ) => string,
    ) {
        return super.baseGenerateTreeHtmlInternal(
            treeHierarchy,
            isRoot,
            depth,
            (treeRecord, isRoot, depth, index, isLeafLevel) => {
                const isExpanded = ExpandCollapseTreeUtils.IsListItemExpanded(listItemStateHashList, rootNode.Id, treeRecord.Id);
                return nodeItemTitleModifer(treeRecord, rootNode, isRoot, depth, index, listItemStateHashList, isExpanded, isLeafLevel);
            },
            htmlModifiersHash,
        );
    }

    public static generateTreeHtml<T1>(
        treeHierarchy: ExpandCollapseTreeRecord<T1>[],
        listItemStateHashList: ListItemStateHashList,
        rootNode: ExpandCollapseTreeRecord<T1>,
        searchQueryHash: string,
        itemHtmlModifiers: (
            treeRecord: ExpandCollapseTreeRecord<T1>,
            rootNode: ExpandCollapseTreeRecord<T1>,
            isRoot: boolean,
            depth: number,
            index: number,
            listItemStateHashList: ListItemStateHashList,
            isExpanded: boolean,
            isLeafLevel: boolean,
            searchQueryHash?: string,
        ) => TreeNodeListItemHtmlModifiers,
        nodeItemTitleModifer: (
            treeRecord: ExpandCollapseTreeRecord<T1>,
            rootNode: ExpandCollapseTreeRecord<T1>,
            isRoot: boolean,
            depth: number,
            index: number,
            listItemStateHashList: ListItemStateHashList,
            isExpanded: boolean,
            isLeafLevel: boolean,
        ) => string,
    ) {
        if (!treeHierarchy) return null;

        let depth = 1;

        let isRoot = true;

        let htmlModifiersHash: Record<string, TreeNodeListItemHtmlModifiers> = {};
        treeHierarchy &&
            treeHierarchy.forEach((c, i) => {
                const isExpanded = ExpandCollapseTreeUtils.IsListItemExpanded(listItemStateHashList, rootNode.Id, c.Id);
                const isLeafLevel = !!!(c.Children && c.Children.length > 0);
                htmlModifiersHash[c.Id] = itemHtmlModifiers(
                    c,
                    rootNode,
                    isRoot,
                    depth,
                    i,
                    listItemStateHashList,
                    isExpanded,
                    isLeafLevel,
                    searchQueryHash,
                );
                const descendant = ExpandCollapseTreeUtils.generateHtmlModifiersHashList<T1>(
                    c.Children,
                    rootNode,
                    false,
                    depth + 1,
                    listItemStateHashList,
                    itemHtmlModifiers,
                );
                htmlModifiersHash = { ...htmlModifiersHash, ...descendant };
            });

        return ExpandCollapseTreeUtils.generateTreeHtmlInternal(
            treeHierarchy,
            rootNode,
            isRoot,
            depth,
            listItemStateHashList,
            htmlModifiersHash,
            (treeRecord, rootNode, isRoot, depth, index, listItemStateHashList, isExpanded, isLeafLevel) =>
                nodeItemTitleModifer(
                    treeRecord as ExpandCollapseTreeRecord<T1>,
                    rootNode,
                    isRoot,
                    depth,
                    index,
                    listItemStateHashList,
                    isExpanded,
                    isLeafLevel,
                ),
        );
    }

    public static getParentListItemId = (id: string) => {
        if (id && id.indexOf('.') > -1) {
            const splits = id.split('.');
            return splits.slice(0, splits.length - 1).join('.');
        }

        return null;
    };

    public static getListItemDepth(listItemId: string): number {
        return listItemId.split('.').length + 1;
    }

    public static isListItemSibling = (lhsListItemId: string, rhsListItemId: string): boolean => {
        const parentLhsListItemId = ExpandCollapseTreeUtils.getParentListItemId(lhsListItemId);
        const parentRhsListItemId = ExpandCollapseTreeUtils.getParentListItemId(rhsListItemId);
        return parentLhsListItemId === parentRhsListItemId && lhsListItemId !== rhsListItemId;
    };

    private static getListItemStateHashListSiblings(listItemStateHashList: ListItemStateHashList, listItemId: string): ListItemStateHashList {
        const parentListItemId = ExpandCollapseTreeUtils.getParentListItemId(listItemId);
        let response: ListItemStateHashList = {};
        if (parentListItemId) {
            const keys = Object.keys(listItemStateHashList);
            for (let i = 0; i < keys.length; ++i) {
                const currentListItemId = keys[i];

                if (
                    currentListItemId !== listItemId &&
                    currentListItemId.startsWith(parentListItemId) &&
                    ExpandCollapseTreeUtils.getListItemDepth(currentListItemId) === ExpandCollapseTreeUtils.getListItemDepth(listItemId)
                ) {
                    // this is a sibling
                    response[currentListItemId] = listItemStateHashList[currentListItemId];
                }
            }
        }

        return response;
    }

    public static isListItemDescendant = (lhsListItemId: string, rhsListItemId: string): boolean => {
        return lhsListItemId !== rhsListItemId && lhsListItemId.startsWith(rhsListItemId);
    };

    public static isListItemAncestor = (lhsListItemId: string, rhsListItemId: string): boolean => {
        return lhsListItemId !== rhsListItemId && rhsListItemId.startsWith(lhsListItemId);
    };

    public static isListItemChild = (lhsListItemId: string, rhsListItemId: string): boolean => {
        return (
            ExpandCollapseTreeUtils.isListItemDescendant(lhsListItemId, rhsListItemId) &&
            ExpandCollapseTreeUtils.getListItemDepth(lhsListItemId) - 1 === ExpandCollapseTreeUtils.getListItemDepth(rhsListItemId)
        );
    };

    public static isListItemUncle = (lhsListItemId: string, rhsListItemId: string): boolean => {
        const parentRhsListItemId = ExpandCollapseTreeUtils.getParentListItemId(rhsListItemId);
        return ExpandCollapseTreeUtils.isListItemSibling(lhsListItemId, parentRhsListItemId);
    };

    public static isListItemHidden(checkingListItemId: string, focusedListItemId, isExpanding: boolean): boolean {
        // function isAnyExpanded(stateHashList: ListItemStateHashList): boolean {
        //     const keys = Object.keys(stateHashList);
        //     let found = false;
        //     for (let i = 0; i < keys.length; ++i) {
        //         const currentListItemId = keys[i];
        //         if (listItemStateHashList[currentListItemId].isExpanded) {
        //             found = true;
        //         }
        //     }
        //     return found;
        // }

        if (ExpandCollapseTreeUtils.isListItemRoot(checkingListItemId)) {
            return false;
        }

        const isTheSame = checkingListItemId === focusedListItemId;
        const isSibling = ExpandCollapseTreeUtils.isListItemSibling(checkingListItemId, focusedListItemId);
        const isDescendant = ExpandCollapseTreeUtils.isListItemDescendant(checkingListItemId, focusedListItemId);
        const isChild = ExpandCollapseTreeUtils.isListItemChild(checkingListItemId, focusedListItemId);
        const isAncestor = ExpandCollapseTreeUtils.isListItemAncestor(checkingListItemId, focusedListItemId);
        const isUncle = ExpandCollapseTreeUtils.isListItemUncle(checkingListItemId, focusedListItemId);

        if (isExpanding) {
            if (isSibling) {
                return true;
            } else if (isTheSame) {
                return false;
            } else if (isChild) {
                return false;
            } else if (isAncestor) {
                return false;
            } else if (isDescendant) {
                return true;
            } else if (isUncle) {
                return true;
            } else {
                return true;
            }
        } else {
            if (isSibling) {
                return false;
            } else if (isTheSame) {
                return false;
            } else if (isChild) {
                return false;
            } else if (isDescendant) {
                return true;
            } else if (isAncestor) {
                return false;
            } else if (isUncle) {
                return false;
            } else {
                return true;
            }
        }
    }

    public static isListItemExpanded(checkingListItemId: string, focusedListItemId, isExpanding: boolean): boolean {
        if (ExpandCollapseTreeUtils.isListItemRoot(checkingListItemId)) {
            return true;
        }

        const isTheSame = checkingListItemId === focusedListItemId;
        const isSibling = ExpandCollapseTreeUtils.isListItemSibling(checkingListItemId, focusedListItemId);
        const isDescendant = ExpandCollapseTreeUtils.isListItemDescendant(checkingListItemId, focusedListItemId);
        const isChild = ExpandCollapseTreeUtils.isListItemChild(checkingListItemId, focusedListItemId);
        const isAncestor = ExpandCollapseTreeUtils.isListItemAncestor(checkingListItemId, focusedListItemId);

        if (isExpanding) {
            if (isSibling) {
                return false;
            } else if (isTheSame) {
                return true;
            } else if (isChild) {
                return false;
            } else if (isDescendant) {
                return false;
            } else if (isAncestor) {
                return true;
            } else {
                return false;
            }
        } else {
            if (isSibling) {
                return false;
            } else if (isTheSame) {
                return false;
            } else if (isChild) {
                return false;
            } else if (isDescendant) {
                return true;
            } else if (isAncestor) {
                return true;
            } else {
                return false;
            }
        }
    }
}
