import { Element, DOMNode } from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import './Tree.scss';
import './ExpandCollapseTree.scss';
import { ReplaceEmbeddedObjectsFunction, ReplaceMobileEmbeddedObjectsFunction } from '../../models/types/ReplaceEmbeddedObjectsFunction';
import TreeBase from './TreeBase';
import { NodeDetails } from './NodeDetails';
import { NodePositionProperties } from './NodePositionProperties';
import { ExpandCollapseTreeRecord, ExpandCollapseTreeUtils, ListItemStateHashList } from './ExpandCollapseTreeUtils';

type ExpandCollapseTreeReplaceEmbeddedObjectsFunction = (
    domNode: DOMNode,
    depth: number,
    index: number,
    parentIndex: number,
    parentListItemId: string,
    isRoot: boolean,
) => JSX.Element;

export type ExpandCollapseTreeReplaceEmbeddedObjectsCallbackFunction = (
    domNode: DOMNode,
    depth: number,
    index: number,
    parentIndex: number,
    listItemId: string,
    isRoot: boolean,
    isExpanded: boolean,
    callback: ExpandCollapseTreeReplaceEmbeddedObjectsFunction,
) => JSX.Element;

type ExpandCollapseTreeReplaceMobileEmbeddedObjectsFunction = (
    domNode: DOMNode,
    depth: number,
    index: number,
    parentIndex: number,
    parentListItemId: string,
    isRoot: boolean,
    isSoleRoot: boolean,
) => JSX.Element;

export type ExpandCollapseTreeReplaceMobileEmbeddedObjectsCallbackFunction = (
    domNode: DOMNode,
    depth: number,
    index: number,
    parentIndex: number,
    listItemId: string,
    isRoot: boolean,
    isSoleRoot: boolean,
    isExpanded: boolean,
    callback: ExpandCollapseTreeReplaceMobileEmbeddedObjectsFunction,
) => JSX.Element;

interface ExpandCollapseTreeProps<T> {
    SearchQueryHash: string;
    Hierarchy: ExpandCollapseTreeRecord<T>[];
    RootTreeElement: Element;
    HandleNodeClickEvents: (domNode: DOMNode, i: number, nodeCountAtIndex: number) => void;
    HandleListItemIconOnClick: (
        evt: React.MouseEvent<HTMLDivElement>,
        listItemId: string,
        isExpanded: boolean,
        newListItemState: ListItemStateHashList,
    ) => void;
    HandleSkipLi: (listItemId: string) => boolean;
    HandleSkipList: (parentListItemId: string) => boolean;
    ReplaceEmbeddedObjects?: ExpandCollapseTreeReplaceEmbeddedObjectsCallbackFunction;
    ReplaceMobileEmbeddedObjects?: ExpandCollapseTreeReplaceMobileEmbeddedObjectsCallbackFunction;
    ListItemStateHashList: ListItemStateHashList;
}

export function isListItemOnLeafLevel<T>(hierarchy: ExpandCollapseTreeRecord<T>[], id: string): boolean {
    let found = false;

    hierarchy.forEach((c) => {
        if (c.Id === id && !!!(c.Children && c.Children.length > 0)) {
            found = true;
        }

        if (!found && !!(c.Children && c.Children.length > 0)) {
            found = isListItemOnLeafLevel<T>(c.Children, id);
        }
    });

    return found;
}

export default function ExpandCollapseTree<T>(props: ExpandCollapseTreeProps<T>) {
    const [listItemState, setListItemState] = useState<ListItemStateHashList>();
    const [, setLatestToggleItemId] = useState<string>();
    const [currentNodeListItemToggleHash, setCurrentNodeListItemToggleHash] = useState<string>();
    const [currentHierarchyHash, setCurrentHierarchyHash] = useState<string>();
    const [treeWithHash, setTreeWithHash] = useState<{ hash: string; tree: Element }>();
    const [renderId, setRenderId] = useState<string>();

    //region lifecycle
    useEffect(() => {
        if (props.RootTreeElement) {
            const localRenderId = Date.now().toString();
            setRenderId(() => {
                return localRenderId;
            });
        }
    }, [props.RootTreeElement, JSON.stringify(listItemState)]);

    useEffect(() => {
        let localRootTreeElement = { ...props.RootTreeElement } as Element;
        localRootTreeElement.attribs['data-renderid'] = renderId;
        setTreeWithHash({ hash: renderId, tree: localRootTreeElement });
    }, [renderId]);

    useEffect(() => {
        if (JSON.stringify(props.ListItemStateHashList) !== JSON.stringify(listItemState)) {
            setListItemState(props.ListItemStateHashList);
        }
    }, [JSON.stringify(props.ListItemStateHashList)]);

    useEffect(() => {
        if (currentHierarchyHash !== props.SearchQueryHash) {
            setCurrentHierarchyHash(props.SearchQueryHash);

            generateInitialListItemState();
        }
    }, [props.SearchQueryHash]);
    //endregion

    //#region handle methods
    const handleListItemIconOnClick = (evt: React.MouseEvent<HTMLDivElement>, listItemId: string) => {
        if (isListItemOnLeafLevel(props.Hierarchy, listItemId)) {
            return;
        }

        let currentlyExpanded = !!(listItemState[listItemId] && listItemState[listItemId].isExpanded);

        let newListItemState = {};
        setListItemState((val) => {
            const keys = Object.keys(val);

            if (!ExpandCollapseTreeUtils.isListItemRoot(listItemId)) {
                val[listItemId] = {
                    isExpanded: !currentlyExpanded,
                    isHidden: ExpandCollapseTreeUtils.isListItemHidden(listItemId, listItemId, !currentlyExpanded),
                };
            }

            for (let i = 0; i < keys.length; ++i) {
                const current = keys[i];
                if (!ExpandCollapseTreeUtils.isListItemRoot(current) && current !== listItemId) {
                    val[current] = {
                        isExpanded: ExpandCollapseTreeUtils.isListItemExpanded(current, listItemId, !currentlyExpanded),
                        isHidden: ExpandCollapseTreeUtils.isListItemHidden(current, listItemId, !currentlyExpanded),
                    };
                }
            }
            newListItemState = val;
            return val;
        });
        setCurrentNodeListItemToggleHash(Date.now().toString() + '_ExpandCollapseTree');

        props.HandleListItemIconOnClick && props.HandleListItemIconOnClick(evt, listItemId, currentlyExpanded, newListItemState);
    };

    const generateInitialListItemState = () => {
        const initialState = ExpandCollapseTreeUtils.generateInitialListItemState<T>(props.Hierarchy);
        const root = props.Hierarchy[0];
        setListItemState(initialState);
        setLatestToggleItemId(root.Id);
    };

    const handleOnSelectedNodeSet = (
        selectedNodeDetails: NodeDetails,
    ): {
        TabletStartingNodeDetails: NodeDetails;
        MobileStartingNodeDetails: NodeDetails;
    } => {
        if (selectedNodeDetails) {
            let startingNodeDetailsTemp: NodeDetails = selectedNodeDetails;

            return {
                MobileStartingNodeDetails: startingNodeDetailsTemp,
                TabletStartingNodeDetails: startingNodeDetailsTemp,
            };
        }
    };

    const handleNodeOnClick = (evt: React.MouseEvent<HTMLLIElement>, domNode: DOMNode, i: number, nodeCountAtIndex: number) => {
        evt.preventDefault();
        evt.stopPropagation();
        props.HandleNodeClickEvents && props.HandleNodeClickEvents(domNode, i, nodeCountAtIndex);
    };

    const getParentListItemId = (listItemId: string) => {
        if (!listItemId) {
            return null;
        }
        const splits = listItemId.split('.');
        return splits.slice(0, splits.length - 1).join('.');
    };

    const handleSkipLi = (
        nodePositionalProperties: NodePositionProperties,
        depth: number,
        index: number,
        parentIndex: number,
        isRoot: boolean,
        listItemId: string,
    ): boolean => {
        if (isRoot) {
            return false;
        }

        const parent = getParentListItemId(listItemId);

        //parent is not in list or parent itself is not expanded
        if (!!!listItemState[parent] || !listItemState[parent].isExpanded) {
            return true;
        }

        return props.HandleSkipLi(listItemId);
    };

    const handleSkipList = (newDepth: number, parentIndex: number, parentListItemId: string): boolean => {
        if (!parentListItemId) {
            return false;
        }

        if (!!!listItemState[parentListItemId] || !listItemState[parentListItemId].isExpanded) {
            return true;
        }

        return props.HandleSkipList(parentListItemId);
    };

    const handleReplaceEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number,
        listItemId: string,
        isRoot: boolean,
        callback: ReplaceEmbeddedObjectsFunction,
    ) => {
        const currentlyExpanded = listItemState[listItemId] && listItemState[listItemId].isExpanded;
        return (
            props.ReplaceEmbeddedObjects &&
            props.ReplaceEmbeddedObjects(domNode, depth, index, parentIndex, listItemId, isRoot, currentlyExpanded, callback)
        );
    };

    const handleMobileReplaceEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number,
        listItemId: string,
        isRoot: boolean,
        isSoleRoot: boolean,
        callback: ReplaceMobileEmbeddedObjectsFunction,
    ) => {
        const currentlyExpanded = listItemState[listItemId] && listItemState[listItemId].isExpanded;
        return (
            props.ReplaceMobileEmbeddedObjects &&
            props.ReplaceMobileEmbeddedObjects(domNode, depth, index, parentIndex, listItemId, isRoot, isSoleRoot, currentlyExpanded, callback)
        );
    };

    const conditionsForOnNodeListItemToggle = (paramCurrentNodeListItemToggleHash: string): boolean => {
        return currentNodeListItemToggleHash !== paramCurrentNodeListItemToggleHash;
    };
    //#endregion

    return (
        <div className="tree-wrapper">
            <TreeBase
                HierarchyHash={props.SearchQueryHash}
                CurrentNodeListItemToggleHash={currentNodeListItemToggleHash}
                ConditionsForOnNodeListItemToggle={conditionsForOnNodeListItemToggle}
                ConditionsForOnSelectedNodeSet={() => true}
                Tree={treeWithHash?.tree}
                HasMoreThresholdCount={99}
                HandleNodeClickEvents={handleNodeOnClick}
                HandleListItemIconOnClick={handleListItemIconOnClick}
                OnSelectedNodeSet={handleOnSelectedNodeSet}
                ReplaceEmbeddedObjects={handleReplaceEmbeddedObjects}
                ReplaceMobileEmbeddedObjects={handleMobileReplaceEmbeddedObjects}
                RunCheckOnSelectedHierarchy={() => null}
                SkipLi={handleSkipLi}
                SkipLiMobile={handleSkipLi}
                SkipList={handleSkipList}
                SkipListMobile={handleSkipList}
            />
        </div>
    );
}
