import React, { useState } from 'react';
import { ContentItem } from '@exporter-services/common-ui';
import ComponentResolver from '../../ComponentResolver';
import { useAppSelector } from '../../hooks/reduxHooks';
import { ProductServiceSelectionInfo } from '../../models/ReduxModels';
import { CommonConstants } from '../../CommonConstants';
import NotListed from '../../controls/notListed/NotListed';

interface ShortlistProps {
    match?: any;
    data: {
        item?: ContentItem;
    };
}

enum ResultsStatuses {
    NotStarted = 1,
    NoResultsReturned = 2,
    ResultsReturned = 3,
}

const Shortlist = (props: ShortlistProps) => {
    const [resultsStatus, setResultsStatus] = useState<ResultsStatuses>(ResultsStatuses.NotStarted);
    const productsServicesSelectionState = useAppSelector<ProductServiceSelectionInfo>(
        (state) => state.productServiceSelection.productServiceSelectionInfo,
    );

    const pendingMarketSearchInfo = useAppSelector((state) => state.market.pendingMarketSearchInfo);

    const showShortlistHeader = () => {
        let resolveLinkTypeName = '';

        if (pendingMarketSearchInfo?.timeTag) {
            return <></>;
        }

        if (!productsServicesSelectionState.sectorCode && !productsServicesSelectionState.hsCode) return <></>;
        else if (productsServicesSelectionState.hsCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            resolveLinkTypeName = 'searchcontainer_commodity_not_selected';
        } else if (
            productsServicesSelectionState.sectorCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME ||
            resultsStatus === ResultsStatuses.NoResultsReturned
        ) {
            resolveLinkTypeName = 'search_dropdown_question';
        } else return <></>;

        return (
            <>
                <NotListed
                    data={{ item: { ...props.data.item, system: { ...props.data.item.system, type: resolveLinkTypeName } } }}
                    type={resolveLinkTypeName}
                />
            </>
        );
    };

    const isStrongMarkets = () => {
        return (
            productsServicesSelectionState.hsCode && productsServicesSelectionState.hsCode !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME
        );
    };

    const getContents = () => {
        if (
            !props.data.item.elements.list_of_questions ||
            !props.data.item.elements.list_of_questions['linkedItems'] ||
            !props.data.item.elements.list_of_questions['linkedItems'][0].elements.linked_items ||
            props.data.item.elements.list_of_questions['linkedItems'][0].elements.linked_items['linkedItems'].length < 2
        )
            return null;

        return isStrongMarkets()
            ? props.data.item.elements.list_of_questions['linkedItems'][0].elements.linked_items['linkedItems'][1]
            : props.data.item.elements.list_of_questions['linkedItems'][0].elements.linked_items['linkedItems'][0];
    };

    const handleResultsLoad = (available: boolean) => {
        setResultsStatus(available ? ResultsStatuses.ResultsReturned : ResultsStatuses.NoResultsReturned);
    };

    const renderResults = (filter: (a: ContentItem) => boolean) => {
        const contents = getContents();
        if (!contents) return <></>;

        return (
            <>
                <hr />
                {contents.elements.content['linkedItems'].filter(filter).map((a: ContentItem) => {
                    return (
                        <ComponentResolver
                            data={{ item: a, linkedItems: {} }}
                            key={a.system.id}
                            match={props.match}
                            onLoad={handleResultsLoad}
                            type={
                                props.data.item.elements.market_multiple_choice && props.data.item.elements.market_multiple_choice.value[0].codename
                            }
                        />
                    );
                })}
            </>
        );
    };

    const notListedIsNotSelected = () => {
        return !(
            productsServicesSelectionState.sectorCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME ||
            productsServicesSelectionState.hsCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME
        );
    };

    const filterContent = (a: ContentItem) => {
        if (pendingMarketSearchInfo?.timeTag) {
            return a.system.type === 'results_container';
        }

        return true;
    };

    return (
        <>
            {showShortlistHeader()}
            <div>
                {(productsServicesSelectionState.sectorCode || productsServicesSelectionState.hsCode) && notListedIsNotSelected() ? (
                    renderResults(filterContent)
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
export default Shortlist;
