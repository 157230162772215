import React, { useEffect, useContext } from 'react';
import { Col, Container } from 'reactstrap';
import './PageHeader.scss';
import { Elements } from '@kontent-ai/delivery-sdk';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { capitalise, formatDate } from '../../utils/utils';
import { formatSearchString } from '../../utils/searchStringHelper';
import DeliveryService from '../../services/DeliveryService';
import { getTaxonomyTermLabel } from '@exporter-services/common-ui/src/utils/taxonomyHelper';
import { personalisationContext } from '../../providers/personalisation';
import { setIndustrySector, setIndustrySectorHsCode } from '../../providers/personalisation/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { CurrentMarketNameInfo } from '../../models/ReduxModels';
import { getCurrentMarketNameInfo } from '../../providers/reducers/marketSlice';
import { useLocation } from 'react-router-dom';
import EbgaHead from '../EbgaHead';
import { PageName } from '../../models/PageName';
import { CommonConstants } from '../../CommonConstants';
import { useGetHSCodeLabelQuery } from '../../providers/reducers/commoditiesApi';
import { getPageHeaderBackgroundStyling } from './PageHeaderCommon';

interface PageHeaderProps {
    data: {
        type: any;
        title: any;
        title_tag: string;
        titleNumber?: Elements.NumberElement;
        description: any;
        metaDescription: string;
        image: any;
        label?: any;
        lastUpdated_at?: any;
        marketType?: Elements.MultipleChoiceElement;
        urlSlug?: Elements.UrlSlugElement;
        backgroundColorTheme?: Elements.MultipleChoiceElement;
    };
    match: any;
}

const checkIfMarketSearchToolShortlistedPage = (pathname: string) => {
    return (
        pathname.includes(`/${PageName.FindExportMarkets}/${PageName.ShortlistProducts}/`) ||
        pathname.includes(`/${PageName.FindExportMarkets}/${PageName.Shortlist}/`)
    );
};

const checkIfExportRulesFinderServicePage = (pathname: string) => {
    return pathname.includes(`/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}/${PageName.ServiceRequirements}`);
};

const PageHeader = (props: PageHeaderProps) => {
    const [sectorLabel, setSectorLabel] = useState(null);
    const [marketName, setMarketName] = useState(null);
    const [sectorLoading, setSectorLoading] = useState<boolean>(true);
    const [marketLoading, setMarketLoading] = useState<boolean>(true);
    const deliveryService = new DeliveryService();
    const [loading, setLoading] = useState<boolean>(true);
    const { dispatch } = useContext(personalisationContext);
    const reduxDispatch = useAppDispatch();

    let { pathname } = useLocation();

    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);

    let third_slug = null;
    let industry = null;
    let sector = null;
    let isQuizResultsPage = false;
    let isServiceRequirementsPage = false;

    let sectorParam = props.match.params?.sector ?? '';

    const isStrongMarket = () => {
        return props.data.marketType?.value?.[0]?.codename === 'strong_market';
    };

    const { data: hsCodeLabel, isSuccess: hsCodeLabelSuccess } = useGetHSCodeLabelQuery(
        { hsCode: sectorParam },
        { skip: !(sectorParam && isStrongMarket()) },
    );

    if (props.match.params) {
        third_slug = props.match.params.third_slug;
        industry = props.match.params.industry;
        isQuizResultsPage = third_slug && industry && third_slug === 'quiz-results';
        isServiceRequirementsPage = third_slug && industry && third_slug === 'service-requirements';
    }

    useEffect(() => {
        if (props.match.params?.market) {
            if (currentMarketNameInfo.retrieved) {
                setMarketName(currentMarketNameInfo.data);
                setMarketLoading(false);
                setLoading(false);
            }
        } else {
            setMarketLoading(false);
            setLoading(false);
        }
    }, [currentMarketNameInfo]);

    useEffect(() => {
        const marketCodeName = props.data.marketType?.value?.[0]?.codename;
        if (marketCodeName === 'strong_market' || marketCodeName === 'market') {
            reduxDispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: props.match }));
        } else {
            setMarketLoading(false);
            if (!checkIfExportRulesFinderServicePage(pathname)) {
                setLoading(false);
            }
        }

        if (marketCodeName === 'market' || isQuizResultsPage || isServiceRequirementsPage) {
            if (props.match.params.sector || props.match.params.industry) {
                let sector = props.match.params.sector
                    ? props.match.params.sector.replace(/-/g, '_')
                    : props.match.params.industry.replace(/-/g, '_');

                deliveryService.getTaxonomyGroup('sector').then((taxGroup) => {
                    const sectorLabelTemp = getTaxonomyTermLabel(taxGroup, sector);
                    setSectorLabel(sectorLabelTemp);
                });
            }
        }

        if (!isStrongMarket()) {
            setSectorLoading(false);
            if (!checkIfExportRulesFinderServicePage(pathname)) {
                setLoading(false);
            }
        }

        if (isQuizResultsPage) {
            getQuizPageDescription();
        }

        if (sector) {
            const normalisedSector = sector.replace(/-/g, '_');
            if (isStrongMarket()) {
                dispatch(setIndustrySectorHsCode(CommonConstants.PRODUCTS_CODENAME, CommonConstants.PRODUCTS_CODENAME, normalisedSector));
            } else if (industry) {
                dispatch(setIndustrySector(industry.replace(/-/g, '_'), normalisedSector));
            }
        }
    }, []);

    useEffect(() => {
        if (!hsCodeLabelSuccess) {
            return;
        }
        if (hsCodeLabel) {
            setSectorLabel(hsCodeLabel.Label);
        }
        setSectorLoading(false);
        setLoading(false);
    }, [hsCodeLabelSuccess, hsCodeLabel]);

    const showAriaInfo = () => !!props.data.label;

    const getQuizPageTitle = (sector: string) => {
        let pageTitle;
        if (sector && sector !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL) {
            pageTitle = props.data.title.replace(/{{sector}}/g, sector);
        } else {
            pageTitle = props.data.title.replace(/{{sector}}/g, '');
        }
        return pageTitle;
    };

    const getPageTitle = (market: string, sector: string) => {
        let pageTitle = props.data.title.replace(/{{market}}/g, market);

        if (sectorParam === hsCodeLabel?.Code) {
            sector = hsCodeLabel.Code;
        }

        if (isQuizResultsPage) {
            return getQuizPageTitle(sector);
        }

        return pageTitle.replace(/{{sector}}/g, sector);
    };

    const getQuizPageDescription = () => {
        let descriptionText = props.data.description;
        if (sectorLabel) {
            let sector = capitalise(sectorLabel);
            if (sector !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL) {
                descriptionText = descriptionText.replace(/{{sector}}/g, '<strong>' + sector + '</strong>');
            } else {
                descriptionText = descriptionText.replace(/{{sector}}/g, '');
                descriptionText = descriptionText.substring(0, descriptionText.length - 2);
                descriptionText = descriptionText + '.';
            }
        }
        return descriptionText;
    };

    const getDescription = () => {
        let descriptionText = props.data.description.replace(/{{market}}/g, '<strong>' + marketName + '</strong>');
        if (sectorLabel) {
            let sector = sectorLabel;
            if (
                (props.data.marketType && props.data.marketType.value.length > 0 && props.data.marketType.value[0].codename === 'market') ||
                isQuizResultsPage ||
                isServiceRequirementsPage
            ) {
                sector = capitalise(sectorLabel);
                descriptionText = descriptionText.replace(/{{sector}}/g, '<strong>' + sector + '</strong>');
            } else {
                sector = formatSearchString(sector);
                descriptionText = descriptionText.replace(/{{sector}}/g, '<strong>' + sector + '</strong>');
            }
        }
        if (isQuizResultsPage) {
            descriptionText = getQuizPageDescription();
        }

        return descriptionText;
    };

    const getMetaDescription = () => {
        return getDescription().replace(/<\/?[^>]+(>|$)/g, '');
    };

    const getLastModifiedDate = () => {
        if (!props.data.lastUpdated_at || checkIfExportRulesFinderServicePage(pathname) || checkIfMarketSearchToolShortlistedPage(pathname)) {
            return null;
        }

        return (
            <p data-testid="last-updated" className="last-updated">
                Last updated: {formatDate(props.data.lastUpdated_at)}
            </p>
        );
    };

    const getStandardH1 = (showAria: boolean, testId: string, cssClass?: string) => (
        <h1 id="pageTitle" className={`${!!cssClass ? `${cssClass}` : ''}`} aria-hidden={!showAria} data-testid={testId}>
            {props.data.titleNumber?.value ? props.data.titleNumber.value + '.' : ''}{' '}
            {/*show loading overlay only on filtered market result (country page) or filtered strong market result (country page)*/}
            {props.match.params && (props.match.params.market || props.match.params.sector)
                ? !marketLoading && !sectorLoading
                    ? getPageTitle(marketName, sectorLabel)
                    : ''
                : getPageTitle(marketName, sectorLabel)}
        </h1>
    );

    return (
        <>
            <EbgaHead
                metaDescription={props.data.metaDescription}
                abstract={getMetaDescription()}
                pageTitle={getPageTitle(marketName, sectorLabel)}
                titleTag={props.data.title_tag}
                market={marketName}
                sectorLabel={sectorLabel}
                sector={sectorLabel}
            />

            <LoadingOverlay active={loading || marketLoading || sectorLoading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <Container fluid className={`page-header ${getPageHeaderBackgroundStyling(props.data.backgroundColorTheme)}`} id="page-header">
                    <Container>
                        <div className="content-header">
                            {props.data.image.value.length > 0 ? (
                                <Col className="d-flex">
                                    <div className="component-content p-md-5 p-4">
                                        {showAriaInfo() && (
                                            <>
                                                {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                                <div className="header-label">{props.data.label}</div>
                                            </>
                                        )}
                                        {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                        {getLastModifiedDate()}
                                        <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                    </div>
                                    <div className="component-image">
                                        {props.data.image.value && <img src={props.data.image.value[0].url} alt="" />}
                                    </div>
                                </Col>
                            ) : (
                                <Col xl={8} md={10} sm={12} className="p-md-5 p-4">
                                    {showAriaInfo() && (
                                        <>
                                            {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                            <div className="header-label">{props.data.label}</div>
                                        </>
                                    )}
                                    {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                    {getLastModifiedDate()}
                                    <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                </Col>
                            )}
                        </div>
                    </Container>
                </Container>
            </LoadingOverlay>
        </>
    );
};
export default PageHeader;
