import React, { useRef } from 'react';
import './ComMultiSelectUIItem.scss';
import { ENTER_KEY, SPACE_KEY } from '../../../utils/interactionHelper';

export interface ComMultiSelectUIItemProps {
    data: {
        value: string;
        text: string;
    };
    removeItemHandler?: Function;
}

export const ComMultiSelectUIItem = (props: ComMultiSelectUIItemProps) => {
    const elementRef = useRef(null);

    const onRemoveItem = (e) => {
        props.removeItemHandler && props.removeItemHandler(props.data.value, props.data.text);
    };

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === SPACE_KEY) {
            e.currentTarget.click();
        } else if (e.key === ENTER_KEY) {
            elementRef.current.click();
        }
    };

    return (
        <div role="button" className="com-multi-select-ui-item" tabIndex={0} onClick={onRemoveItem} onKeyPress={handleOnKeyPress}>
            <span ref={elementRef}>
                <span className="close-mini-icon"></span>
                <span className="close-text">{props.data.text} </span>
            </span>
        </div>
    );
};
