import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import './ComRadioButtonGroup.scss';
import ComRadioButton from './ComRadioButton';

export interface ComRadioButtonGroupItem {
    Id: string;
    Title: string;
    Value: string;
}

export enum ComRadioButtonGroupLayout {
    NotStacked = 0,
    Stacked = 1,
}

export interface ComRadioButtonGroupProps {
    items: ComRadioButtonGroupItem[];
    selectedValue: string;
    focusedValue?: string;
    name: string;
    setLabelCssClass: () => string;
    setLayout: () => ComRadioButtonGroupLayout;
    handleOnChange: (evt: React.MouseEvent<HTMLInputElement>, selectedValue: string) => void;
}

export const ComRadioButtonGroup = (props: ComRadioButtonGroupProps) => {
    const [items, setItems] = useState<ComRadioButtonGroupItem[]>([]);
    const [refsCreated, setRefsCreated] = useState<boolean>(false);
    const myRefs = useRef<Record<string, RefObject<HTMLInputElement>>>(null);
    const memoizedItems = useMemo(() => {
        if (JSON.stringify(items) !== JSON.stringify(props.items)) {
            setItems(props.items);
        }
        return items;
    }, [props.items, items]);

    useEffect(() => {
        setRefsCreated(false);
    }, [memoizedItems]);

    useEffect(() => {
        if (!refsCreated) {
            if (memoizedItems.length > 0) {
                let count = 0;
                memoizedItems.forEach((o) => {
                    count += !!(myRefs.current && myRefs.current[o.Value]) ? 1 : 0;
                });
                if (count === memoizedItems.length) {
                    setRefsCreated(true);
                }
            }
        }
    }, [refsCreated, myRefs, memoizedItems]);

    const handleOnChange = (evt: React.MouseEvent<HTMLInputElement>, selectedValue: string): void => {
        props.handleOnChange(evt, selectedValue);
    };

    const handleOnCreateRef = (ref: React.RefObject<HTMLInputElement>, item: ComRadioButtonGroupItem) => {
        if (myRefs.current) {
            myRefs.current[item.Value] = ref;
        } else {
            let newRefs: Record<string, RefObject<HTMLInputElement>> = Object.create({});
            newRefs[item.Value] = ref;
            myRefs.current = newRefs;
        }
    };

    return (
        <div className={`radio-button-group ${props.setLayout() === ComRadioButtonGroupLayout.Stacked ? 'stacked' : 'not-stacked'}`}>
            {memoizedItems.map((o, i) => {
                return (
                    <ComRadioButton
                        data={{
                            Index: i,
                            Title: o.Title,
                            Value: o.Value,
                            Id: o.Id,
                        }}
                        key={o.Id}
                        refsCreated={refsCreated}
                        selectedValue={props.selectedValue}
                        focusedValue={props.focusedValue}
                        name={`radio-button-group_${props.name}`}
                        setLabelCssClass={props.setLabelCssClass}
                        handleOnChange={(evt, itm) => handleOnChange(evt, itm)}
                        handleOnRefCreate={(ref) => handleOnCreateRef(ref, o)}
                    />
                );
            })}
        </div>
    );
};
