import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import './BodyCopy.scss';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { BodyCopyItem } from './BodyCopyItem';
import { useState } from 'react';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/utils';
import useGetKontentPlaceholderValues, { KontentPlaceHolder, replacePlaceHolders } from '../../hooks/useGetKontentPlaceholderValues';

interface BodyCopyProps {
    data: {
        item: BodyCopyItem;
        linkedItems: IContentItemsContainer;
    };
    match?: any;
    displayTitle: boolean;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    onLoadDescription?: (dataAvailable: boolean, description: string) => void;
    type?: string;
}

const BodyCopy = (props: BodyCopyProps) => {
    const [showHeading, setShowHeading] = useState<boolean>(true);
    const [hasTitle, setHasTitle] = useState<boolean>(false);

    const kontentPlaceHolder: KontentPlaceHolder = useGetKontentPlaceholderValues().kontentPlaceHolder;

    useEffect(() => {
        if (props.data.item.elements.title !== undefined) {
            setHasTitle(true);
        }
    }, []);

    const handleComponentLoad = (dataAvailable: boolean, title: string) => {
        props.onLoad && props.onLoad(dataAvailable, replacePlaceHolders(title, kontentPlaceHolder));
        setShowHeading(dataAvailable);
    };

    const handleComponentLoadDescription = (dataAvailable: boolean, description: string) => {
        props.onLoadDescription && props.onLoadDescription(dataAvailable, replacePlaceHolders(description, kontentPlaceHolder));
    };

    const getId = (title: string): string =>
        title &&
        title
            .replace(/\s+/g, '-')
            .replace(/[^\w-]/g, '')
            .toLowerCase();

    const { personalisationState } = useContext(personalisationContext);

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <Row>
                    <Col>
                        <div
                            className="body-copy"
                            id={
                                !hasTitle
                                    ? Date.now().toString()
                                    : props.data.item.elements.title.value &&
                                      getId(replacePlaceHolders(props.data.item.elements.title.value, kontentPlaceHolder))
                            }
                        >
                            {hasTitle && showHeading && props.data.item.elements.title.value && props.displayTitle && (
                                <h2>{replacePlaceHolders(props.data.item.elements.title.value, kontentPlaceHolder)}</h2>
                            )}
                            <ResolveLink
                                data={{
                                    item: props.data.item,
                                    linkedItems: props.data.linkedItems,
                                    match: props.match,
                                    onLoad: handleComponentLoad,
                                    onLoadDescription: handleComponentLoadDescription,
                                }}
                                type={props.type}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default BodyCopy;
