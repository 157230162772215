import React from 'react';
import { Row, Col } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import ComponentResolver from '../../ComponentResolver';

interface FilteredMarketResultsProps {
    match?: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
}

const FilteredMarketResults = (props: FilteredMarketResultsProps) => {
    return (
        <Row className="filtered-results">
            <Col>
                {props.data.item.elements.linked_items['linkedItems'].map((a: ContentItem) => {
                    return (
                        <ComponentResolver
                            data={{ item: a, linkedItems: props.data.linkedItems }}
                            key={a.system.id}
                            match={props.match}
                            onLoad={props.onLoad}
                            type={
                                props.data.item.elements.market_multiple_choice && props.data.item.elements.market_multiple_choice.value[0].codename
                            }
                        />
                    );
                })}
            </Col>
        </Row>
    );
};
export default FilteredMarketResults;
