import React from 'react';
import ComponentResolver from '../../ComponentResolver';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { Col, Row } from 'reactstrap';
import { PageItem } from '../../models/PageItem';

interface NewsArticlePageProps {
    data: {
        item: PageItem;
        contentItems: ContentItem[];
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

export default function NewsArticlePage(props: NewsArticlePageProps) {
    return (
        <Row>
            <Col xs={12}>
                {props.data.contentItems?.map((item: ContentItem) => (
                    <ComponentResolver data={{ item: item, linkedItems: props.data.linkedItems }} key={item.system.id} match={props.match} />
                ))}
            </Col>
        </Row>
    );
}
