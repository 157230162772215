import { useState } from 'react';
import { client } from '../config';
import { ContentItem } from '@exporter-services/common-ui';
import { CommonConstants } from '../CommonConstants';

interface useCommodityIndustryMarketHelperProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const useCommodityIndustryMarketHelper = (props: useCommodityIndustryMarketHelperProps) => {
    const [content, setContent] = useState(null);
    const [linkedItems, setLinkedItems] = useState(null);
    const [noContentItem, setNoContentItem] = useState<ContentItem>(null);

    const params = props.match.params;
    const market = params.market.toUpperCase();
    const commodity = params.sector.toUpperCase();
    const onLoad = props.onLoad;
    const propsType = props.type;

    const DefaultKenticoElementSectorFilterCodeName = 'industry_sector';

    const getIndustry = () => {
        if (propsType === 'strong_market') {
            return CommonConstants.PRODUCTS_CODENAME;
        } else {
            if (propsType === 'market' && params.sector !== 'all-sector') {
                return params.sector.replace(/-/g, '_');
            } else {
                return params.industry.replace(/-/g, '_');
            }
        }
    };

    const industry = getIndustry();

    const getIndustryMarketTypes = (systemType, optionalKenticoElementSectorFilterCodeName) => {
        client
            .items()
            .equalsFilter('system.type', systemType)
            .anyFilter(`elements.${optionalKenticoElementSectorFilterCodeName}`, [industry, CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                setContent(response.data.items[0]);
                setLinkedItems(response.data.linkedItems);
                onLoad && onLoad(response.data.items.length > 0, response.data.items.length > 0 ? response.data.items[0].elements.title.value : '');
            });
    };

    const getMarketCommodityTypes = (systemType, optionalTitle = '') => {
        client
            .items()
            .equalsFilter('system.type', systemType)
            .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .anyFilter('elements.hscodesnippet__hscode', ['n' + commodity, CommonConstants.KONTENT_HSCODES_ALL_CODENAME])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                let hasItems = response.data.items.length > 0;
                if (!hasItems && optionalTitle) {
                    setLinkedItems(response.data.linkedItems);
                    onLoad && onLoad(true, optionalTitle);
                } else {
                    setContent(response.data.items[0]);
                    setLinkedItems(response.data.linkedItems);
                    onLoad && onLoad(hasItems, hasItems ? response.data.items[0].elements.title.value : '');
                }
            });
    };

    const getNoContentStandardPathway = (systemCodeName) => {
        client
            .items()
            .equalsFilter('system.codename', systemCodeName)
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                setNoContentItem(response.data.items[0]);
            });
    };

    const getCommodityIndustryMarketTypes = (
        systemType,
        optionalTitle = '',
        optionalKenticoElementSectorFilterCodeName = DefaultKenticoElementSectorFilterCodeName,
    ) => {
        if (propsType === 'market') {
            getIndustryMarketTypes(systemType, optionalKenticoElementSectorFilterCodeName);
        } else {
            getMarketCommodityTypes(systemType, optionalTitle);
        }
    };

    return { content, linkedItems, noContentItem, getCommodityIndustryMarketTypes, getNoContentStandardPathway };
};

export default useCommodityIndustryMarketHelper;
