import { Element, DOMNode, Text, domToReact } from 'html-react-parser';
import { ElementType } from 'domelementtype';
import React, { useEffect, useState } from 'react';
import './Tree.scss';
import { TreeNodeElement, TreeUtilities } from './TreeUtils';
import {
    ReplaceEmbeddedObjectsCallbackFunction,
    ReplaceMobileEmbeddedObjectsCallbackFunction,
} from '../../models/types/ReplaceEmbeddedObjectsFunction';
import { NodePositionProperties } from './NodePositionProperties';
import { NodeDetails } from './NodeDetails';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { ComButton } from '@exporter-services/common-ui';

interface TreeBaseProps {
    Tree: Element;
    HierarchyHash: string;
    HandleNodeClickEvents: (evt: React.MouseEvent<HTMLLIElement>, domNode: DOMNode, i: number, nodeCountAtIndex: number) => void;
    HandleListItemIconOnClick?: (evt: React.MouseEvent<HTMLDivElement>, listItemId: string) => void;
    ReplaceEmbeddedObjects?: ReplaceEmbeddedObjectsCallbackFunction;
    ReplaceMobileEmbeddedObjects?: ReplaceMobileEmbeddedObjectsCallbackFunction;
    HasMoreThresholdCount?: number;
    SkipLi?: (
        nodePositionalProperties: NodePositionProperties,
        depth: number,
        index: number,
        parentIndex: number,
        isRoot: boolean,
        listItemId: string,
        selectedNodeDetails: NodeDetails,
    ) => boolean;
    SkipLiMobile?: (
        nodePositionalProperties: NodePositionProperties,
        depth: number,
        index: number,
        parentIndex: number,
        isRoot: boolean,
        listItemId: string,
        selectedNodeDetails: NodeDetails,
        selectedNodeParentDetails: NodeDetails,
    ) => boolean;
    SkipList?: (
        newDepth: number,
        parentIndex: number,
        parentListItemId: string,
        selectedNodeDetails: NodeDetails,
        selectedNodeParentDetails: NodeDetails,
    ) => boolean;
    SkipListMobile?: (
        newDepth: number,
        parentIndex: number,
        parentListItemId: string,
        selectedNodeDetails: NodeDetails,
        selectedNodeParentDetails: NodeDetails,
    ) => boolean;
    RunCheckOnSelectedHierarchy?: (
        domNode: Node,
        depth: number,
        index: number,
        parentIndex: number,
        parentNode: Node,
        grandParentIndex,
        grandParentNode: Node,
    ) => void;
    ConditionsForOnSelectedNodeSet?: (currentHierarchyHash: string, currentNodeListItemToggleHash: string, rootTreeElement: Element) => boolean;
    OnSelectedNodeSet?: (
        selectedNodeDetails: NodeDetails,
        selectedNodeParentDetails: NodeDetails,
    ) => {
        TabletStartingNodeDetails: NodeDetails;
        MobileStartingNodeDetails: NodeDetails;
    };
    ConditionsForOnNodeListItemToggle?: (currentNodeListItemToggleHash: string) => boolean;
    CurrentNodeListItemToggleHash?: string;
}

const Tree = (props: TreeBaseProps) => {
    const [tree, setTree] = useState<Element>();
    const [selectedNodeParentDetails, setselectedNodeParentDetails] = useState<NodeDetails>();
    const [selectedNodeDetails, setselectedNodeDetails] = useState<NodeDetails>();
    const [previouslySelectedNodeDetails, setPreviouslySelectedNodeDetails] = useState<NodeDetails>();
    const [tabletStartingNodeDetails, settabletStartingNodeDetails] = useState<NodeDetails>();
    const [mobileStartingNodeDetails, setmobileStartingNodeDetails] = useState<NodeDetails>();
    const defaultHasMoreThresholdCount = 3;
    const [hasMoreThresholdCount] = useState<number>(props.HasMoreThresholdCount ?? defaultHasMoreThresholdCount);
    let nodeCount = 0;
    const rootDepth = 1;
    const { isMobile, isTablet } = useGetDisplayType();
    const [treeShowHideHashMap, settreeShowHideHashMapRecord] = useState<Record<string, boolean>>({});
    const [timestamp, setTimestamp] = useState<number>(0);
    const [currentHierarchyHash, setCurrentHierarchyHash] = useState<string>();
    const [currentNodeListItemToggleHash, setCurrentNodeListItemToggleHash] = useState<string>();

    //#region lifecycle

    useEffect(() => {
        const selectedNodeDetailsObject = {
            selectRootDomNode: null,
            nodeCountIndex: 0,
            previouslySelectedNode: null,
            selectedNodeDetails: null,
            selectedNodeParentDetails: null,
            selectedGrandParenDetails: null,
        };

        const findAndSetSelectedNode = (
            domNode: DOMNode,
            depth: number,
            index: number,
            parentIndex: number = null,
            parentNode: DOMNode = null,
            grandParentIndex = null,
            grandParentNode: DOMNode = null,
        ) => {
            if (domNode.type === ElementType.Tag) {
                let element = domNode as Element;
                if (element.tagName === 'li') {
                    element.attribs['index'] =
                        (parentNode && (parentNode as Element).attribs['index'] ? (parentNode as Element).attribs['index'] + '.' : '') +
                        index.toString();

                    if (element.attribs['class'] && element.attribs['class'].includes('has-selection')) {
                        const nodeCountIndex = TreeUtilities.getNodeCountAtIndex(domNode, index) - 1;
                        selectedNodeDetailsObject.nodeCountIndex = nodeCountIndex;
                        selectedNodeDetailsObject.previouslySelectedNode = selectedNodeDetailsObject;
                        selectedNodeDetailsObject.selectedNodeDetails = new NodeDetails(domNode, depth, nodeCountIndex);
                        selectedNodeDetailsObject.selectedNodeParentDetails = parentNode && new NodeDetails(parentNode, depth - 1, parentIndex);
                        selectedNodeDetailsObject.selectedGrandParenDetails =
                            grandParentNode && new NodeDetails(grandParentNode, depth - 2, grandParentIndex);

                        element.children.map((c, i) => findAndSetSelectedNode(c as DOMNode, depth + 1, i, index, domNode, parentIndex, parentNode));
                    } else {
                        return (
                            <>
                                {element.children.map((c, i) =>
                                    findAndSetSelectedNode(c as DOMNode, depth + 1, i, index, domNode, parentIndex, parentNode),
                                )}
                            </>
                        );
                    }
                } else if (element.tagName === 'ul') {
                    return (
                        <>
                            {element.children.map((c, i) =>
                                findAndSetSelectedNode(c as DOMNode, depth, i, parentIndex, parentNode, grandParentIndex, grandParentNode),
                            )}
                        </>
                    );
                }
            }
        };

        props.Tree &&
            props.Tree.children.forEach((c, i) => {
                findAndSetSelectedNode(c as DOMNode, rootDepth, i);
            });

        selectedNodeDetailsObject.selectRootDomNode = props.Tree;
        if (props.Tree) {
            props.RunCheckOnSelectedHierarchy &&
                props.RunCheckOnSelectedHierarchy(
                    selectedNodeDetailsObject.selectedNodeDetails.DomNode,
                    selectedNodeDetailsObject.selectedNodeDetails.Depth,
                    selectedNodeDetailsObject.selectedNodeDetails.Index,
                    selectedNodeDetailsObject.selectedNodeParentDetails?.Index,
                    selectedNodeDetailsObject.selectedNodeParentDetails?.DomNode,
                    selectedNodeDetailsObject.selectedGrandParenDetails?.Index,
                    selectedNodeDetailsObject.selectedGrandParenDetails?.DomNode,
                );

            setPreviouslySelectedNodeDetails(selectedNodeDetailsObject.selectedNodeParentDetails);
            setselectedNodeDetails(selectedNodeDetailsObject.selectedNodeDetails);
            selectedNodeDetailsObject.selectedNodeParentDetails &&
                setselectedNodeParentDetails(
                    new NodeDetails(
                        selectedNodeDetailsObject.selectedNodeParentDetails.DomNode,
                        selectedNodeDetailsObject.selectedNodeDetails.Depth - 1,
                        selectedNodeDetailsObject.selectedNodeParentDetails.Index,
                    ),
                );

            if (!props.ConditionsForOnSelectedNodeSet) {
                if (
                    selectedNodeDetails &&
                    previouslySelectedNodeDetails &&
                    selectedNodeDetails.Index === previouslySelectedNodeDetails.Index &&
                    selectedNodeDetails.Depth === previouslySelectedNodeDetails.Depth
                ) {
                    return;
                }
            }

            if (
                !props.ConditionsForOnSelectedNodeSet ||
                props.ConditionsForOnSelectedNodeSet(currentHierarchyHash, currentNodeListItemToggleHash, props.Tree)
            ) {
                setCurrentHierarchyHash(props.HierarchyHash);
                setCurrentNodeListItemToggleHash(props.CurrentNodeListItemToggleHash);
                if (props.OnSelectedNodeSet) {
                    const startingDetails = props.OnSelectedNodeSet(selectedNodeDetailsObject.selectedNodeDetails, selectedNodeParentDetails);
                    setmobileStartingNodeDetails(startingDetails.MobileStartingNodeDetails);
                    settabletStartingNodeDetails(startingDetails.TabletStartingNodeDetails);
                }

                selectedNodeDetailsObject.selectRootDomNode && setTree(selectedNodeDetailsObject.selectRootDomNode as Element);
            }
        }
    }, [props.Tree, timestamp]);

    //#endregion

    //#region page event methods
    const seeMoreOnClick = (evt: any, depth: number, index: number) => {
        if (depth === rootDepth) {
            treeShowHideHashMap[depth.toString()] = true;
        } else {
            treeShowHideHashMap[depth.toString() + '_' + index] = true;
        }
        settreeShowHideHashMapRecord(treeShowHideHashMap);
        setTimestamp(Date.now);
    };

    const seeLessOnClick = (evt: any, depth: number, index: number) => {
        if (depth === 1) {
            treeShowHideHashMap[depth.toString()] = false;
        } else {
            treeShowHideHashMap[depth.toString() + '_' + index] = false;
        }
        settreeShowHideHashMapRecord(treeShowHideHashMap);
        setTimestamp(Date.now);
    };
    //#endregion page event methods

    //#region process methods
    const isContracted = (depth: number, index: number): boolean => {
        return (
            (depth > rootDepth && !treeShowHideHashMap[depth.toString() + '_' + index]) ||
            (depth === rootDepth && !treeShowHideHashMap[depth.toString()])
        );
    };

    const getLiCountForUl = (domNode: DOMNode) => {
        if (domNode.type === ElementType.Tag && (domNode as Element).tagName === 'ul') {
            let count = 0;

            (domNode as Element).children.forEach((c) => {
                if (c.type === ElementType.Tag && (c as Element).tagName === 'li') {
                    ++count;
                }
            });

            return count;
        }

        return null;
    };

    const getNodePositionalProperties = (
        domNode: DOMNode,
        depth: number,
        index: number,
        selectedNodeParentDetails: NodeDetails = undefined,
    ): NodePositionProperties => {
        let commonAncestor = findCommonAncestorWithSelectedNode(domNode, depth, index);
        const selectedNodeIsRoot = selectedNodeDetails.Depth === rootDepth;
        let isSibling = false;
        let isSameLevel = false;
        let isSelectedItem = selectedNodeDetails.Depth === depth && selectedNodeDetails.Index === index;
        let hasCommonAncestor = false;
        let isLowerLevel = false;
        let isCommonAncestorParent = false;
        let isDescendant = false;
        let hasChildren = false;
        const hasParent = !!selectedNodeParentDetails;
        const isParentLevel = hasParent && depth === selectedNodeParentDetails.Depth;
        const isParent = isParentLevel && index === selectedNodeParentDetails.Index;
        const isUncle = isParentLevel && index !== selectedNodeParentDetails.Index;

        if (commonAncestor) {
            hasCommonAncestor = true;
            if (!(selectedNodeIsRoot && depth === rootDepth)) {
                if (commonAncestor.Depth <= selectedNodeDetails.Depth) {
                    //is common ancestor either parent, uncle, grand parent or grand uncle
                    isLowerLevel = selectedNodeDetails.Depth < depth;
                    isSameLevel = selectedNodeDetails.Depth === depth;

                    isCommonAncestorParent = isSameLevel && hasParent && commonAncestor.Index === selectedNodeParentDetails.Index;

                    if (isLowerLevel && commonAncestor.Depth === selectedNodeDetails.Depth && commonAncestor.Index === selectedNodeDetails.Index) {
                        isDescendant = true;
                    } else if (isSameLevel) {
                        if (!isSelectedItem && isCommonAncestorParent) {
                            isSibling = index !== selectedNodeDetails.Index;
                        }
                    }

                    if (isDescendant || isSibling || isSelectedItem) {
                        hasChildren = liHasChildren(domNode);
                    }
                }
            }
        }

        return {
            hasCommonAncestor: hasCommonAncestor,
            isSelectedItem: isSelectedItem,
            isLowerLevel: isLowerLevel,
            isDescendant: isDescendant,
            isSibling: isSibling,
            isUncle: isUncle,
            isParent: isParent,
            isSameLevel: isSameLevel,
            isCommonAncestorParent: isCommonAncestorParent,
            commonAncestor: commonAncestor,
            hasChildren: hasChildren,
        };
    };

    const getMobileNodePositionalProperties = (domNode: DOMNode, depth: number, index: number): NodePositionProperties => {
        let commonAncestor = findCommonAncestorWithSelectedNode(domNode, depth, index);
        const selectedNodeIsRoot = selectedNodeDetails.Depth === rootDepth;
        let isSibling = false;
        let isSameLevel = false;
        let isDescendant = false;
        let hasChildren = false;
        let hasCommonAncestor = false;
        let isLowerLevel = false;
        let isCommonAncestorParent = false;
        const hasParent = !!selectedNodeParentDetails;
        const isParentLevel = hasParent && depth === selectedNodeParentDetails.Depth;
        const isParent = isParentLevel && index === selectedNodeParentDetails.Index;
        const isUncle = isParentLevel && index !== selectedNodeParentDetails.Index;

        let isSelectedItem = selectedNodeDetails.Depth === depth && selectedNodeDetails.Index === index;

        if (commonAncestor) {
            hasCommonAncestor = true;
            if (!(selectedNodeIsRoot && depth === rootDepth)) {
                if (commonAncestor.Depth <= selectedNodeDetails.Depth) {
                    isLowerLevel = selectedNodeDetails.Depth < depth;
                    isSameLevel = selectedNodeDetails.Depth === depth;
                    isCommonAncestorParent = isSameLevel && hasParent && commonAncestor.Index === selectedNodeParentDetails.Index;

                    if (isLowerLevel && commonAncestor.Depth === selectedNodeDetails.Depth && commonAncestor.Index === selectedNodeDetails.Index) {
                        isDescendant = true;
                    }

                    if (isSameLevel) {
                        if (isCommonAncestorParent) {
                            isSibling = index !== selectedNodeDetails.Index;
                        }
                    }

                    if (isDescendant || isSibling || isSelectedItem) {
                        hasChildren = liHasChildren(domNode as Element);
                    }
                }
            }
        }

        return {
            hasCommonAncestor: hasCommonAncestor,
            isSelectedItem: isSelectedItem,
            isLowerLevel: isLowerLevel,
            isDescendant: isDescendant,
            isSibling: isSibling,
            isParent: isParent,
            isUncle: isUncle,
            isSameLevel: isSameLevel,
            isCommonAncestorParent: isCommonAncestorParent,
            commonAncestor: commonAncestor,
            hasChildren: hasChildren,
        };
    };

    const areChildrenViewDisplayable = (depth: number, properties: NodePositionProperties) => {
        if (!properties.hasChildren) {
            return false;
        }
        return !(!properties.isSameLevel && !properties.hasChildren);
    };

    const areChildrenMobileViewDisplayable = (depth: number, properties: NodePositionProperties) => {
        if (!properties.hasChildren) {
            return false;
        }
        return !(!properties.isSameLevel && !properties.hasChildren);
    };

    const getLiAttributes = (domNode: DOMNode, i: number, nodeCountAtIndex: number): TreeNodeElement => {
        if (domNode.type === ElementType.Text) {
            return undefined;
        }
        if (!((domNode as Element).tagName === 'li')) {
            return undefined;
        }

        const response = new TreeNodeElement();

        response.onClickExtended = (evt) => props.HandleNodeClickEvents && props.HandleNodeClickEvents(evt, domNode, i, nodeCountAtIndex);
        response.hasNextSibling = TreeUtilities.hasLIGotNextSiblings(domNode as Element, i);
        response.hasSelection = TreeUtilities.hasLIGotSelection(domNode as Element);
        response.hasSeeMore = TreeUtilities.hasLIGotSeeMore(domNode as Element, i, nodeCountAtIndex, hasMoreThresholdCount);
        response.hasContractibility = TreeUtilities.hasLIGotContractibility(domNode as Element, i, nodeCountAtIndex, hasMoreThresholdCount);

        return response;
    };

    const getListItemId = (element: Element) => {
        return element.attribs['data-id'] ?? '';
    };

    const replaceMobileEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number,
        parentListItemId: string,
        isRoot: boolean = false,
        isSoleRoot: boolean = false,
        listItemShowingCallback: (isListItemShowing: boolean) => void = undefined,
        isAnyChildrenListItemsShowingCallback: (isAnyChildrenListItemsShowing: boolean) => void = undefined,
    ) => {
        if (domNode.type === ElementType.Text) {
            return <div className="content">{(domNode as Text).data?.trim()}</div>;
        } else if (domNode.type === ElementType.Tag) {
            let element = domNode as Element;
            if (element.name === 'li') {
                let conductResponse = getMobileNodePositionalProperties(domNode, depth, index);

                let skipLi =
                    props.SkipLiMobile &&
                    props.SkipLiMobile(
                        conductResponse,
                        depth,
                        index,
                        parentIndex,
                        isRoot,
                        getListItemId(element),
                        selectedNodeDetails,
                        selectedNodeParentDetails,
                    );

                listItemShowingCallback(!skipLi);

                ++nodeCount;
                const nodeCountOnIndex = TreeUtilities.getNodeCountAtIndex(domNode, index);
                let navTreeElement = getLiAttributes(domNode, index, nodeCountOnIndex);
                let className = '';
                let contracted = !isRoot && isContracted(depth, parentIndex);
                let hasSameLevelOrDescendancy = conductResponse.isSibling || conductResponse.isDescendant || conductResponse.isSelectedItem;
                let hasTogglability = false;
                if (navTreeElement.hasSelection) {
                    className = 'has-selection';
                }

                let displayChildren = areChildrenMobileViewDisplayable(depth, conductResponse);

                const liChildren =
                    !skipLi &&
                    domToReact(domNode.children as DOMNode[], {
                        replace: (dN) =>
                            replaceMobileEmbeddedObjects(
                                dN,
                                depth,
                                index,
                                index,
                                getListItemId(element),
                                isRoot,
                                null,
                                null,
                                (isAnyChildrenListItemsShowing) => {
                                    if (
                                        dN.type === ElementType.Tag &&
                                        (dN as Element).tagName === 'ul' &&
                                        displayChildren &&
                                        !isAnyChildrenListItemsShowing
                                    ) {
                                        displayChildren = false;
                                    }
                                },
                            ),
                    });

                if (hasSameLevelOrDescendancy) {
                    if (navTreeElement.hasNextSibling) {
                        className += ' has-next-sibling';
                        hasTogglability = true;
                    }
                    if (navTreeElement.hasContractibility) {
                        className += ' has-contractibility' + (!contracted ? ' force-show' : '');
                        hasTogglability = true;
                    }
                    if (!navTreeElement.hasNextSibling && !navTreeElement.hasContractibility && displayChildren) {
                        className += ' is-sole-parent';
                    }
                } else {
                    className += ' is-sole-parent';
                }
                if (isRoot && isSoleRoot) {
                    className += ' sole-root';
                }

                className += element.attribs['class'] ? ` ${element.attribs['class']}` : '';

                return (
                    (!skipLi && (
                        <>
                            <li
                                {...element.attribs}
                                className={className}
                                onClick={(evt) => {
                                    navTreeElement.onClickExtended && navTreeElement.onClickExtended(evt);
                                }}
                                key={nodeCount}
                                data-id={getListItemId(element)}
                            >
                                {liChildren}
                            </li>
                            {hasTogglability && navTreeElement.hasSeeMore && (
                                <li
                                    onClick={(evt) => seeMoreOnClick(evt, depth, parentIndex)}
                                    className={'has-see-more' + (!contracted ? ' expanded' : '')}
                                >
                                    <div className="see-more">
                                        <ComButton variant="link">
                                            <span>See more...</span>
                                        </ComButton>
                                    </div>
                                </li>
                            )}
                            {hasTogglability && navTreeElement.hasContractibility && !navTreeElement.hasNextSibling && (
                                <li
                                    onClick={(evt) => seeLessOnClick(evt, depth, parentIndex)}
                                    className={'has-see-less' + (!contracted ? ' expanded' : '')}
                                >
                                    <div className="see-less">
                                        <ComButton variant="link">
                                            <span>See less...</span>
                                        </ComButton>
                                    </div>
                                </li>
                            )}
                        </>
                    )) ||
                    (skipLi && <></>)
                );
            } else if (element.name === 'ul') {
                return replaceMobileULEmbeddedObjects(element, depth, parentIndex, parentListItemId, (isAnyChildrenListItemsShowing) => {
                    isAnyChildrenListItemsShowingCallback(isAnyChildrenListItemsShowing);
                });
            } else {
                if (element.tagName === 'div' && element.attribs['class']?.indexOf('icon-click-overlay') > -1) {
                    return (
                        <div
                            {...element.attribs}
                            onClick={(evt) => props.HandleListItemIconOnClick && props.HandleListItemIconOnClick(evt, parentListItemId)}
                        ></div>
                    );
                } else if (props.ReplaceMobileEmbeddedObjects) {
                    const replace = props.ReplaceMobileEmbeddedObjects(
                        domNode,
                        depth,
                        index,
                        parentIndex,
                        parentListItemId,
                        isRoot,
                        isSoleRoot,
                        replaceMobileEmbeddedObjects,
                    );
                    if (replace) {
                        return replace;
                    }
                }
            }
        }
    };

    const replaceEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number = null,
        parentListItemId: string = null,
        isRoot: boolean = false,
        listItemShowingCallback: (isListItemShowing: boolean) => void = undefined,
        isAnyChildrenListItemsShowingCallback: (isAnyChildrenListItemsShowing: boolean) => void = undefined,
    ) => {
        if (domNode.type === ElementType.Text) {
            return (domNode as Text).data?.trim().length > 0 && <div className="content">{(domNode as Text).data?.trim()}</div>;
        } else if (domNode.type === ElementType.Tag) {
            let element = domNode as Element;
            if (element.name === 'li') {
                ++nodeCount;
                const nodeCountOnIndex = TreeUtilities.getNodeCountAtIndex(domNode, index);
                let navTreeElement = getLiAttributes(domNode, index, nodeCountOnIndex);
                let className = '';
                let contracted = isContracted(depth, parentIndex);
                let conductResponse = getNodePositionalProperties(domNode, depth, index);
                let _skipLi =
                    props.SkipLi && props.SkipLi(conductResponse, depth, index, parentIndex, isRoot, getListItemId(element), selectedNodeDetails);

                listItemShowingCallback(!_skipLi);

                let hasSameLevelOrDescendancy = conductResponse.isSibling || conductResponse.isDescendant || conductResponse.isSelectedItem;
                let hasTogglability = false;
                let isSoleRoot = isRoot && conductResponse.isSelectedItem;

                if (navTreeElement.hasSelection) {
                    className = 'has-selection';
                }

                let displayChildren = areChildrenViewDisplayable(depth, conductResponse);

                const liChildren =
                    !_skipLi &&
                    domToReact(domNode.children as DOMNode[], {
                        replace: (dN) =>
                            replaceEmbeddedObjects(dN, depth, index, index, getListItemId(element), null, null, (isAnyChildrenListItems) => {
                                if (dN.type === ElementType.Tag && (dN as Element).tagName === 'ul' && displayChildren && !isAnyChildrenListItems) {
                                    displayChildren = false;
                                }
                            }),
                    });

                if (hasSameLevelOrDescendancy) {
                    if (navTreeElement.hasNextSibling) {
                        className += ' has-next-sibling';
                        hasTogglability = true;
                    }
                    if (navTreeElement.hasContractibility) {
                        className += ' has-contractibility' + (!contracted ? ' force-show' : '');
                        hasTogglability = true;
                    }
                    if (!navTreeElement.hasNextSibling && !navTreeElement.hasContractibility && displayChildren) {
                        className += ' is-sole-parent';
                    }
                } else {
                    className += ' is-sole-parent';
                }
                if ((isRoot && getLiCountForUl(domNode.parent as DOMNode) === 1) || isSoleRoot) {
                    className += ' sole-root';
                }

                className += element.attribs['class'] ? ` ${element.attribs['class']}` : '';

                return (
                    (!_skipLi && (
                        <>
                            <li
                                {...element.attribs}
                                className={className}
                                onClick={(evt) => {
                                    navTreeElement.onClickExtended && navTreeElement.onClickExtended(evt);
                                }}
                                key={nodeCount}
                                data-id={getListItemId(element)}
                            >
                                {liChildren}
                            </li>
                            {hasTogglability && navTreeElement.hasSeeMore && (
                                <li
                                    onClick={(evt) => seeMoreOnClick(evt, depth, parentIndex)}
                                    className={'has-see-more' + (!contracted ? ' expanded' : '')}
                                >
                                    <div className="see-more">
                                        <ComButton variant="link">
                                            <span>See more...</span>
                                        </ComButton>
                                    </div>
                                </li>
                            )}
                            {hasTogglability && navTreeElement.hasContractibility && !navTreeElement.hasNextSibling && (
                                <li
                                    onClick={(evt) => seeLessOnClick(evt, depth, parentIndex)}
                                    className={'has-see-less' + (!contracted ? ' expanded' : '')}
                                >
                                    <div className="see-less">
                                        <ComButton variant="link">
                                            <span>See less...</span>
                                        </ComButton>
                                    </div>
                                </li>
                            )}
                        </>
                    )) ||
                    (_skipLi && <></>)
                );
            } else if (element.name === 'ul') {
                return replaceULEmbeddedObjects(element, depth, parentIndex, parentListItemId, false, (isAnyChildrenListItemsShowing) => {
                    isAnyChildrenListItemsShowingCallback(isAnyChildrenListItemsShowing);
                });
            } else {
                if (element.tagName === 'div' && element.attribs['class']?.indexOf('icon-click-overlay') > -1) {
                    return (
                        <div
                            {...element.attribs}
                            onClick={(evt) => props.HandleListItemIconOnClick && props.HandleListItemIconOnClick(evt, parentListItemId)}
                        ></div>
                    );
                } else if (props.ReplaceEmbeddedObjects) {
                    const replace = props.ReplaceEmbeddedObjects(
                        domNode,
                        depth,
                        index,
                        parentIndex,
                        parentListItemId,
                        isRoot,
                        replaceEmbeddedObjects,
                    ) as JSX.Element;
                    if (replace) {
                        return replace;
                    }
                }
            }
        }
    };

    const findCommonAncestorWithSelectedNode = (domNode: DOMNode, depth: number, index: number) => {
        let temp = new NodeDetails(domNode, depth, index);
        while (temp) {
            let match = findCommonAncestor(temp, selectedNodeDetails);
            if (match) {
                return match;
            }
            const indexes = (temp.DomNode as Element).attribs['index'].split('.');
            let parentIndexIndex = indexes.length - 2;
            let parentIndex = parentIndexIndex > -1 && parseInt(indexes[parentIndexIndex]);
            temp = temp.DomNode.parent?.parent ? new NodeDetails(temp.DomNode.parent.parent as DOMNode, temp.Depth - 1, parentIndex) : null;
        }

        return null;
    };

    const liHasChildren = (domNode: DOMNode) => {
        let found = false;
        (domNode as Element).children.forEach((c) => {
            if (!found) {
                found = c.type === ElementType.Tag && (c as Element).name === 'ul';
            }
        });
        return found;
    };

    const findCommonAncestor = (left: NodeDetails, right: NodeDetails) => {
        let temp = right;
        let match: NodeDetails = null;
        while (temp) {
            if (left.Depth === temp.Depth && left.Index === temp.Index) {
                match = temp;
                break;
            } else {
                if (temp.DomNode.parent.parent) {
                    const indexes = (temp.DomNode as Element).attribs['index'].split('.');
                    let parentIndexIndex = indexes.length - 2;
                    let parentIndex = parentIndexIndex > -1 && parseInt(indexes[parentIndexIndex]);
                    temp = new NodeDetails(temp.DomNode.parent.parent as DOMNode, temp.Depth - 1, parentIndex);
                } else {
                    temp = null;
                }
            }
        }

        return match;
    };

    const replaceMobileULEmbeddedObjects = (
        domNode: Element,
        depth: number,
        parentIndex: number,
        parentListItemId: string,
        isAnyChildrenListItemsShowingCallback: (isAnyChildrenListItemsShowing: boolean) => void,
    ) => {
        const newDepth = depth + 1;

        const skipUl =
            props.SkipListMobile && props.SkipListMobile(newDepth, parentIndex, parentListItemId, selectedNodeDetails, selectedNodeParentDetails);

        let isAnyChildrenListItemsShowing = false;

        const response =
            (!skipUl && (
                <ul className="sub-tree">
                    {(domNode as Element).children.map((c, i) => {
                        return replaceMobileEmbeddedObjects(
                            c as DOMNode,
                            newDepth,
                            i,
                            parentIndex,
                            parentListItemId,
                            null,
                            null,
                            (isListItemShowing) => {
                                if (!isAnyChildrenListItemsShowing && isListItemShowing) {
                                    isAnyChildrenListItemsShowing = true;
                                }
                            },
                        ) as JSX.Element;
                    })}
                </ul>
            )) ||
            (skipUl && <></>);

        isAnyChildrenListItemsShowingCallback(isAnyChildrenListItemsShowing);

        return response;
    };

    const replaceULEmbeddedObjects = (
        domNode: Element,
        depth: number,
        parentIndex: number,
        parentListItemId: string,
        isRoot: boolean = false,
        isAnyChildrenListItemsShowingCallback: (isAnyChildrenListItemsShowing: boolean) => void = undefined,
    ) => {
        const rootClass: string = isRoot ? 'tree tree-root' : 'sub-tree';
        const newDepth = depth + 1;

        let skipUl = props.SkipList && props.SkipList(newDepth, parentIndex, parentListItemId, selectedNodeDetails, selectedNodeParentDetails);

        let isAnyChildrenListItemsShowing = false;

        const response =
            (!skipUl && (
                <ul className={rootClass}>
                    {(domNode as Element).children.map((c, i) => {
                        return replaceEmbeddedObjects(c as DOMNode, depth + 1, i, parentIndex, parentListItemId, null, (isListItemShowing) => {
                            if (!isAnyChildrenListItemsShowing && isListItemShowing) {
                                isAnyChildrenListItemsShowing = true;
                            }
                        }) as JSX.Element;
                    })}
                </ul>
            )) ||
            (skipUl && <></>);

        isAnyChildrenListItemsShowingCallback(isAnyChildrenListItemsShowing);

        return response;
    };
    //#endregion

    return (
        <div className="tree-container">
            <ul
                className={
                    'tree tree-root' +
                    (!isMobile && !isTablet ? ' tree-desktop' : '') +
                    (isMobile ? ' tree-mobile' : '') +
                    (isTablet ? ' tree-tablet' : '')
                }
            >
                {!isMobile &&
                    !isTablet &&
                    tree &&
                    tree.children.map((c, i) => replaceEmbeddedObjects(c as DOMNode, rootDepth, i, null, null, true, () => undefined))}
                {isTablet &&
                    tabletStartingNodeDetails &&
                    (() =>
                        replaceMobileEmbeddedObjects(
                            tabletStartingNodeDetails.DomNode,
                            tabletStartingNodeDetails.Depth,
                            tabletStartingNodeDetails.Index,
                            null,
                            null,
                            true,
                            true,
                            () => undefined,
                        ))()}
                {isMobile &&
                    mobileStartingNodeDetails &&
                    (() =>
                        replaceMobileEmbeddedObjects(
                            mobileStartingNodeDetails.DomNode,
                            mobileStartingNodeDetails.Depth,
                            mobileStartingNodeDetails.Index,
                            null,
                            null,
                            true,
                            true,
                            () => undefined,
                        ))()}
            </ul>
        </div>
    );
};

export default Tree;
