import React, { useContext, useEffect, useRef, useState } from 'react';
import './Video.scss';
import x2js from 'x2js';
import YouTubePlayer from 'react-player/youtube';
import VideoTranscript from './videoTranscript/videoTranscript';
import { strPadLeft, validatePersonalisation } from '../../utils/utils';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { ContentItem } from '@exporter-services/common-ui';

interface VideoProps {
    data: {
        item: ContentItem;
    };
}

const Video = (props: VideoProps) => {
    const [isTransScript, setIsTransScript] = useState<boolean>(false);
    const [videoUrl, setvideoUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [transcriptData, setTranscriptData] = useState<any>(null);
    const [duration, setDuration] = useState<any>(0);
    const [playing, setPlaying] = useState<boolean>(false);
    const [seeking] = useState<boolean>(false);
    const [playedSeconds, setPlayedSecons] = useState(null);
    const refVideo = useRef<YouTubePlayer | null>(null);
    const { personalisationState } = useContext(personalisationContext);

    let player: any = refVideo;

    const xmlToJson = new x2js();

    useEffect(() => {
        updateVideoData();
    }, [props.data.item.elements.video_id.value]);

    const updateVideoData = () => {
        const url: string = `https://www.youtube.com/watch?v=${props.data.item.elements.video_id.value}`;
        const defaultImgUrl: string = `https://img.youtube.com/vi/${props.data.item.elements.video_id.value}/0.jpg`;

        setvideoUrl(url);
        setImageUrl(defaultImgUrl);

        fetchTranscriptData().then((res) => {
            if (res) {
                setTranscriptData(res);
            } else {
                setTranscriptData(null);
            }
        });
    };

    const fetchTranscriptData = (): Promise<object | boolean> => {
        setTranscriptData('loading');
        const parseJson = (json) => {
            const result = [];
            json.forEach((j) => {
                result.push({
                    start: Number(j._start),
                    dur: Number(j._dur),
                    text: j.__text.replace(/&#39;/gim, "'"),
                });
            });
            return result;
        };
        const transcriptUrl = `https://video.google.com/timedtext?lang=en-GB&v=${props.data.item.elements.video_id.value}`;
        return fetch(transcriptUrl)
            .then((res) => res.text())
            .then((xml) => {
                if (xml) {
                    return xmlToJson.xml2js(xml);
                } else {
                    return fetch(`https://video.google.com/timedtext?lang=en&v=${props.data.item.elements.video_id.value}`)
                        .then((res) => res.text())
                        .then((xml) => xmlToJson.xml2js(xml));
                }
            })
            .then((json) => parseJson(json['transcript']['text']))
            .catch(() => {
                return false;
            });
    };

    const toggleTranscript = (): void => {
        setIsTransScript(!isTransScript);
    };

    const onDuration = (duration: number): void => {
        let minutes: number = Math.floor(duration / 60);
        let seconds: number = duration - minutes * 60;
        let finalTime: string = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);
        setDuration(finalTime);
    };

    const onPlay = (): void => {
        setPlaying(true);
    };

    const onEnded = (): void => {
        setPlaying(false);
    };

    const onProgress = (state): void => {
        if (!seeking) {
            setPlayedSecons(state.playedSeconds);
        }
    };

    const transcriptClickHandler = (time: number): void => {
        const seekTime: number = time < 1 ? 0 : time;
        player.current.seekTo(seekTime);
        setPlaying(true);
    };

    const clickHandler = () => {
        setPlaying(true);
    };

    const getTranscriptTitle = () => {
        return isTransScript ? 'Close transcript' : 'Open transcript';
    };

    const renderTranscript = () =>
        transcriptData && transcriptData !== 'loading' ? (
            <div>
                <div className="video-player-transcript-toggle" style={{ display: transcriptData ? 'flex' : 'none' }}>
                    <button aria-label={isTransScript ? 'Open Transcript' : 'Close Transcript'} onClick={(e) => toggleTranscript()}>
                        <span>{getTranscriptTitle()}</span>
                    </button>
                </div>
                <div
                    className="video-player-transcript"
                    style={{
                        height: isTransScript ? '19.5rem' : '0',
                        borderBottom: isTransScript ? '0.125rem solid #d8d8d8' : 'none',
                    }}
                >
                    <VideoTranscript
                        style={{
                            backgroundColor: '$grey-92',
                            visibility: isTransScript ? 'visible' : 'hidden',
                            transition: isTransScript ? '' : 'visibility 0.5s 1s',
                        }}
                        transcriptClickHandler={transcriptClickHandler}
                        playedSeconds={playedSeconds}
                        transcriptData={transcriptData}
                    />
                </div>
            </div>
        ) : null;

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <div className="video-player" data-testid="video-player">
                    <div
                        className="video-player-player"
                        onClick={clickHandler}
                        style={{
                            backgroundImage: imageUrl
                                ? `url(${imageUrl})`
                                : 'https://img.youtube.com/vi/' + props.data.item.elements.video_id.value + '/maxresdefault.jpg',
                        }}
                    >
                        <button
                            aria-label={`Play video ${props.data.item.elements.title.value}`}
                            className="video-player-button"
                            onClick={clickHandler}
                            style={{
                                visibility: !playing ? 'visible' : 'hidden',
                            }}
                        ></button>
                        <YouTubePlayer
                            className="video-player-player-youtube"
                            style={{
                                visibility: playing ? 'visible' : 'hidden',
                            }}
                            config={{
                                playerVars: {
                                    color: 'white',
                                    showinfo: 0,
                                    modestbranding: 1,
                                    controls: 1,
                                    enablejsapi: 1,
                                    rel: 0,
                                },
                            }}
                            width="100%"
                            height="100%"
                            playing={playing}
                            ref={refVideo}
                            url={videoUrl}
                            onSeek={() => null}
                            controls
                            onPlay={onPlay}
                            onEnded={onEnded}
                            onDuration={onDuration}
                            onProgress={onProgress}
                        />
                    </div>
                    {renderTranscript()}
                    <div className="video-player-info">
                        <h3 className="video-player-info-title" dangerouslySetInnerHTML={{ __html: props.data.item.elements.title.value }} />
                        <p aria-label="video duration" className="video-player-info-date">
                            {' '}
                            <span>Time: {duration}</span>
                        </p>
                        <p aria-label="video info" className="video-player-info-detail" />
                        <ResolveLink data={{ item: props.data.item }} type={props.data.item.system.type} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Video;
