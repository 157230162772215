import React, { useEffect, useState } from 'react';
import { FilterHeader, FilterItem, FilterRadiobutton } from '.';
import { ComMultiSelectUIItem } from '../../generic/formControls/ComMultiSelectUIItem';
import './FilterRadiobuttonPanel.scss';

export interface FilterRadiobuttonPanelProps {
    data?: {
        id?: string;
        controlWidth?: string;
        label?: string;
        itemList?: FilterItem[];
        clearState: boolean;
        defaultState: string;
    };
    name?: string;
    onChange?: Function;
}

export const FilterRadiobuttonPanel = (props: FilterRadiobuttonPanelProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const [selection, setSelection] = useState<string>(props.data.defaultState);

    const comMultiSelectUIItemRemove = (itemValue: string, itemText: string) => {
        setSelection(props.data.defaultState);

        if (props.onChange) {
            props.onChange(itemValue);
        }
    };

    useEffect(() => {
        if (props.data.clearState) {
            setSelection(props.data.defaultState);
        }
    }, [props.data.clearState]);

    const onRadiobuttonSelection = (value: string) => {
        setSelection(value);
        props.onChange && props.onChange(value);
    };

    const headerOnClick = (open: boolean) => {
        setOpen(open);
    };

    return (
        <div className="com-filter-radiobutton-panel">
            <FilterHeader data={{ label: props.data.label, open: open }} onClick={headerOnClick} />

            <div className="com-filter-radiobutton-selection">
                {props.data.itemList &&
                    props.data.itemList.map(
                        (item) =>
                            item.value === selection && (
                                <ComMultiSelectUIItem
                                    key={`check-item-${item.value}`}
                                    data={{ value: item.value, text: item.value }}
                                    removeItemHandler={comMultiSelectUIItemRemove}
                                />
                            ),
                    )}
            </div>
            <div style={{ display: open ? 'block' : 'none' }}>
                {props.data.itemList &&
                    props.data.itemList.map((item: FilterItem, index) => (
                        <FilterRadiobutton
                            key={index}
                            data={{
                                id: item.value,
                                label: item.label,
                                value: item.value,
                                count: item.count,
                                selection: selection,
                                index: index,
                            }}
                            name={props.name}
                            onChange={onRadiobuttonSelection}
                        />
                    ))}
            </div>
        </div>
    );
};
