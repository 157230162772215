import React from 'react';
import { Col, Container } from 'reactstrap';
import { Elements } from '@kontent-ai/delivery-sdk';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import EbgaHead from '../EbgaHead';
import { formatDate } from '../../utils/utils';
import { getPageHeaderBackgroundStyling } from './PageHeaderCommon';

export interface NewsArticlePageHeaderProps {
    data: {
        type: any;
        title: any;
        title_tag?: string;
        titleNumber?: Elements.NumberElement;
        description: any;
        metaDescription?: string;
        image_or_video: any;
        label?: string;
        lastUpdated_at?: Date;
        urlSlug?: Elements.UrlSlugElement;
        backgroundColorTheme?: Elements.MultipleChoiceElement;
    };
}

const NewsArticlePageHeader = (props: NewsArticlePageHeaderProps) => {
    const [loading] = useState<boolean>(false);

    const showAriaInfo = () => !!props.data.label;

    const getPageTitle = () => {
        return props.data.title;
    };

    const getDescription = () => {
        return props.data.description;
    };

    const getMetaDescription = () => {
        return getDescription().replace(/<\/?[^>]+(>|$)/g, '');
    };

    const getStandardH1 = (showAria: boolean, testId: string, cssClass?: string) => (
        <h1 id="pageTitle" className={`${!!cssClass ? `${cssClass}` : ''}`} aria-hidden={!showAria} data-testid={testId}>
            {props.data.titleNumber && props.data.titleNumber.value && props.data.titleNumber.value + '.'} {getPageTitle()}
        </h1>
    );

    const imageOrVideo = props.data.image_or_video?.linkedItems;

    return (
        <>
            <EbgaHead
                metaDescription={props.data.metaDescription}
                abstract={getMetaDescription()}
                pageTitle={getPageTitle()}
                titleTag={props.data.title_tag}
            />
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <Container
                    fluid
                    className={`page-header news-article-page-header ${getPageHeaderBackgroundStyling(props.data.backgroundColorTheme)}`}
                    id="page-header"
                >
                    <Container>
                        <div className="content-header">
                            {imageOrVideo?.length > 0 ? (
                                <>
                                    <Col className="d-flex">
                                        <div className="component-content p-md-5 p-4">
                                            {showAriaInfo() && (
                                                <>
                                                    {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                                    <div className="header-label">{props.data.label}</div>
                                                </>
                                            )}
                                            {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                            {props.data.lastUpdated_at && (
                                                <p data-testid="last-updated" className="last-updated">
                                                    Last updated: {formatDate(props.data.lastUpdated_at)}
                                                </p>
                                            )}
                                            <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                        </div>
                                        <div className="component-image">
                                            {imageOrVideo[0].elements.image.value.length > 0 && (
                                                <img src={imageOrVideo[0].elements.image.value[0].url} alt="" />
                                            )}
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                <Col xl={8} md={10} sm={12} className="p-md-5 p-4">
                                    {showAriaInfo() && (
                                        <>
                                            {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                            <div className="header-label">{props.data.label}</div>
                                        </>
                                    )}
                                    {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                                    {props.data.lastUpdated_at && (
                                        <p data-testid="last-updated" className="last-updated">
                                            Last updated: {formatDate(props.data.lastUpdated_at)}
                                        </p>
                                    )}
                                    <div className="header-intro" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                                </Col>
                            )}
                        </div>
                    </Container>
                </Container>
            </LoadingOverlay>
        </>
    );
};
export default NewsArticlePageHeader;
