import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import './CommoditiesSearch.scss';
import { Col, Row } from 'reactstrap';
import { ReactSelectOption } from '../../models/ReactSelectOption';
import { debounce } from 'lodash';
import { CommoditySearchItem } from './CommoditySearchItem';
import { ContentItem } from '@exporter-services/common-ui';
import parse from 'html-react-parser';
import * as ReactGA from 'react-ga';
import { CommonConstants } from '../../CommonConstants';
import { CommodityService } from '../../services/CommodityService';
import { getCommodities, getCommoditiesWithStrongMarkets } from '../../providers/reducers/commoditySlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { CommoditiesInfo } from '../../models/ReduxModels';

export interface Commodity {
    Code: string;
    Label: string;
    Children?: Commodity[];
}

const convertToCommodityOption = (option: ReactSelectOption): Commodity => {
    if (option) {
        return { Code: option.value, Label: option.label };
    }
    return null;
};

const convertToReactSelectOption = (option: Commodity): ReactSelectOption => {
    if (option) {
        return { value: option.Code, label: option.Label };
    }
    return null;
};

export interface CommoditiesSearchProps {
    item: ContentItem;
    searchItem: CommoditySearchItem;
    PnPFilter: boolean;
    industryCode: string;
    commodityCode: string;
    onClick?: (selectedOption: Commodity) => void;
    clearSelection?: boolean;
    isMarketSearch?: boolean;
    isExportRules?: boolean;
    isTariffFinder?: boolean;
    ariaLabel?: string;
    onFocus?: Function;
}

const CommoditiesSearch = (props: CommoditiesSearchProps) => {
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState<Commodity>();
    let defaultOptions: any = null;
    let commodityService = new CommodityService();
    const dispatch = useAppDispatch();
    const commoditySearchData = useAppSelector<CommoditiesInfo>((state) => state.commodity.commoditiesInfo);

    useEffect(() => {
        if (props.industryCode && props.industryCode.toLowerCase() === CommonConstants.PRODUCTS_CODENAME && props.commodityCode) {
            loadOptionsByQueryParams(props.commodityCode);
        }
        if (props.clearSelection) setSelectedValue(null);
    }, [props.clearSelection]);

    useEffect(() => {
        let result = commoditySearchData;

        if (result?.data?.length > 0) {
            defaultOptions = result.data;

            const matchingRecord = result.data.find((v) => v.Code === props.commodityCode);
            if (matchingRecord) {
                setSelectedValue(matchingRecord); // set the selectedValue for AsyncSelect
                props.onClick && props.onClick(matchingRecord);
            }
        }
    }, [commoditySearchData]);

    const loadOptionsByQueryParams = (inputValue: string) => {
        if (props.commodityCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            const notListedCommodity: Commodity = {
                Code: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                Label: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL,
                Children: [],
            };
            setSelectedValue(notListedCommodity);
            return;
        }

        let queryString = '?query=' + inputValue;
        if (props.isExportRules || props.isTariffFinder) {
            dispatch(getCommodities({ queryString: queryString }));
        } else {
            dispatch(getCommoditiesWithStrongMarkets({ queryString: queryString }));
        }
    };

    const handleChange = (selectedOption: ReactSelectOption) => {
        setSearchText('');
        setSelectedValue(convertToCommodityOption(selectedOption));
        props.onClick && props.onClick(convertToCommodityOption(selectedOption));
    };

    const loadOptions = debounce((inputValue: string, callback: any) => {
        setSearchText(inputValue);
        inputValue = inputValue.replaceAll(/[^a-z0-9, -]/gi, ' ');
        inputValue = inputValue.replaceAll(/^\s+|\s+$|\s+(?=\s)/g, ''); // remove all white space occurences (beginning and end)

        if (!inputValue) {
            callback(
                [
                    {
                        Code: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                        Label: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL,
                    },
                ].map((c) => convertToReactSelectOption(c)),
            );
            return;
        }

        let codename: string = '';
        if (props.searchItem.elements.type && props.searchItem.elements.type.value.length > 0) {
            codename = props.searchItem.elements.type.value[0].codename.toLowerCase();
        }

        let queryString = '?query=' + inputValue;
        if (props.isTariffFinder) {
            queryString = queryString + '&headingsOnly=true';
        }

        let promiseResult: Promise<any> = null;
        if (codename === 'laws_and_regs') {
            ReactGA.event({
                category: 'LR Food and agribusiness',
                action: 'Enters search term',
                label: inputValue,
            });
            promiseResult = commodityService.getCommodities(queryString);
        } else if (codename === 'strong_market') {
            ReactGA.event({
                category: 'MS Food and agribusiness',
                action: 'Enters search term',
                label: inputValue,
            });
            promiseResult = commodityService.getCommoditiesWithStrongMarkets(queryString);
        } else {
            promiseResult = commodityService.getCommodities(queryString);
        }

        promiseResult.then((data) => {
            data.value = data.value ?? data;
            data.value.push({
                Code: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                Label: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL,
            });
            callback(data.value.map((c) => convertToReactSelectOption(c)));
        });
    }, 1000);

    const formOptionHandler = (e) => {
        if (e?.label) {
            return parse(searchText ? e.label.replace(new RegExp(`(${searchText})`, 'gi'), '<strong class="highlight">$1</strong>') : e.label);
        }
    };

    const onFocusDropdown = () => {
        props.onFocus && props.onFocus();
    };

    return (
        <div
            className={`commoditiessearch-ahecc-container ${props.PnPFilter ? 'commoditiessearch-pnp-Container' : ''} ${
                props.isExportRules || props.isTariffFinder ? 'commoditiessearch-lawsnregs-Container' : ''
            } ${props.isMarketSearch ? 'commoditiessearch-marketsearch-Container' : ''} `}
        >
            <Row>
                <Col>
                    <AsyncSelect
                        inputId="commodities-search"
                        placeholder=""
                        getOptionValue={(e) => e.Code}
                        onChange={handleChange}
                        loadOptions={loadOptions}
                        isClearable
                        isSearchable
                        styles={{
                            singleValue: () => ({}),
                        }}
                        classNamePrefix="commodity-search"
                        value={convertToReactSelectOption(selectedValue)}
                        noOptionsMessage={() => null}
                        formatOptionLabel={formOptionHandler}
                        defaultOptions={defaultOptions?.map((c) => convertToReactSelectOption(c))}
                        aria-label={props.ariaLabel}
                        onFocus={onFocusDropdown}
                    />
                </Col>
            </Row>
        </div>
    );
};
export default CommoditiesSearch;
