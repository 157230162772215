import React from 'react';
import { Col, Container } from 'reactstrap';
import { Elements } from '@kontent-ai/delivery-sdk';
import ComponentResolver from '../../ComponentResolver';
import { PageItem } from '../../models/PageItem';
import EbgaHead from '../EbgaHead';
import { getPageHeaderBackgroundStyling } from './PageHeaderCommon';

interface FilteredSearchtoolPageHeaderProps {
    data: {
        item: PageItem;
        backgroundColorTheme?: Elements.MultipleChoiceElement;
    };
    match?: any;
}

const FilteredSearchtoolPageHeader = (props: FilteredSearchtoolPageHeaderProps) => {
    const item = props.data.item;

    const showAriaInfo = () => item && getLabelInformation();

    const getPageTitle = () => {
        return item.elements.title.value;
    };

    const getAbstract = () => {
        return item.elements.abstract.value;
    };

    const getMetaDescription = () => {
        return getAbstract().replace(/<\/?[^>]+(>|$)/g, '');
    };

    const getLabelInformation = () => {
        return item.elements.label.value;
    };

    const getStandardH1 = (showAria: boolean, testId: string, cssClass?: string) => (
        <h1 id="pageTitle" className={`${!!cssClass ? `${cssClass}` : ''}`} aria-hidden={!showAria} data-testid={testId}>
            {getPageTitle()}
        </h1>
    );

    return (
        <>
            <EbgaHead
                metaDescription={item.elements.common_page_info__meta_description_tag.value as string}
                abstract={getMetaDescription()}
                pageTitle={getPageTitle()}
                titleTag={item.elements.common_page_info__title_tag.value as string}
            />
            <Container fluid className={`page-header ${getPageHeaderBackgroundStyling(props.data.backgroundColorTheme)}`} id="page-header">
                <Container>
                    <div className="content-header">
                        <Col xl={8} md={10} sm={12} className="p-md-5 p-4">
                            {showAriaInfo() && (
                                <>
                                    {getStandardH1(true, 'screen-reader-h1', 'sr-only')}
                                    <div className="header-label">{getLabelInformation()}</div>
                                </>
                            )}
                            {getStandardH1(!showAriaInfo(), 'displayable-h1')}
                            <div className="header-intro" dangerouslySetInnerHTML={{ __html: getAbstract() }} />
                            <div>
                                {item.elements.abstract_content?.value.map((ac) => (
                                    <ComponentResolver
                                        data={{ item: ac, linkedItems: {} }}
                                        key={ac.system.id}
                                        match={props.match}
                                        type={
                                            props.data.item.elements.market_multiple_choice &&
                                            props.data.item.elements.market_multiple_choice.value[0].codename
                                        }
                                    />
                                ))}
                            </div>
                        </Col>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default FilteredSearchtoolPageHeader;
