import React, { useEffect, useState } from 'react';
import { client } from '../../config';
import BodyCopy from '../bodyCopy/BodyCopy';
import { BodyCopyItem } from '../bodyCopy/BodyCopyItem';
import { InlinePathway, InlinePathwayItem } from '@exporter-services/common-ui/src/index';
import { ContentItem } from '@exporter-services/common-ui';
import { filterContentItemsAgainstUrlParams, formatDate } from '../../utils/utils';
import { CommonConstants } from '../../CommonConstants';
import { useUrlParams } from '../../hooks/useUrlParams';

interface CompetitiveEnvironmentProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const CompetitiveEnvironment = (props: CompetitiveEnvironmentProps) => {
    const urlCodes = useUrlParams(props.match);
    const [content, setContent] = useState(null);
    const [linkedItems, setLinkedItems] = useState(null);
    const [noContentItem, setNoContentItem] = useState<ContentItem>(null);

    const market = props.match.params.market.toUpperCase();

    useEffect(() => {
        if (props.type === 'market') {
            getCEForMarket();
        } else {
            getCEForStrongMarket();
        }
    }, []);

    useEffect(() => {
        if (urlCodes) {
            getNoContentItem();
        }
    }, [urlCodes]);

    const getCEForMarket = () => {
        const industry = props.type === 'market' ? props.match.params.sector.replace(/-/g, '_') : props.match.params.industry.replace(/-/g, '_');
        client
            .items()
            .equalsFilter('system.type', 'competitive_environments')
            .anyFilter('elements.industry_sector', [industry, CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                setContent(response.data.items[0]);
                setLinkedItems(response.data.linkedItems);
                props.onLoad && props.onLoad(true, 'Competitive environment');
            });
    };

    const getCEForStrongMarket = () => {
        const sector = props.match.params.sector.toUpperCase();
        client
            .items()
            .equalsFilter('system.type', 'competitive_environments')
            .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
            .anyFilter('elements.hscodesnippet__hscode', ['n' + sector, CommonConstants.KONTENT_HSCODES_ALL_CODENAME])
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                setContent(response.data.items[0]);
                setLinkedItems(response.data.linkedItems);
                props.onLoad && props.onLoad(true, 'Competitive environment');
            });
    };

    const getNoContentItem = () => {
        const linkedItems = props.data.item.elements['competitive_environment_no_content_item']?.linkedItems;
        if (linkedItems?.length) {
            const noContentItems = filterContentItemsAgainstUrlParams(linkedItems, urlCodes);
            if (noContentItems?.length > 0) {
                setNoContentItem(noContentItems[0]);
            }
        }
    };

    return (
        <>
            {content && linkedItems && (
                <BodyCopy data={{ item: content as BodyCopyItem, linkedItems: linkedItems }} match={props.match} displayTitle={false} />
            )}
            {content?.elements.last_updated_date__last_updated?.value && (
                <p>
                    <strong>Last updated:</strong> {formatDate(content.elements.last_updated_date__last_updated.value)}
                </p>
            )}
            {!content && noContentItem && (
                <div className="pb-4">
                    <InlinePathway data={{ content: [noContentItem as InlinePathwayItem] }} client={client} />
                </div>
            )}
        </>
    );
};
export default CompetitiveEnvironment;
