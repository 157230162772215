import React from 'react';
import { Col, Row } from 'reactstrap';
import './GuidePathway.scss';
import { GuidePathwayItem } from './GuidePathwayItem';
import { DeliveryClient } from '@kontent-ai/delivery-sdk';
import ComPathwayAnchor from '../../base/ComPathwayAnchor';
import { getColorThemeFromTagName } from '../../../utils/tagHelper';
import { ColorThemeEnum, PageLayoutType } from '../../../models/enums';

interface GuidePathwayProps {
    data: {
        content: GuidePathwayItem[];
        parentHeadingLevel?: string;
    };
    pageLayoutType?: PageLayoutType;
    client: DeliveryClient;
}

const GuidePathway = (props: GuidePathwayProps) => {
    const getTagCodeName = (item: GuidePathwayItem) => (item.elements.tag.value?.length > 0 ? item.elements.tag.value[0]?.codename : null);

    const getTagName = (item: GuidePathwayItem) => {
        const tagCodeName = getTagCodeName(item);
        return tagCodeName ? tagCodeName + '-tag' : null;
    };
    const getTagIcon = (item: GuidePathwayItem) => {
        const tagCodeName = getTagCodeName(item);
        return tagCodeName ? tagCodeName + '-icon' : null;
    };

    const getTagLabel = (item: GuidePathwayItem) =>
        item.elements.tag.value?.length > 0 && item.elements.tag.value[0]?.name ? item.elements.tag.value[0].name : null;

    const isShowPathwayBorderAndHover = (item: GuidePathwayItem) => {
        const borderValue = item.elements.border.value;
        return borderValue?.length > 0 && borderValue[0].codename === 'show';
    };

    const getColorTheme = (item: GuidePathwayItem) => {
        const tagName = getTagName(item);
        const theme = getColorThemeFromTagName(tagName);
        return ColorThemeEnum[theme];
    };

    return (
        <Row className="gx-4 gy-4 pb-4">
            {props.data.content.map((a: GuidePathwayItem, i) => (
                <Col
                    className={`guide-pathway-wrapper ${
                        props.pageLayoutType === PageLayoutType.TwoColumnPage
                            ? 'two-column-layout col-12 col-md-6'
                            : 'full-width-layout col-12 col-md-6 col-lg-4'
                    }`}
                    key={i}
                >
                    <ComPathwayAnchor
                        data={{
                            link: a.elements.link,
                            pathway_link: a.elements.pathway__link,
                        }}
                        client={props.client}
                        className={`guide-pathway-anchor ${isShowPathwayBorderAndHover(a) ? 'show-border' : ''}`}
                        title={a.elements.title.value}
                    >
                        <div
                            className={`guide-pathway-card ${
                                isShowPathwayBorderAndHover(a) ? 'show-side-bar show-hover-background' : ''
                            } ${getColorTheme(a)}`}
                        >
                            <div className="guide-pathway-card-content">
                                {getTagCodeName(a) && (
                                    <div className="label-holder">
                                        <div className={`tag ${getTagName(a)}`}>
                                            <div className={`${getTagIcon(a)}`}></div>
                                            <div>{getTagLabel(a)}</div>
                                        </div>
                                    </div>
                                )}
                                {props.data.parentHeadingLevel === 'h3' ? (
                                    <h4 className="guide-pathway-card-title">{a.elements.title.value}</h4>
                                ) : (
                                    <h3 className="guide-pathway-card-title">{a.elements.title.value}</h3>
                                )}
                                <div
                                    className="guide-pathway-card-description"
                                    dangerouslySetInnerHTML={{
                                        __html: a.elements.description.value,
                                    }}
                                ></div>
                            </div>
                            <div className="guide-pathway-footer">
                                <span className="arrow-right-black-icon"></span>
                            </div>
                        </div>
                    </ComPathwayAnchor>
                </Col>
            ))}
        </Row>
    );
};

export default GuidePathway;
