import { MarketOption } from '../models/MarketOption';
import { Market } from '../models/Market';
import { CommonConstants } from '../CommonConstants';

export const getMarketOptionsWithNotOptions = (marketOptions: MarketOption[]) => {
    let marketNotOptions: MarketOption[] = [
        { Code: CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME, Label: CommonConstants.KONTENT_TAXONOMY_NOT_SURE_LABEL },
        { Code: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME, Label: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL },
    ];
    if (marketOptions && marketOptions.length > 0) {
        return marketOptions.concat(marketNotOptions);
    }
    return marketNotOptions;
};

export const handleMarketSearchResponse = (data: Market[]) => {
    const allMarkets = data.map((x) => {
        return {
            Label: x.Name,
            Code: x.Iso3lCode,
        } as MarketOption;
    });

    return getMarketOptionsWithNotOptions(allMarkets);
};
